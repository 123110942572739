import axios from "../../axios/axios";

export const FetchOwnerHorsesAPI = async (param) => {
  try {
    const response = await axios.post(`/OwnerProfile/GetOwnerHorses`, {
      ownerId: param.ownerId,
      skip: param.skip,
      take: param.take,
      langauge: param.langauge,
      horseTypeFilter: param.horseTypeFilter,
      searchValue: param.searchValue,
      sortBy: param.sortBy,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerByIdAPI = async (param) => {
  try {
    const response = await axios.get(`/OwnerProfile/GetOwnerById?id=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateAccountAPI = async (param) => {
  try {
    const response = await axios.post(
      `/OwnerProfile/UpdateAccount`,
      {
        id: param.id,
        phone: param.phone,
        smsPhone: param.smsPhone,
        //password: param.password,
        email: param.email,
        englishName: param.englishName,
        arabicName: param.arabicName,
        signature: param.signature,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCitiesAPI = async (param) => {
  try {
    const response = await axios.get(`/LookUp/GetCities`);
    //const response = await axios.get(`/OwnerProfile/GetCities`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCreateHorseMarketAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("horseId", param.horseId);
    formData.append("horseEnglishName", param.nameEn);
    formData.append("horseArabicName", param.nameAr);
    formData.append("registerNumber", param.regNo);
    formData.append("horsePrice", param.price);
    formData.append("cityId", param.city);
    formData.append("address", param.address);
    formData.append("contact", param.phone);
    formData.append("email", param.email);
    formData.append("youTubeVideoUrl", param.urlVideo);
    formData.append("ownerId", param.ownerId);
    formData.append("horseCategory", param.category);
    formData.append("marketType", param.type);
    param.files.forEach((element) => {
      formData.append("imagesList", element);
    });

    const response = await axios.post(
      `/OwnerProfile/CreateHorseMarket`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          //Accept: "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateHorseMarketAPI = async (param) => {
  try {
    const formData = new FormData();
    formData.append("horseMarket.id", param.horseMarket.id);
    formData.append("horseMarket.englisName", param.horseMarket.englishName);
    formData.append("horseMarket.arabicName", param.horseMarket.arabicName);
    formData.append(
      "horseMarket.horsecategory",
      param.horseMarket.horsecategory
    );
    formData.append("horseMarket.horsePrice", param.horseMarket.horsePrice);
    formData.append("horseMarket.cityId", param.horseMarket.cityId);
    formData.append("horseMarket.address", param.horseMarket.address);
    formData.append("horseMarket.contact", param.horseMarket.contact);
    formData.append("horseMarket.email", param.horseMarket.email);
    formData.append(
      "horseMarket.youTubeVideoUrl",
      param.horseMarket.youTubeVideoUrl
    );
    formData.append("horseMarket.marketType", param.horseMarket.marketType);
    formData.append("horseMarket.isSold", param.horseMarket.isSold);
    param.imageListObjects.forEach((element, i) => {
      //formData.append("imageListObjects[0]", element);
      formData.append(`imageListObjects[${i}].id`, element.id);
      formData.append(`imageListObjects[${i}].image`, element.image);
      formData.append(`imageListObjects[${i}].url`, element.url);
    });

    const response = await axios.post(
      `/OwnerProfile/UpdateHorseMarket`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchHorseDataByRegisterNumberAPI = async (param) => {
  try {
    const response = await axios.get(
      `/OwnerProfile/GetHorseDataByRegisterNumber?registerNumber=${param}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerHorseMarketAPI = async (param) => {
  try {
    const response = await axios.get(
      `/OwnerProfile/GetOwnerHorseMarket?ownerId=${param}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchDeleteHorseMarketAPI = async (param) => {
  try {
    const response = await axios.post(`/OwnerProfile/DeleteHorseMarket`, {
      id: param,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchCheckUserHasOwnerProfileAPI = async (param) => {
  try {
    const response = await axios.get(
      `/OwnerProfile/CheckUserHasOwnerProfile?id=${param}`
    );
    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchChangePasswordAPI = async (param) => {
  try {
    const response = await axios.post(`/Account/ChangePassword`, {
      userId: param.userId,
      currentPassword: param.currentPassword,
      newPassword: param.newPassword,
    });

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchHorsePassportAPI = async (param) => {
  try {
    const response = await axios.get(`/OwnerProfile/HorsePassport?id=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchOwnerAppuserByIdAPI = async (param) => {
  try {
    const response = await axios.get(
      `/OwnerProfile/GetOwnerAppuserById?id=${param}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchUpdateOwnerAppuserAccountAPI = async (param) => {
  try {
    // console.log(param);
    const response = await axios.post(
      `/OwnerProfile/UpdateOwnerAppuserAccount`,
      {
        id: param.id,
        secondMobileNo: param.secondMobileNo,
        phoneNo: param.phoneNo,
        secondEmail: param.secondEmail,
        email: param.email,
        mobileNo: param.mobileNo,
        signature: param.signature,
        tableType: param.tableType,
        bankName: param?.bankName,
        iban: param?.iban,
        swiftCode: param?.swiftCode,
        bneficiaryName: param?.bneficiaryName,
        bankCountryId: param?.bankCountryId,
        currencyId: param?.currencyId
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchStudOwnerByIdAPI = async (param) => {
  try {
    let response = await axios.get(
      `/OwnerProfile/GetStudOwnerById?id=${param.id}&language=${param.langauge}`
    );

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};

export const FetchGetOwnersListAPI = async (param) => {
  try {
    let response = await axios.get(`/ownerprofile/getownerslist?lang=${param}`);

    return response;
  } catch (error) {
    throw new Error("Failed to fetch data");
  }
};
