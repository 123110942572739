import React, { useEffect, useState } from 'react'
import NetaqInput from './NetaqInput';
import Popup from 'reactjs-popup';
import ActionButton from './ActionButton';
import UploadFile from './UploadFile';
import SignaturePad from "react-signature-canvas";
import axiosInstance from '../axios/axios';


export default function AccountSetting({
    translate,
    englishName,
    ownerAppuserByIdData,
    setEnglishName,
    arabicName,
    setArabicName,
    email,
    userIsUAEPass,
    setEmail,
    phoneNo,
    setPhoneNo,
    userProfile,
    secondEmail,
    setSecondEmail,
    ownerPhoneNo,
    setOwnerPhoneNo,
    mobileNo,
    setMobileNo,
    buttonSubmitStyle,
    handleMouseOver,
    handleMouseOut,
    signatureCanvas,
    buttonclearStyle,
    onSaveSignature,
    onClearSignature,
    buttoncloseStyle,
    handleFileChange,
    imageURL,
    handelUpdateOwnerAppuserAccount,
    handelChangePassword,
    setOldPassword,
    showErrorConfirmPassword,
    setConfirmNewPassword,
    setConfirmPassword,
    bankName,
    setBankName,
    iban,
    setIban,
    swiftCode,
    setSwiftCode,
    bneficiaryName,
    setBneficiaryName,
    bankCountry,
    setBankCountry,
    curency,
    setCurency,
    cachedUserProfile
}) {
    const [invalidIban, setInvalidIBAN] = useState(false);
    const [curenciesOptions, setCurenciesOptions] = useState([]);
    const [nationalityOptions, setNationalityOptions] = useState([]);

    async function getcurrencyList() {
        try {
            const currencylist = await axiosInstance.get('/lookup/getcurrencies');
            const formattedData = currencylist?.data?.result?.map((item) => ({
                ...item,
                label: item.text
            }))

            setCurenciesOptions(formattedData ?? []);
            if (cachedUserProfile != false) {
                const defaultCurrency = formattedData?.find(item => item.value == cachedUserProfile?.currencyId);
                if (defaultCurrency) {
                    setCurency(defaultCurrency);
                }
            }
        } catch (error) {
            console.error('error in currency list')
        }
    }

    async function getNationalityList() {
        try {
            const nationalityList = await axiosInstance.get('/nationality')
            const formattedData = await nationalityList.data.result.map((item) => ({
                text: item.name,
                label: item.name,
                value: item.id,
            }));
            setNationalityOptions(formattedData);
            if (cachedUserProfile != false) {
                const defaultCountry = formattedData?.find(item => item.value == cachedUserProfile?.bankCountryId);
                if (defaultCountry) {
                    setBankCountry(defaultCountry);
                }
            }
        } catch (error) {
            console.error('eror in getting countries')
        }

    }

    useEffect(() => {
        getNationalityList();
        getcurrencyList();

    }, []);
    useEffect(() => {
        if (((iban?.length < 14 || iban?.length > 35) && (iban.length > 0)) ?? false) {
            setInvalidIBAN(true);
        } else {
            setInvalidIBAN(false);
        }
    }, [iban]);

    const userData = ownerAppuserByIdData;
    debugger;
    return <div className="col-lg-9 col-md-8 col-sm-12  rtl">
        <div className="row userinfocard rtl">
            <div className="col-lg-12 col-md-12 col-sm-12  userInfoTitle">
                {translate("web.userInfo", "user Info")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12  ">
                <NetaqInput
                    type={"text"}
                    name={"English Name"}
                    placeholder={translate("web.englishName", "English Name")}
                    label={translate("web.englishName", "English Name")}
                    value={englishName !== ""
                        ? englishName
                        : !!ownerAppuserByIdData &&
                        ownerAppuserByIdData.fullName
                        //: !!OwnerByIdData && OwnerByIdData.ownerNameEn
                    }
                    disabled={true}
                    onChange={(e) => setEnglishName(e.target.value)} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12  ">
                <NetaqInput
                    type={"text"}
                    name={"Arabic Name"}
                    placeholder={translate("web.arabicName", "Arabic Name")}
                    label={translate("web.arabicName", "Arabic Name")}
                    value={arabicName !== ""
                        ? arabicName
                        : !!ownerAppuserByIdData &&
                        ownerAppuserByIdData.fullNameAr
                        //: !!OwnerByIdData && OwnerByIdData.ownerNameAr
                    }
                    disabled={true}
                    onChange={(e) => setArabicName(e.target.value)} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
                <NetaqInput
                    type={"email"}
                    name={"email"}
                    placeholder={translate("web.email", "Email")}
                    label={translate("web.email", "Email")}
                    value={email !== ""
                        ? email
                        : !!ownerAppuserByIdData && ownerAppuserByIdData.email
                        //: !!OwnerByIdData && OwnerByIdData.email
                    }
                    disabled={userIsUAEPass === "false" ? false : true}
                    onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 ">
                <NetaqInput
                    type={"text"}
                    name={"mobile no"}
                    placeholder={translate("web.smsPhoneNo", "Mobile No")}
                    label={translate("web.smsPhoneNo", "Mobile No")}
                    disabled={userIsUAEPass === "false" ? false : true}
                    //value={!!OwnerByIdData && OwnerByIdData.phone}
                    value={phoneNo !== ""
                        ? phoneNo
                        : !!ownerAppuserByIdData &&
                        ownerAppuserByIdData.mobileNo
                        //: !!OwnerByIdData && OwnerByIdData.phone
                    }
                    onChange={(e) => setPhoneNo(e.target.value)} />
            </div>
            {userProfile.accountType != "BasicAccount" && (
                <React.Fragment>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                            type={"email"}
                            name={"second email"}
                            placeholder={translate(
                                "web.secondEmail",
                                "Second Email"
                            )}
                            label={translate("web.secondEmail", "Second Email")}
                            value={secondEmail !== ""
                                ? secondEmail
                                : !!ownerAppuserByIdData &&
                                ownerAppuserByIdData.secondEmail
                                //: !!OwnerByIdData && OwnerByIdData.email
                            }
                            onChange={(e) => setSecondEmail(e.target.value)} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                            type={"text"}
                            name={"phone no"}
                            placeholder={translate("web.phoneNo", "Phone No")}
                            label={translate("web.phoneNo", "Phone No")}
                            value={ownerPhoneNo !== ""
                                ? ownerPhoneNo
                                : !!ownerAppuserByIdData &&
                                ownerAppuserByIdData.phoneNo
                                //: !!OwnerByIdData && OwnerByIdData.phone
                            }
                            onChange={(e) => setOwnerPhoneNo(e.target.value)} />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <NetaqInput
                            type={"text"}
                            name={"second mobile no"}
                            placeholder={translate(
                                "web.secondMobileNo",
                                "Second Mobile No"
                            )}
                            label={translate(
                                "web.secondMobileNo",
                                "Second Mobile No"
                            )}
                            value={mobileNo !== ""
                                ? mobileNo
                                : !!ownerAppuserByIdData &&
                                ownerAppuserByIdData.secondMobileNo
                                //: !!OwnerByIdData && OwnerByIdData.phone
                            }
                            onChange={(e) => setMobileNo(e.target.value)} />
                    </div>
                </React.Fragment>
            )}
            <div className="col-lg-6 col-md-6 col-sm-12">
                <label>{translate("web.signature", "Signature")}</label>

                <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: 20,
                    }}
                >
                    <span style={{ margin: "auto 5px" }}> </span>
                    <Popup
                        modal
                        lockScroll
                        trigger={<div
                            style={{
                                display: "flex",
                            }}
                        >
                            <span
                                style={buttonSubmitStyle}
                                onMouseOver={() => handleMouseOver("sign")}
                                onMouseOut={() => handleMouseOut("sign")}
                                className="opensigniturepad"
                            >
                                {" "}
                                {translate(
                                    "web.openSigniturePad",
                                    "Open Signiture Pad"
                                )}{" "}
                            </span>
                        </div>}
                        closeOnDocumentClick={false}
                        closeOnEscape
                    >
                        {(close) => (
                            <React.Fragment>
                                <SignaturePad
                                    ref={signatureCanvas}
                                    canvasProps={{
                                        className: "signatureCanvas",
                                    }} />
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <button
                                        style={buttonclearStyle}
                                        onMouseOver={() => handleMouseOver("save")}
                                        onMouseOut={() => handleMouseOut("save")}
                                        onClick={() => {
                                            onSaveSignature(close);
                                        }}
                                    >
                                        {translate("web.save", "Save")}{" "}
                                    </button>
                                    <button
                                        style={buttonclearStyle}
                                        onMouseOver={() => handleMouseOver("clear")}
                                        onMouseOut={() => handleMouseOut("clear")}
                                        onClick={onClearSignature}
                                    >
                                        {" "}
                                        {translate("web.clear", "Clear")}{" "}
                                    </button>
                                    <button
                                        style={buttoncloseStyle}
                                        onMouseOver={() => handleMouseOver("close")}
                                        onMouseOut={() => handleMouseOut("close")}
                                        onClick={close}
                                    >
                                        {" "}
                                        {translate("web.close", "close")}{" "}
                                    </button>
                                </div>
                            </React.Fragment>
                        )}
                    </Popup>

                    <span className="uaepassor">
                        {translate("web.or", "OR")}
                    </span>
                    {<React.Fragment>
                        <UploadFile
                            text="Upload Singniture Photo"
                            onChange={handleFileChange} />
                    </React.Fragment>}
                </div>
                <div
                    style={{
                        marginTop: 7, //width: 474
                        textAlign: "center",
                    }}
                    className="col-lg-12 col-md-12 col-sm-12"
                >
                    <div className="text-center">
                        {imageURL ||
                            (!!ownerAppuserByIdData &&
                                !!ownerAppuserByIdData.signature) ? (
                            //(!!OwnerByIdData && !!OwnerByIdData.signature) ? (
                            <img
                                src={imageURL || ownerAppuserByIdData.signature}
                                //src={imageURL || OwnerByIdData.signature}
                                alt={translate("web.signature", "Signature")}
                                style={{
                                    width: "100%",
                                    maxWidth: 216,
                                    maxHeight: 216,
                                }} />
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className="submitButtonContainerleft saveaccountInformation"
                style={{ width: "100%" }}
            >

                <ActionButton
                    type={"submit"}
                    label={"Save"}
                    text={translate("web.save", "Save")}
                    margin={"0"}
                    onClick={handelUpdateOwnerAppuserAccount}
                    disabled={(email !== "" || ownerAppuserByIdData.email !== "") &&
                        (phoneNo !== "" ||
                            ownerAppuserByIdData.mobileNo !== "") &&
                        (secondEmail !== "" ||
                            ownerAppuserByIdData.secondEmail !== "") &&
                        userProfile.accountType != "BasicAccount" &&
                        (ownerPhoneNo !== "" ||
                            ownerAppuserByIdData.phoneNo !== "") &&
                        userProfile.accountType != "BasicAccount" &&
                        (mobileNo !== "" ||
                            ownerAppuserByIdData.secondMobileNo !== "") &&
                        userProfile.accountType != "BasicAccount"
                        ? false
                        : true} />

            </div>
        </div>
        <div className="row userinfocard rtl mt-3">
            <div className="col-lg-12 col-md-12 col-sm-12  userInfoTitle">
                {translate("web.bankDetails", "Bank Details")}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <NetaqInput
                    type={"text"}
                    name={"BankName"}
                    placeholder={translate("web.bankName", "Bank Name")}
                    label={translate("web.bankName", "Bank Name")}
                    value={bankName}
                    onChange={(e) => setBankName(e.target.value)} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <NetaqInput
                    type={"text"}
                    name={"bneficiaryName"}
                    placeholder={translate("web.bneficiaryName", "Beneficiary Name")}
                    label={translate("web.bneficiaryName", "Bneficiary Name")}
                    value={bneficiaryName}
                    onChange={(e) => setBneficiaryName(e.target.value)} />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <NetaqInput
                    type={"text"}
                    label={translate("web.iban", "IBAN")}
                    placeholder={translate("web.iban", "Iban")}
                    name={"iban"}
                    value={iban}
                    key={"IBAN"}
                    onChange={(e) => setIban(e.target.value)}
                />
                {invalidIban &&
                    <div className='text-danger text-center'>
                        {translate('web.invalidIban', 'Invalid IBAN')}
                    </div>
                }
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
                <NetaqInput
                    type={"text"}
                    name={"swiftCode"}
                    placeholder={translate("web.swiftCode", "SwiftCode")}
                    label={translate("web.swiftCode", "SwiftCode")}
                    value={swiftCode}
                    onChange={(e) => setSwiftCode(e.target.value)} />
            </div>
            <div className="col-lg-6  mt-2 col-md-6 col-sm-12  rtl">
                <NetaqInput
                    key={'bankCountryId'}
                    type={"select"}
                    label={translate("web.bankCountry", "Bank Country")}
                    placeholder={translate("web.bankCountry", "Bank Country")}
                    name={"bankCountryId"}
                    listSelect={nationalityOptions}
                    value={bankCountry}
                    onChange={(evt) => setBankCountry(evt)}

                />
            </div>
            <div className="col-lg-6  mt-2 col-md-6 col-sm-12  rtl">
                <NetaqInput
                    key={'currencyId'}
                    type={"select"}
                    label={translate("web.curency", "Curency")}
                    placeholder={translate("web.curency", "curency")}
                    name={"currencyId"}
                    listSelect={curenciesOptions}
                    value={curency}
                    onChange={(evt) => setCurency(evt)}

                />
            </div>

            <div
                className="submitButtonContainerleft saveaccountInformation"
                style={{ width: "100%" }}
            >

                <ActionButton
                    type={"submit"}
                    label={"Save"}
                    text={translate("web.save", "Save")}
                    margin={"0"}
                    onClick={handelUpdateOwnerAppuserAccount}
                    disabled={(email !== "" || ownerAppuserByIdData.email !== "") &&
                        (phoneNo !== "" ||
                            ownerAppuserByIdData.mobileNo !== "") &&
                        (secondEmail !== "" ||
                            ownerAppuserByIdData.secondEmail !== "") &&
                        userProfile.accountType != "BasicAccount" &&
                        (ownerPhoneNo !== "" ||
                            ownerAppuserByIdData.phoneNo !== "") &&
                        userProfile.accountType != "BasicAccount" &&
                        (mobileNo !== "" ||
                            ownerAppuserByIdData.secondMobileNo !== "") &&
                        userProfile.accountType != "BasicAccount"
                        ? false
                        : true} />

            </div>

        </div>
        {userIsUAEPass === "false" && (
            <form
                className="row changepasswordcard rtl"
                onSubmit={handelChangePassword}
            >
                <div className="col-lg-12 col-md-12 col-sm-12 userInfoTitle">
                    {translate("web.changePassword", "Change Password")}
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <NetaqInput
                        type={"password"}
                        name={"oldpassword"}
                        placeholder={translate(
                            "web.oldPassword",
                            "Old Password"
                        )}
                        label={translate("web.oldPassword", "Old Password")}
                        onChange={(e) => setOldPassword(e.target.value)}
                        required={true} />
                </div>
                {showErrorConfirmPassword != "none" && (
                    <div
                        className="col-lg-6 col-md-6 col-sm-12"
                        style={{ minHeight: 85, display: "flex" }}
                    >
                        <span
                            className="text-danger"
                            style={{
                                display: showErrorConfirmPassword,
                                margin: "auto",
                            }}
                        >
                            {translate(
                                "web.errorConfirmPassword",
                                "Error in Confirm Password"
                            )}
                        </span>
                    </div>
                )}
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <NetaqInput
                        type={"password"}
                        name={"newpassword"}
                        placeholder={translate(
                            "web.newPassword",
                            "New Password"
                        )}
                        label={translate("web.newPassword", "New Password")}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required={true} />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <NetaqInput
                        type={"password"}
                        name={"confirmpassword"}
                        placeholder={translate(
                            "web.confirmPassword",
                            "Confirm Password"
                        )}
                        label={translate(
                            "web.confirmPassword",
                            "Confirm Password"
                        )}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required={true} />
                </div>

                <div className="submitButtonContainerleft">
                    <ActionButton
                        type={"submit"}
                        label={"Save"}
                        text={translate("web.save", "Save")}
                        margin={"0"} />
                </div>
            </form>
        )}
    </div>;
}
