import React from 'react'

export default function EahsForm({ children, title="" }) {
    return (
        <>
            <h4 className="col-lg-12 col-md-12 col-sm-12 fw-bold mt-3 ps-0">
                {title}
            </h4>
            <div
                className="row RegisterFormRow pt-1 pb-3 rtl" style={{ display: 'flex', flexDirection: 'column' }}
            >
                <div className="row rtl">
                    {children}
                </div>
            </div>
        </>
    )
}
