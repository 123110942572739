export class TableObject {

    static userProfile = JSON.parse(localStorage.getItem('eahsUserProfile') ?? '{}') ?? '';

    id;
    ownerId;
    holdEndDate;
    price;
    status;
    tableNo;
    anonymousUserId;
    currentAnynomusUserId;

    constructor() {
        this.currentAnynomusUserId = localStorage.getItem('AnonymousUserId');
    }

    static fromRaw(raw) {
        const self = new TableObject();
        Object.assign(self, raw);
        return self;
    }

    state() {
        const statusCodeToState = {
            1: 'available',
            2: 'booked',
            3: 'NA',
            4: !!TableObject.userProfile?.id ? (this.ownerId === TableObject.userProfile.id ? 'selected' : 'hold') : this.anonymousUserId === this.currentAnynomusUserId ? 'selected' : 'hold'
        };

        const state = statusCodeToState[this.status] ?? 'NA';
        return state;
    }
}
