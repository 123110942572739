import React, { useEffect, useRef, useState } from 'react'
import Calendar from '../components/Calendar'
import { useTranslate } from "../components/TranslationText";
import ComingSoon from '../components/ComingSoon';
import { CalenderSeasonsCalculator } from '../helper/CalenderSeasonsCalculator';
import axios from '../axios/axios';
import { getDayfromDate } from '../helper/getDayfromDate';
import axiosUmbraco from '../axios/axiosUmbraco';
import { getEventColor } from '../helper/getEventColor';
import { Link, useLocation } from 'react-router-dom';
import { getEventLink } from '../helper/getEventLink';
import Loader from '../components/Loader';
import ActionButton from '../components/ActionButton';
import normalAxios from 'axios';



const eventColorsID = process.env.REACT_APP_EVENTS_COLORS_ID;
const umbracoEvents = process.env.REACT_APP_UMBRACO_EVENTS_ID;
const screenshotBaseUrl = process.env.REACT_APP_SCREENSHOT_APP_URL_BASE;
const reactBase = process.env.REACT_APP_BASE_URL;
const calenderPageId = process.env.REACT_APP_HOME_PAGE_FOOTER_ID;
const baseURL = process.env.REACT_APP_UMBRACO;

export default function CalendarPage() {
    const { translate } = useTranslate()

    const [nowDate, setNowDate] = useState(new Date());
    const [dates, setDates] = useState(CalenderSeasonsCalculator(nowDate.getFullYear()));
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [loaded, setLoaded] = useState(false);
    const [currentSeason, setCurrentSeason] = useState('');
    const componentRef = useRef(null);
    const [isCapturing, setIsCapturing] = useState(false);
    const lang = localStorage.getItem("eahsLang") ?? "en";

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const incomingSeason = searchParams.has("season") ? searchParams.get("season") : null;




    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    useEffect(() => {
        setDates(CalenderSeasonsCalculator(nowDate.getFullYear()));
    }, [nowDate]);

    const handleNext = () => {
        setNowDate(prev => {
            const newDate = new Date(prev.getTime());
            newDate.setFullYear(prev.getFullYear() + 1);
            return newDate;
        });
        getseasons(dates[0].getFullYear() + 1, dates[6].getFullYear() + 1);
        setLoaded(false);
    };

    const handlePrev = () => {
        setNowDate(prev => {
            const newDate = new Date(prev.getTime());
            newDate.setFullYear(prev.getFullYear() - 1);
            return newDate;
        });
        getseasons(dates[0].getFullYear() - 1, dates[6].getFullYear() - 1);
        setLoaded(false);

    };

    function getCurrentLanguageUmbraco(isUmbracoAPI = false) {
        const lang = localStorage.getItem("eahsLang") ?? "en";
        if (!isUmbracoAPI) {
            return lang === "en" ? 'en' : 'ar';
        } else {
            return lang === "en" ? 'en-US' : 'ar-AE';
        }
    }

    const [events, setEvents] = useState([]);
    const [eventColors, setEventsColors] = useState([]);
    const [renderdMonthes, setRenderdMonthes] = useState([]);
    const [eventsCountsInCurrentSeason, setEventsCountInCurrentSeason] = useState(0);

    async function getLatestSeason() {
        const latestseasonis = await axios.get('shows/GetLastSeason')
        const [startDate, endDate] = latestseasonis.data.result.split("-");
        setCurrentSeason(latestseasonis?.data?.result);
        return { startDate, endDate }
    }


    async function getseasons(startDate, endDate) {
        const eventsColors = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content?fetch=children:${eventColorsID}`,
            {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(true),
                },
            });

        const formattedColors = eventsColors.data.items.map((item) => ({
            eventName: item?.properties?.eventName ?? '',
            eventDisplayName: item?.properties?.eventDisplayName ?? '',
            eventCode: item?.properties?.eventCode ?? '',
            eventColor: item?.properties?.eventColor ?? '',
        }));

        setEventsColors(formattedColors);

        const eventsFromUmbraco = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content?fetch=children:${umbracoEvents}`,
            {
                headers: {
                    "accept-language": getCurrentLanguageUmbraco(true),
                },
            });

        const formatedUmbracoEvents = eventsFromUmbraco.data.items.map((item) => ({
            title: item.properties.eventTitle,
            start: item.properties.eventStart,
            id: "",
            end: item.properties.eventEnd,
            type: item.properties.type,
            backgroundColor: getEventColor(item.properties.type, formattedColors, true),
            eventBG: getEventColor(item.properties.type, formattedColors, true),
        }));

        const incomingEvents = await axios.get(`shows/getseasonshowandauctonsbyseason?season=${startDate}-${endDate}&Langauge=${getCurrentLanguageUmbraco(false)}`);

        const formatedEvents = incomingEvents.data.result.map((item) => ({
            title: item.title,
            id: item.id ?? '',
            start: item.start,
            end: item.end,
            type: item.type,
            backgroundColor: (item.logo != null && item.logo !== "") ? `url(${item.logo})` : getEventColor(item.type, formattedColors, false),
            eventBG: getEventColor(item.type, formattedColors, false),
        }));

        const totalEvents = [...formatedUmbracoEvents, ...formatedEvents];
        const filterdTotalEvents = totalEvents.filter((item) => {
            const myFullYear = new Date(item.start).getFullYear();
            const isbetween = (myFullYear >= startDate && myFullYear < endDate)
            return isbetween
        })


        setEventsCountInCurrentSeason(filterdTotalEvents.length);
        setLoaded(true);
        setEvents(totalEvents);
        // Extract unique months from totalEvents' start and end dates
        const monthsSet = new Set();
        totalEvents.forEach(event => {
            if (event.start) {
                const startMonth = new Date(event.start).getMonth();
                monthsSet.add(startMonth);
            }
            if (event.end) {
                const endMonth = new Date(event.end).getMonth();
                monthsSet.add(endMonth);
            }
        });

        // Convert set to array and sort
        const renderedMonths = Array.from(monthsSet).sort((a, b) => a - b);
        setRenderdMonthes(renderedMonths);
    }

    useEffect(() => {
        async function fetchData() {
            if (!incomingSeason) {
                const { startDate, endDate } = await getLatestSeason();
                setNowDate(new Date(startDate, 0, 1)); // Ensure the start date is set correctly
                setDates(CalenderSeasonsCalculator(startDate));
                getseasons(startDate, endDate);
            } else {
                const [startDate, endDate] = incomingSeason.split("-");
                setNowDate(new Date(startDate, 0, 1)); // Ensure the start date is set correctly
                setDates(CalenderSeasonsCalculator(startDate));
                getseasons(startDate, endDate);
            }
        }
        fetchData();
    }, []);

    function chooseEventsByindex(index, date) {
        return events.map(item => ({ ...item, startDate: new Date(item.start), endDate: new Date(item.end) }))
            .filter(item => (item.startDate.getMonth() === (index + 9) % 12 && item.startDate.getFullYear() === date.getFullYear()) || item.endDate.getMonth() === (index + 9) % 12 && item.endDate.getFullYear() === date.getFullYear());
    }

    useEffect(() => {
        console.debug('event!!!', events);
    }, [events])


    // calender Print PDF
    const [calenderPDFUrl, setCalenderPDFUrl] = useState('');
    async function getCalenderPDFUrl() {
        try {
            const res = await axiosUmbraco.get(`/umbraco/delivery/api/v1/content/item/${calenderPageId}`,)
            setCalenderPDFUrl(res?.data?.properties?.calendarFile);
        } catch (error) {
            console.error('Error in getCalenderPDFUrl', error);
        }

    }
    useEffect(() => {
        getCalenderPDFUrl();
    }, [])


    return (
        <>
            {loaded ?
                <div className='container-fluid px75' ref={componentRef} >
                    <div className='row rtl'>
                        <div className='col-12 text-center'>
                            <div className="calnderuttonsContainer">
                                <div className="calenderBtn tooltip" onClick={handlePrev}>
                                    <i className="fa-solid fa-chevron-left justRotate"></i>
                                    <span class="tooltiptext">{translate('web.prev', 'Prev')}</span>
                                </div>
                                <div className="year fc-toolbar-title  rtl">
                                    {dates[0].getFullYear()} - {dates[6].getFullYear()}
                                </div>
                                <div className="calenderBtn tooltip" onClick={handleNext}>
                                    <i className="fa-solid fa-chevron-right justRotate"></i>
                                    <span class="tooltiptext">{translate('web.next', 'Next')}</span>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row justRotate' id='tabletoprint' style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'flex-start' }}>
                        {
                            dates.map((date, index) => ({
                                date,
                                events: chooseEventsByindex(index, date)
                            })).map((item, index) => {
                                // isNoEventsinSeason(item.events.length > 0, index, dates.length);
                                return (
                                    item.events.length > 0 &&
                                    <div key={index} className={'col-lg-4 col-md-12 col-sm-12 justRotate w100OnMobile'} >
                                        <Calendar showHeader={false} isLeftSide={true} events={events} initialDate={item.date} aspectRatio={1} />
                                        <div className='eventsContainer'>
                                            <EventsDates events={item.events} />
                                        </div>
                                    </div>
                                )
                            }

                            )
                        }
                        {eventsCountsInCurrentSeason == 0 &&
                            <div className='justRotate' style={{ margin: 'auto' }}>
                                <ComingSoon text={'web.notAvailableNow'} defaultText={'Not Available Now'} />
                            </div>
                        }
                        <div className='calendarLegendContainer justRotate rtl' >
                            {eventColors.map((item, index) => (
                                <div key={index} className='legendCard'>
                                    <div className='legendCardrect' style={{ background: item.eventColor }}></div>
                                    <div className='legendCardDescription'>{item.eventDisplayName}</div>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>
                : <Loader />
            }

            {!!calenderPDFUrl &&
                <div className='container-fluid px75'>
                    <div className='row m0 text-start'>
                        <div className=' col-12 mt-3'>
                            <Link to={`${baseURL}${calenderPDFUrl}`} target='_blank' className='btn btn-danger' >
                                {translate('web.downloadAsPdf', 'Dowanload as PDF')}
                            </Link>
                        </div>
                    </div>
                </div>
            }
        </>
    );

    function EventsDates({ events }) {
        return events.sort((a, b) => (new Date(a.start) - new Date(b.start))).map((event, index) => (
            getEventLink(event.type, event.id) == "" ?
                <div key={index} className='eventRow rtl'>
                    <div className='dayContainer' style={{ background: event.eventBG }}>{getDayfromDate(event.start)}</div>
                    <div className='dayContainer' style={{ background: event.eventBG }}>{getDayfromDate(event.end)}</div>
                    {event.backgroundColor.includes('url') &&
                        <div className='dayContainer' style={{ background: event.backgroundColor ?? event.eventBG, backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    }
                    <div className='eventName'>{event.title}</div>
                </div>
                :
                <Link key={index} to={getEventLink(event.type, event.id)} className='eventRow rtl'>
                    <div className='dayContainer' style={{ background: event.eventBG, }}>{getDayfromDate(event.start)}</div>
                    <div className='dayContainer' style={{ background: event.eventBG, }}>{getDayfromDate(event.end)}</div>
                    {event.backgroundColor.includes('url') ?
                        <div className='dayContainer' style={{ background: event.backgroundColor ?? event.eventBG, backgroundSize: "cover", backgroundPosition: "center" }}></div> :
                        <div className='dayContainer' style={{ background: '#fff', backgroundSize: "cover", backgroundPosition: "center" }}></div>
                    }
                    <div className='eventName'>{event.title}</div>
                </Link>
        ));
    }
}
