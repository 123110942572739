import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchApproveRejectRequestCoveringCertificatethunk,
  FetchCancelRequestthunk,
  FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk,
  FetchCreateCoveringCertificateServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchUpdateCoveringCertificateServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import AsyncSelect from "react-select/async";
import axiosInstance from "../axios/axios";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import Popup from "reactjs-popup";
import { base64ToBlob } from "../helper/base64ToBlob";
import {
  FetchCountriesthunk,
  FetchHorseColorsthunk,
} from "../Redux/LookUp/LookUpAction";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { isLessThanUndefinedYearOld } from "../helper/isLessThanUndefinedYearOld";
import dayjs from "dayjs";
import { CheckDateBeforUndefinedDays } from "../helper/CheckDateBeforUndefinedDays";
import EahsForm from "../components/EahsForm";

export default function RequestCoveringCertificate() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const { countriesData, horseColorsData } = useSelector(
    (state) => state.lookUpSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    checkLastChildrenOfHorseMareRegLessThanOneWeekData,
    createCoveringCertificateServiceRequestData
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    isStallionRegistered: "Registered",
    isMareRegistered: "Registered",
    notStallionOwner: false,
    horseStallionReg: "",
    horseStallionRegDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    horseNotStallion: "",
    horseNotStallionDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    ownerIdNotStallionReg: "",
    regNoStallionReg: "",
    horseNameStallionReg: "",
    originStallionReg: "",
    breederStallionReg: "",
    genderStallionReg: "",
    colorStallionReg: "",
    birthDateStallionReg: "",
    sireStallionReg: "",
    damStallionReg: "",
    ownerNameStallionReg: "",
    ownerEmailStallionReg: "",
    ownerMobileStallionReg: "",

    horseNameEnStallionUnReg: "",
    horseNameArStallionUnReg: "",
    currentRegNoStallionUnReg: "",
    damEnStallionUnReg: "",
    damArStallionUnReg: "",
    sireEnStallionUnReg: "",
    sireArStallionUnReg: "",
    colorStallionUnReg: "",
    colorStallionUnRegDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    ownerNameEnStallionUnReg: "",
    ownerNameArStallionUnReg: "",
    originStallionUnReg: "",
    originStallionUnRegDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    uelnStallionUnReg: "",
    genderStallionUnReg: "",
    genderStallionUnRegDrop: {
      label: translate("web.gender", "Gender"),
      value: null,
    },
    birthDateStallionUnReg: null,
    breederNameEnStallionUnReg: "",
    breederNameArStallionUnReg: "",
    horseAddressStallionUnReg: "",
    horseCountryStallionUnReg: "",
    horseCountryStallionUnRegDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },

    breedingHappendAbroadAttachmentFile: null,
    stallionOwnerSignedPermitAttachmentFile: null,
    embryoIsImportedAttachmentFile: null,
    importedSemenCertificateAttachmentFile: null,

    horseMareReg: "",
    horseMareRegDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },
    originMareReg: "",
    regNoMareReg: "",
    horseNameMareReg: "",
    genderMareReg: "",
    colorMareReg: "",
    birthDateMareReg: "",
    sireMareReg: "",
    damMareReg: "",
    breederMareReg: "",

    horseNameEnMareUnReg: "",
    horseNameArMareUnReg: "",
    currentRegNoMareUnReg: "",
    damEnMareUnReg: "",
    damArMareUnReg: "",
    sireEnMareUnReg: "",
    sireArMareUnReg: "",
    colorMareUnReg: "",
    colorMareUnRegDrop: {
      label: translate("web.color", "Color"),
      value: null,
    },
    originMareUnReg: "",
    originMareUnRegDrop: {
      label: translate("web.chooseOrigin", "Choose Origin"),
      value: null,
    },
    horseCountryMareUnReg: "",
    horseCountryMareUnRegDrop: {
      label: translate("web.horseCountry", "Horse Country"),
      value: null,
    },
    uelnMareUnReg: "",
    genderMareUnReg: "",
    genderMareUnRegDrop: {
      label: translate("web.gender", "Gender"),
      value: null,
    },
    birthDateMareUnReg: null,
    breederNameEnMareUnReg: "",
    breederNameArMareUnReg: "",
    horseAddressMareUnReg: "",

    typeOfCovering: "NaturalBreeding",
    coveringDate: null,
    breedingHappendAbroad: false,
    embryoIsImported: false,
    stallionOwnerSignedPermit: false,
    stallionOwnerSignedPermitPart: false,
    filePathBreedingHappendAbroad: "",
    fileTypeBreedingHappendAbroad: "",
    fileNameBreedingHappendAbroad: "",
    documentBase64BreedingHappendAbroad: "",

    filePathEmbryoIsImported: "",
    fileTypeEmbryoIsImported: "",
    fileNameEmbryoIsImported: "",
    documentBase64EmbryoIsImported: "",

    filePathStallionOwnerSignedPermit: "",
    fileTypeStallionOwnerSignedPermit: "",
    fileNameStallionOwnerSignedPermit: "",
    documentBase64StallionOwnerSignedPermit: "",

    filePathImportedSemenCertificate: "",
    fileTypeImportedSemenCertificate: "",
    fileNameImportedSemenCertificate: "",
    documentBase64ImportedSemenCertificate: "",

    eventName: "",
    stallionComment: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",

    coveringCertificateId: null,
    horseMareRegLessThanOneWeek: false,
    semenDoseOwnershipReferenceNumber: "",
    isViolated: false,
    paymentStatus: "",
    violationPayment: "",
  });

  const loadd = useRef(false);
  const [errorHorseStallionReg, setErrorHorseStallionReg] = useState("none");
  const [errorHorseNameEnStallionUnReg, setErrorHorseNameEnStallionUnReg] = useState("none");
  const [errorHorseNameArStallionUnReg, setErrorHorseNameArStallionUnReg] = useState("none");
  const [errorDamEnStallionUnReg, setErrorDamEnStallionUnReg] = useState("none");
  const [errorDamArStallionUnReg, setErrorDamArStallionUnReg] = useState("none");
  const [errorSireEnStallionUnReg, setErrorSireEnStallionUnReg] = useState("none");
  const [errorSireArStallionUnReg, setErrorSireArStallionUnReg] = useState("none");
  const [errorOwnerNameEnStallionUnReg, setErrorOwnerNameEnStallionUnReg] = useState("none");
  const [errorOwnerNameArStallionUnReg, setErrorOwnerNameArStallionUnReg] = useState("none");
  const [errorBreederNameEnStallionUnReg, setErrorBreederNameEnStallionUnReg] = useState("none");
  const [errorBreederNameArStallionUnReg, setErrorBreederNameArStallionUnReg] = useState("none");
  const [errorHorseAddressStallionUnReg, setErrorHorseAddressStallionUnReg] = useState("none");
  const [errorCurrentRegNoStallionUnReg, setErrorCurrentRegNoStallionUnReg] = useState("none");
  const [errorUelnStallionUnReg, setErrorUelnStallionUnReg] = useState("none");
  const [errorColorStallionUnReg, setErrorColorStallionUnReg] = useState("none");
  const [errorOriginStallionUnReg, setErrorOriginStallionUnReg] = useState("none");
  const [errorHorseCountryStallionUnReg, setErrorHorseCountryStallionUnReg] = useState("none");
  const [errorBirthDateStallionUnReg, setErrorBirthDateStallionUnReg] = useState("none");

  const [errorHorseMareReg, setErrorHorseMareReg] = useState("none");
  const [errorHorseNameEnMareUnReg, setErrorHorseNameEnMareUnReg] = useState("none");
  const [errorHorseNameArMareUnReg, setErrorHorseNameArMareUnReg] = useState("none");
  const [errorDamEnMareUnReg, setErrorDamEnMareUnReg] = useState("none");
  const [errorDamArMareUnReg, setErrorDamArMareUnReg] = useState("none");
  const [errorSireEnMareUnReg, setErrorSireEnMareUnReg] = useState("none");
  const [errorSireArMareUnReg, setErrorSireArMareUnReg] = useState("none");
  const [errorBreederNameEnMareUnReg, setErrorBreederNameEnMareUnReg] = useState("none");
  const [errorBreederNameArMareUnReg, setErrorBreederNameArMareUnReg] = useState("none");
  const [errorHorseAddressMareUnReg, setErrorHorseAddressMareUnReg] = useState("none");
  const [errorCurrentRegNoMareUnReg, setErrorCurrentRegNoMareUnReg] = useState("none");
  const [errorUelnMareUnReg, setErrorUelnMareUnReg] = useState("none");
  const [errorColorMareUnReg, setErrorColorMareUnReg] = useState("none");
  const [errorOriginMareUnReg, setErrorOriginMareUnReg] = useState("none");
  const [errorHorseCountryMareUnReg, setErrorHorseCountryMareUnReg] = useState("none");
  const [errorBirthDateMareUnReg, setErrorBirthDateMareUnReg] = useState("none");

  const [errorCoveringDate, setErrorCoveringDate] = useState("none");

  const [errorBreedingHappendAbroad, setErrorBreedingHappendAbroad] = useState("none");
  const [errorEmbryoIsImported, setErrorEmbryoIsImported] = useState("none");
  const [errorStallionOwnerSignedPermit, setErrorStallionOwnerSignedPermit] = useState("none");
  const [errorStallionOwnerSignedPermitCheck, setErrorStallionOwnerSignedPermitCheck] = useState("none");
  const [errorImportedSemenCertificate, setErrorImportedSemenCertificate] = useState("none");
  const [errorSemenDoseOwnershipReferenceNumber, setErrorSemenDoseOwnershipReferenceNumber] = useState("none");
  const [
    breedingHappendAbroadAttachmentFile,
    setBreedingHappendAbroadAttachmentFile,
  ] = useState([]);
  const [
    stallionOwnerSignedPermitAttachmentFile,
    setStallionOwnerSignedPermitAttachmentFile,
  ] = useState([]);
  const [embryoIsImportedAttachmentFile, setEmbryoIsImportedAttachmentFile] =
    useState([]);
  const [
    importedSemenCertificateAttachmentFile,
    setImportedSemenCertificateAttachmentFile,
  ] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupCancel, setOpenPopupCancel] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [timeoutId, setTimeoutId] = useState(0);
  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
  });
  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage == "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const _isOnlinePayment = useRef("");
  const _status = useRef("");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const nowDate = new Date();
  const oneYearAgo = new Date(nowDate);  // Copy the current date
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  oneYearAgo.setDate(oneYearAgo.getDate() - 10);

  //function
  const handleFormChange = (e, input) => {
    if (input === "horseStallionReg") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      setFormModel({
        ...formModel,
        horseStallionRegDrop: e,
        horseStallionReg: e.value,
        regNoStallionReg: horse[0]?.registerNumber ?? "",
        horseNameStallionReg: horse[0]?.name ?? "",
        genderStallionReg: horse[0]?.gender ?? "",
        colorStallionReg: horse[0]?.color ?? "",
        birthDateStallionReg: horse[0]?.birthDate ?? "",
        sireStallionReg: horse[0]?.sire ?? "",
        damStallionReg: horse[0]?.dam ?? "",
        originStallionReg: horse[0]?.origin ?? "",
        breederStallionReg: horse[0]?.breeder ?? "",
      });
    } else if (!!input && input === "originStallionUnReg") {
      setFormModel({
        ...formModel,
        originStallionUnReg: e.value,
        originStallionUnRegDrop: e,
      });
    } else if (!!input && input === "horseCountryStallionUnReg") {
      setFormModel({
        ...formModel,
        horseCountryStallionUnReg: e.value,
        horseCountryStallionUnRegDrop: e,
      });
    } else if (!!input && input === "colorStallionUnReg") {
      setFormModel({
        ...formModel,
        colorStallionUnReg: e.value,
        colorStallionUnRegDrop: e,
      });
    } else if (input === "horseNotStallion") {
      if (e?.value != null && e?.value != undefined) {
        setFormModel({
          ...formModel,
          horseNotStallionDrop: e,
          horseNotStallion: e.value,
          ownerEmailStallionReg: e.details.ownerEmail,
          ownerMobileStallionReg: e.details.ownerMobile,
          ownerNameStallionReg: e.details.ownerReg + " - " + e.details.owner,
          originStallionReg: e.details.origin,
          breederStallionReg: e.details.breeder,
          genderStallionReg: e.details.gender,
          colorStallionReg: e.details.color,
          birthDateStallionReg: e.details.birthDate,
          sireStallionReg: e.details.sire,
          damStallionReg: e.details.dam,
          ownerIdNotStallionReg: e.details.ownerIdGuid
        });
      }
    } else if (input === "stallionType") {
      setFormModel({
        ...formModel,
        isStallionRegistered: e.target.value,
        stallionOwnerSignedPermitPart: e.target.value === "Unregistered",
        stallionOwnerSignedPermit: false,
        stallionOwnerSignedPermitAttachmentFile: null,
        notStallionOwner: false,
        horseStallionReg: "",
        horseStallionRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        horseNotStallion: "",
        horseNotStallionDrop: {
          label: translate("web.horse", "Horse"),
          value: "",
        },
        ownerEmailStallionReg: "",
        ownerMobileStallionReg: "",
        ownerNameStallionReg: "",
        originStallionReg: "",
        breederStallionReg: "",
        genderStallionReg: "",
        colorStallionReg: "",
        birthDateStallionReg: "",
        sireStallionReg: "",
        damStallionReg: "",

        horseNameEnStallionUnReg: "",
        horseNameArStallionUnReg: "",
        damEnStallionUnReg: "",
        damArStallionUnReg: "",
        sireEnStallionUnReg: "",
        sireArStallionUnReg: "",
        ownerNameEnStallionUnReg: "",
        ownerNameArStallionUnReg: "",
        breederNameEnStallionUnReg: "",
        breederNameArStallionUnReg: "",
        horseAddressStallionUnReg: "",
        currentRegNoStallionUnReg: "",
        uelnStallionUnReg: "",
        colorStallionUnReg: "",
        colorStallionUnRegDrop: {
          label: translate("web.color", "Color"),
          value: null,
        },
        birthDateStallionUnReg: null,
      });

      setStallionOwnerSignedPermitAttachmentFile([]);
    } else if (input === "horseMareReg") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      if (e.value !== "" && formModel.coveringDate != null) {
        let params = {
          horseId: e.value,
          coveringDate: dayjs(formModel.coveringDate)
        }
        dispatch(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk(params));
      }

      setFormModel({
        ...formModel,
        horseMareRegDrop: e,
        horseMareReg: e.value,
        regNoMareReg: horse[0]?.registerNumber ?? "",
        horseNameMareReg: horse[0]?.name ?? "",
        genderMareReg: horse[0]?.gender ?? "",
        colorMareReg: horse[0]?.color ?? "",
        birthDateMareReg: horse[0]?.birthDate ?? "",
        sireMareReg: horse[0]?.sire ?? "",
        damMareReg: horse[0]?.dam ?? "",
        originMareReg: horse[0]?.origin ?? "",
        breederMareReg: horse[0]?.breeder ?? "",
      });
    } else if (!!input && input === "originMareUnReg") {
      setFormModel({
        ...formModel,
        originMareUnReg: e.value,
        originMareUnRegDrop: e,
      });
    } else if (!!input && input === "horseCountryMareUnReg") {
      setFormModel({
        ...formModel,
        horseCountryMareUnReg: e.value,
        horseCountryMareUnRegDrop: e,
      });
    } else if (!!input && input === "colorMareUnReg") {
      setFormModel({
        ...formModel,
        colorMareUnReg: e.value,
        colorMareUnRegDrop: e,
      });
    } else if (input === "mareType") {
      setFormModel({
        ...formModel,
        isMareRegistered: e.target.value,
        horseMareReg: "",
        horseMareRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        originMareReg: "",
        breederMareReg: "",
        genderMareReg: "",
        colorMareReg: "",
        birthDateMareReg: "",
        sireMareReg: "",
        damMareReg: "",

        horseNameEnMareUnReg: "",
        horseNameArMareUnReg: "",
        damEnMareUnReg: "",
        damArMareUnReg: "",
        sireEnMareUnReg: "",
        sireArMareUnReg: "",
        breederNameEnMareUnReg: "",
        breederNameArMareUnReg: "",
        horseAddressMareUnReg: "",
        currentRegNoMareUnReg: "",
        uelnMareUnReg: "",
        colorMareUnReg: "",
        colorMareUnRegDrop: {
          label: translate("web.color", "Color"),
          value: null,
        },
        birthDateMareUnReg: null,
      });
    } else if (input === "notStallionOwner") {
      setFormModel({
        ...formModel,
        notStallionOwner: e.target.checked,
        stallionOwnerSignedPermitPart: e.target.checked,
        stallionOwnerSignedPermit: false,
        stallionOwnerSignedPermitAttachmentFile: null,
        //typeOfCovering: "NaturalBreeding",
        horseStallionReg: "",
        horseStallionRegDrop: {
          label: translate("web.horse", "Horse"),
          value: null,
        },
        horseNotStallion: "",
        horseNotStallionDrop: {
          label: translate("web.horse", "Horse"),
          value: "",
        },
        ownerEmailStallionReg: "",
        ownerMobileStallionReg: "",
        ownerNameStallionReg: "",
        originStallionReg: "",
        breederStallionReg: "",
        genderStallionReg: "",
        colorStallionReg: "",
        birthDateStallionReg: "",
        sireStallionReg: "",
        damStallionReg: "",
      });

      setStallionOwnerSignedPermitAttachmentFile([]);
    } else if (input === "breedingHappendAbroad") {
      setFormModel({
        ...formModel,
        breedingHappendAbroad: e.target.checked,
      });
    } else if (input === "embryoIsImported") {
      setFormModel({
        ...formModel,
        embryoIsImported: e.target.checked,
      });
    } else if (input === "stallionOwnerSignedPermit") {
      setFormModel({
        ...formModel,
        stallionOwnerSignedPermit: e.target.checked,
      });
    } else if (!!input && input === "birthDateStallionUnReg") {
      //setBirthdateStallionUnReg(e);

      setFormModel({
        ...formModel,
        birthDateStallionUnReg: e.target.value,
      });
    } else if (!!input && input === "birthDateMareUnReg") {
      //setBirthdateMareUnReg(e);

      setFormModel({
        ...formModel,
        birthDateMareUnReg: e.target.value,
      });
    } else if (!!input && input === "coveringDate") {
      //setCoveringDate(e);
      if (!!e && formModel.horseMareReg !== "") {
        let params = {
          horseId: formModel.horseMareReg,
          coveringDate: dayjs(e.target.value)
          // coveringDate: dayjs(e.target.value).format("DD/MM/YYYY")
        }

        dispatch(FetchCheckLastChildrenOfHorseMareRegLessThanOneWeekthunk(params));
      }

      setFormModel({
        ...formModel,
        coveringDate: e.target.value,
      });
    } else if (!!input && input === "horseNameEnStallionUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          horseNameEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "sireEnStallionUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          sireEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "damEnStallionUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          damEnStallionUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "horseNameEnMareUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          horseNameEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "sireEnMareUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          sireEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else if (!!input && input === "damEnMareUnReg") {
      // Allow only letters and numbers
      const regex = /^[a-zA-Z0-9]*$/;

      if (regex.test(e.target.value)) {
        setFormModel({
          ...formModel,
          damEnMareUnReg: e.target.value.toUpperCase(),
        });
      }
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;
    //stallion
    if (
      formModel.isStallionRegistered === "Registered" && ((formModel.horseStallionReg === "" && !formModel.notStallionOwner) || (formModel.horseNotStallion === "" && formModel.notStallionOwner))
    ) {
      setErrorHorseStallionReg("");
      valid = false;
    }
    else {
      setErrorHorseStallionReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.horseNameEnStallionUnReg === ""
    ) {
      setErrorHorseNameEnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorHorseNameEnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.horseNameArStallionUnReg === ""
    ) {
      setErrorHorseNameArStallionUnReg("");
      valid = false;
    }
    else {
      setErrorHorseNameArStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.damEnStallionUnReg === ""
    ) {
      setErrorDamEnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorDamEnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.damArStallionUnReg === ""
    ) {
      setErrorDamArStallionUnReg("");
      valid = false;
    }
    else {
      setErrorDamArStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.sireEnStallionUnReg === ""
    ) {
      setErrorSireEnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorSireEnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.sireArStallionUnReg === ""
    ) {
      setErrorSireArStallionUnReg("");
      valid = false;
    }
    else {
      setErrorSireArStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.ownerNameEnStallionUnReg === ""
    ) {
      setErrorOwnerNameEnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorOwnerNameEnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.ownerNameArStallionUnReg === ""
    ) {
      setErrorOwnerNameArStallionUnReg("");
      valid = false;
    }
    else {
      setErrorOwnerNameArStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.breederNameEnStallionUnReg === ""
    ) {
      setErrorBreederNameEnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorBreederNameEnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.breederNameArStallionUnReg === ""
    ) {
      setErrorBreederNameArStallionUnReg("");
      valid = false;
    }
    else {
      setErrorBreederNameArStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.horseAddressStallionUnReg === ""
    ) {
      setErrorHorseAddressStallionUnReg("");
      valid = false;
    }
    else {
      setErrorHorseAddressStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.currentRegNoStallionUnReg === ""
    ) {
      setErrorCurrentRegNoStallionUnReg("");
      valid = false;
    }
    else {
      setErrorCurrentRegNoStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.uelnStallionUnReg === ""
    ) {
      setErrorUelnStallionUnReg("");
      valid = false;
    }
    else {
      setErrorUelnStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.colorStallionUnReg === ""
    ) {
      setErrorColorStallionUnReg("");
      valid = false;
    }
    else {
      setErrorColorStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.originStallionUnReg === ""
    ) {
      setErrorOriginStallionUnReg("");
      valid = false;
    }
    else {
      setErrorOriginStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.horseCountryStallionUnReg === ""
    ) {
      setErrorHorseCountryStallionUnReg("");
      valid = false;
    }
    else {
      setErrorHorseCountryStallionUnReg("none");
    }
    if (
      formModel.isStallionRegistered === "Unregistered" && formModel.birthDateStallionUnReg === null
    ) {
      setErrorBirthDateStallionUnReg("");
      valid = false;
    }
    else {
      setErrorBirthDateStallionUnReg("none");
    }

    //mare
    if (
      formModel.isMareRegistered === "Registered" && formModel.horseMareReg === ""
    ) {
      setErrorHorseMareReg("");
      valid = false;
    }
    else {
      setErrorHorseMareReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.horseNameEnMareUnReg === ""
    ) {
      setErrorHorseNameEnMareUnReg("");
      valid = false;
    }
    else {
      setErrorHorseNameEnMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.horseNameArMareUnReg === ""
    ) {
      setErrorHorseNameArMareUnReg("");
      valid = false;
    }
    else {
      setErrorHorseNameArMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.damEnMareUnReg === ""
    ) {
      setErrorDamEnMareUnReg("");
      valid = false;
    }
    else {
      setErrorDamEnMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.damArMareUnReg === ""
    ) {
      setErrorDamArMareUnReg("");
      valid = false;
    }
    else {
      setErrorDamArMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.sireEnMareUnReg === ""
    ) {
      setErrorSireEnMareUnReg("");
      valid = false;
    }
    else {
      setErrorSireEnMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.sireArMareUnReg === ""
    ) {
      setErrorSireArMareUnReg("");
      valid = false;
    }
    else {
      setErrorSireArMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.breederNameEnMareUnReg === ""
    ) {
      setErrorBreederNameEnMareUnReg("");
      valid = false;
    }
    else {
      setErrorBreederNameEnMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.breederNameArMareUnReg === ""
    ) {
      setErrorBreederNameArMareUnReg("");
      valid = false;
    }
    else {
      setErrorBreederNameArMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.horseAddressMareUnReg === ""
    ) {
      setErrorHorseAddressMareUnReg("");
      valid = false;
    }
    else {
      setErrorHorseAddressMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.currentRegNoMareUnReg === ""
    ) {
      setErrorCurrentRegNoMareUnReg("");
      valid = false;
    }
    else {
      setErrorCurrentRegNoMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.uelnMareUnReg === ""
    ) {
      setErrorUelnMareUnReg("");
      valid = false;
    }
    else {
      setErrorUelnMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.colorMareUnReg === ""
    ) {
      setErrorColorMareUnReg("");
      valid = false;
    }
    else {
      setErrorColorMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.originMareUnReg === ""
    ) {
      setErrorOriginMareUnReg("");
      valid = false;
    }
    else {
      setErrorOriginMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.horseCountryMareUnReg === ""
    ) {
      setErrorHorseCountryMareUnReg("");
      valid = false;
    }
    else {
      setErrorHorseCountryMareUnReg("none");
    }
    if (
      formModel.isMareRegistered === "Unregistered" && formModel.birthDateMareUnReg === null
    ) {
      setErrorBirthDateMareUnReg("");
      valid = false;
    }
    else {
      setErrorBirthDateMareUnReg("none");
    }

    //covering/Upload Documents
    if (
      formModel.coveringDate === null
    ) {
      setErrorCoveringDate("");
      valid = false;
    }
    else {
      setErrorCoveringDate("none");
    }

    // if (formModel.typeOfCovering === "ArtificialInseminationLocal" && formModel.stallionOwnerSignedPermitPart && formModel.notStallionOwner && formModel.semenDoseOwnershipReferenceNumber === "") {
    if (formModel.typeOfCovering === "ArtificialInseminationLocal" && formModel.semenDoseOwnershipReferenceNumber === "") {
      setErrorSemenDoseOwnershipReferenceNumber("");
      valid = false;
    }
    else {
      setErrorSemenDoseOwnershipReferenceNumber("none");
    }

    if (
      formModel.typeOfCovering === "ArtificialInseminationImported" && importedSemenCertificateAttachmentFile.length === 0 && formModel.documentBase64ImportedSemenCertificate === ""
    ) {
      setErrorImportedSemenCertificate("");
      valid = false;
    }
    else {
      setErrorImportedSemenCertificate("none");
    }

    if (formModel.isStallionRegistered === "Unregistered" && !formModel.stallionOwnerSignedPermit) {
      setErrorStallionOwnerSignedPermitCheck("");
      valid = false;
    }
    else {
      setErrorStallionOwnerSignedPermitCheck("none");
    }

    if (
      (formModel.isStallionRegistered === "Unregistered" || formModel.stallionOwnerSignedPermit) && (stallionOwnerSignedPermitAttachmentFile.length === 0 && formModel.documentBase64StallionOwnerSignedPermit === "")
    ) {
      setErrorStallionOwnerSignedPermit("");
      valid = false;
    }
    else {
      setErrorStallionOwnerSignedPermit("none");
    }

    // if (
    //   (formModel.isStallionRegistered === "Unregistered" || formModel.notStallionOwner) && (stallionOwnerSignedPermitAttachmentFile.length === 0 && formModel.documentBase64StallionOwnerSignedPermit === "")
    // ) {
    //   setErrorStallionOwnerSignedPermit("");
    //   valid = false;
    // }
    // else {
    //   setErrorStallionOwnerSignedPermit("none");
    // }

    if (
      formModel.breedingHappendAbroad && (breedingHappendAbroadAttachmentFile.length === 0 && formModel.documentBase64BreedingHappendAbroad === "")
    ) {
      setErrorBreedingHappendAbroad("");
      valid = false;
    }
    else {
      setErrorBreedingHappendAbroad("none");
    }
    if (
      formModel.embryoIsImported && (embryoIsImportedAttachmentFile.length === 0 && formModel.documentBase64EmbryoIsImported === "")
    ) {
      setErrorEmbryoIsImported("");
      valid = false;
    }
    else {
      setErrorEmbryoIsImported("none");
    }
    //terms
    if (
      (!checkedItems.option1)
      || (!checkedItems.option2 && (
        (formModel.isStallionRegistered == "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
        || (formModel.isStallionRegistered == "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
        || (formModel.isMareRegistered == "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
        || (formModel.isMareRegistered == "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3))
      ))
      //|| (!checkedItems.option3 && (formModel.horseMareRegLessThanOneWeek == true || checkLastChildrenOfHorseMareRegLessThanOneWeekData == true))
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );

      valid = false;
    }

    if (formModel.horseMareRegLessThanOneWeek == true || checkLastChildrenOfHorseMareRegLessThanOneWeekData == true) {
      toast.error(
        translate("web.coveringCertificateNote2", "Covering Certificate Note 2")
      );

      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    _isOnlinePayment.current = isOnlinePayment;
    _status.current = status;

    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName === "PendingPayment") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=11&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {

        // if (status === "Submit") {
        //   status = "PendingReview";

        //   // if (formModel.isStallionRegistered === "Registered" && formModel.notStallionOwner) {
        //   if (formModel.notStallionOwner && !formModel.stallionOwnerSignedPermit) {
        //     status = "WatingStallionOwnerApproval";
        //   }
        // }
        let semenDoseOwnershipReferenceNumber = "";

        // if (formModel.typeOfCovering === "ArtificialInseminationLocal" && formModel.stallionOwnerSignedPermitPart && formModel.notStallionOwner) {
        if (formModel.typeOfCovering === "ArtificialInseminationLocal") {
          semenDoseOwnershipReferenceNumber = formModel.semenDoseOwnershipReferenceNumber;
        }

        let isViolated = false;

        if (
          (formModel.isStallionRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
          || (formModel.isStallionRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
          || (formModel.isMareRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
          || (formModel.isMareRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3))
        ) {
          isViolated = true;
        }

        let params = {
          id: id,
          serviceDefinitionId: serviceId,
          date: new Date().toLocaleDateString(),
          status: status,
          isViolated: isViolated,
          // status: formModel.eventName === "SentBack" ? "PendingReview" : status,
          eventName: formModel.eventName,
          isStallionRegistered: formModel.isStallionRegistered,
          notStallionOwner: formModel.notStallionOwner,
          horseStallion: formModel.notStallionOwner ? formModel.horseNotStallion : formModel.horseStallionReg,
          horseNameEnStallionUnReg: formModel.horseNameEnStallionUnReg,
          horseNameArStallionUnReg: formModel.horseNameArStallionUnReg,
          damEnStallionUnReg: formModel.damEnStallionUnReg,
          damArStallionUnReg: formModel.damArStallionUnReg,
          sireEnStallionUnReg: formModel.sireEnStallionUnReg,
          sireArStallionUnReg: formModel.sireArStallionUnReg,
          ownerNameEnStallionUnReg: formModel.ownerNameEnStallionUnReg,
          ownerNameArStallionUnReg: formModel.ownerNameArStallionUnReg,
          breederNameEnStallionUnReg: formModel.breederNameEnStallionUnReg,
          breederNameArStallionUnReg: formModel.breederNameArStallionUnReg,
          horseAddressStallionUnReg: formModel.horseAddressStallionUnReg,
          currentRegNoStallionUnReg: formModel.currentRegNoStallionUnReg,
          uelnStallionUnReg: formModel.uelnStallionUnReg,
          colorStallionUnReg: formModel.colorStallionUnReg != null ? formModel.colorStallionUnReg : "",
          originStallionUnReg: formModel.originStallionUnReg != null ? formModel.originStallionUnReg : "",
          horseCountryStallionUnReg: formModel.horseCountryStallionUnReg != null ? formModel.horseCountryStallionUnReg : "",
          birthDateStallionUnReg: formModel.birthDateStallionUnReg != null ? dayjs(formModel.birthDateStallionUnReg).format("DD/MM/YYYY") : '',

          isMareRegistered: formModel.isMareRegistered,
          horseMareReg: formModel.horseMareReg,
          horseNameEnMareUnReg: formModel.horseNameEnMareUnReg,
          horseNameArMareUnReg: formModel.horseNameArMareUnReg,
          damEnMareUnReg: formModel.damEnMareUnReg,
          damArMareUnReg: formModel.damArMareUnReg,
          sireEnMareUnReg: formModel.sireEnMareUnReg,
          sireArMareUnReg: formModel.sireArMareUnReg,
          breederNameEnMareUnReg: formModel.breederNameEnMareUnReg,
          breederNameArMareUnReg: formModel.breederNameArMareUnReg,
          horseAddressMareUnReg: formModel.horseAddressMareUnReg,
          currentRegNoMareUnReg: formModel.currentRegNoMareUnReg,
          uelnMareUnReg: formModel.uelnMareUnReg,
          colorMareUnReg: formModel.colorMareUnReg != null ? formModel.colorMareUnReg : "",
          originMareUnReg: formModel.originMareUnReg != null ? formModel.originMareUnReg : "",
          horseCountryMareUnReg: formModel.horseCountryMareUnReg != null ? formModel.horseCountryMareUnReg : "",
          birthDateMareUnReg: formModel.birthDateMareUnReg != null ? dayjs(formModel.birthDateMareUnReg).format("DD/MM/YYYY") : '',

          typeOfCovering: formModel.typeOfCovering,
          coveringDate: dayjs(formModel.coveringDate).format("DD/MM/YYYY"),
          breedingHappendAbroad: formModel.breedingHappendAbroad,
          embryoIsImported: formModel.embryoIsImported,
          stallionOwnerSignedPermit: formModel.stallionOwnerSignedPermit,

          breedingHappendAbroadAttachmentFile: formModel.breedingHappendAbroadAttachmentFile,
          embryoIsImportedAttachmentFile: formModel.embryoIsImportedAttachmentFile,
          stallionOwnerSignedPermitAttachmentFile: formModel.stallionOwnerSignedPermitAttachmentFile,
          importedSemenCertificateAttachmentFile: formModel.importedSemenCertificateAttachmentFile,
          ownerId: getUserID(),
          ownerIdNotStallionReg: formModel.ownerIdNotStallionReg != null ? formModel.ownerIdNotStallionReg : "",
          createdBy: localStorage.getItem("EAHSUserID"),
          coveringCertificateId: formModel.coveringCertificateId,
          referenceNumberSemenCollectionCertificate: semenDoseOwnershipReferenceNumber
        };

        if (action == null) {
          dispatch(FetchCreateCoveringCertificateServiceRequestthunk(params));
        } else if (action === "edit" || formModel.eventName === "SentBack") {
          dispatch(FetchUpdateCoveringCertificateServiceRequestthunk(params));
        }
      }
    }
  };

  const handleUploadBreedingHappendAbroadChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          breedingHappendAbroadAttachmentFile: e.target.files[0],
        });

        setBreedingHappendAbroadAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadStallionOwnerSignedPermitChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          stallionOwnerSignedPermitAttachmentFile: e.target.files[0],
        });

        setStallionOwnerSignedPermitAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadEmbryoIsImportedChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          embryoIsImportedAttachmentFile: e.target.files[0],
        });

        setEmbryoIsImportedAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handleUploadImportedSemenCertificateChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          importedSemenCertificateAttachmentFile: e.target.files[0],
        });

        setImportedSemenCertificateAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horseStallionReg: "",
      horseStallionRegDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      regNoStallionReg: "",
      horseNameStallionReg: "",
      originStallionReg: "",
      breederStallionReg: "",
      genderStallionReg: "",
      colorStallionReg: "",
      birthDateStallionReg: "",
      sireStallionReg: "",
      damStallionReg: "",

      horseMareReg: "",
      horseMareRegDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      originMareReg: "",
      regNoMareReg: "",
      horseNameMareReg: "",
      genderMareReg: "",
      colorMareReg: "",
      birthDateMareReg: "",
      sireMareReg: "",
      damMareReg: "",
      breederMareReg: "",

    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  const closeModal = () => setOpenPopup(false);
  const closeModalCancel = () => setOpenPopupCancel(false);

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName == "CoveringCertificates" || x.permissionName == "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const handelApproveRejectRequest = (status) => {
    _status.current = status;

    let params = {
      id: id,
      status: status,
      comment: formModel.stallionComment,
    };

    dispatch(FetchApproveRejectRequestCoveringCertificatethunk(params));
  };

  const handelCancelRequest = () => {
    let params = {
      serviceId: id,
      ownerId: localStorage.getItem("EAHSUserID"),
    };

    dispatch(FetchCancelRequestthunk(params));
  };

  // async search
  const promiseOptions = (searchKeyword) =>
    new Promise((resolve) => {
      if (timeoutId > 0) {
        clearTimeout(timeoutId);
      }

      const id = setTimeout(async () => {
        const response = await axiosInstance.post(
          "/ServicesRequests/GetRegisteredHorses",
          {
            lang: localStorage.getItem("eahsLang") ?? "en",
            searchValue: searchKeyword,
          }
        );

        const data = await response.data.horsesList.filter(item => item.gender == "Stallion");

        const options = data.map((item) => ({
          label: item.registerNumber + " - " + item.name,
          value: item.id,
          details: item,
        }));
        resolve(options);
      }, 1000);

      setTimeoutId(id);
    });

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, [id]);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (!!userProfile && userProfile?.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    dispatch(FetchCountriesthunk({ langauge: currentLanguage }));
    dispatch(FetchHorseColorsthunk({ langauge: currentLanguage }));

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));

  }, [currentLanguage]);

  useEffect(() => {
    if (action != null) {
      loadd.current = true;
    }

    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
      //&&checkAnotherOwner.current
    ) {
      setFormModel({
        ...formModel,
        isStallionRegistered: getServicesRequestByIdData.coveringCertificates.isStallionRegistered,
        notStallionOwner: getServicesRequestByIdData.coveringCertificates.notStallionOwner,
        stallionOwnerSignedPermitPart: getServicesRequestByIdData.coveringCertificates.notStallionOwner === true || getServicesRequestByIdData.coveringCertificates.isStallionRegistered === "Unregistered",
        isMareRegistered: getServicesRequestByIdData.coveringCertificates.isMareRegistered,
        typeOfCovering: getServicesRequestByIdData.coveringCertificates.typeOfCovering,
        coveringDate: getServicesRequestByIdData.coveringCertificates.coveringDate != null ? dayjs(getServicesRequestByIdData.coveringCertificates.coveringDate).format("YYYY-MM-DD") : null,
        breedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.breedingHappendAbroad,
        embryoIsImported: getServicesRequestByIdData.coveringCertificates.embryoIsImported,
        stallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.stallionOwnerSignedPermit,
        filePathBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.filePathBreedingHappendAbroad,
        fileTypeBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.fileTypeBreedingHappendAbroad,
        fileNameBreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.fileNameBreedingHappendAbroad,
        documentBase64BreedingHappendAbroad: getServicesRequestByIdData.coveringCertificates.documentBase64BreedingHappendAbroad,

        filePathEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.filePathEmbryoIsImported,
        fileTypeEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.fileTypeEmbryoIsImported,
        fileNameEmbryoIsImported: getServicesRequestByIdData.coveringCertificates.fileNameEmbryoIsImported,
        documentBase64EmbryoIsImported: getServicesRequestByIdData.coveringCertificates.documentBase64EmbryoIsImported,

        filePathStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.filePathStallionOwnerSignedPermit,
        fileTypeStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.fileTypeStallionOwnerSignedPermit,
        fileNameStallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.fileNameStallionOwnerSignedPermit,
        documentBase64StallionOwnerSignedPermit: getServicesRequestByIdData.coveringCertificates.documentBase64StallionOwnerSignedPermit,

        filePathImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.filePathImportedSemenCertificate,
        fileTypeImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.fileTypeImportedSemenCertificate,
        fileNameImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.fileNameImportedSemenCertificate,
        documentBase64ImportedSemenCertificate: getServicesRequestByIdData.coveringCertificates.documentBase64ImportedSemenCertificate,

        stallionComment: getServicesRequestByIdData.coveringCertificates.stallionComment,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        coveringCertificateId: getServicesRequestByIdData.coveringCertificates.coveringCertificateId,
        horseMareRegLessThanOneWeek: getServicesRequestByIdData.coveringCertificates.horseMareRegLessThanOneWeek,

        horseStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id ?? "",
        horseStallionRegDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        },
        horseNotStallion: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        horseNotStallionDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseStallionVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.id,
        },
        ownerIdNotStallionReg: getServicesRequestByIdData.coveringCertificates.ownerIdNotStallionReg,
        ownerNameStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerName,
        ownerEmailStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerEmail,
        ownerMobileStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.ownerMobile,
        originStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.origin,
        breederStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.breeder,
        genderStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.gender,
        colorStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.color,
        birthDateStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.birthDate,
        sireStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.sire,
        damStallionReg: getServicesRequestByIdData.coveringCertificates.horseStallionVM?.dam,
        horseNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseNameEnStallionUnReg,
        horseNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseNameArStallionUnReg,
        damEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.damEnStallionUnReg,
        damArStallionUnReg: getServicesRequestByIdData.coveringCertificates.damArStallionUnReg,
        sireEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.sireEnStallionUnReg,
        sireArStallionUnReg: getServicesRequestByIdData.coveringCertificates.sireArStallionUnReg,
        ownerNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameEnStallionUnReg,
        ownerNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameArStallionUnReg,
        breederNameEnStallionUnReg: getServicesRequestByIdData.coveringCertificates.breederNameEnStallionUnReg,
        breederNameArStallionUnReg: getServicesRequestByIdData.coveringCertificates.breederNameArStallionUnReg,
        horseAddressStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseAddressStallionUnReg,
        currentRegNoStallionUnReg: getServicesRequestByIdData.coveringCertificates.currentRegNoStallionUnReg,
        uelnStallionUnReg: getServicesRequestByIdData.coveringCertificates.uelnStallionUnReg,
        colorStallionUnReg: getServicesRequestByIdData.coveringCertificates.colorStallionUnReg,
        colorStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.colorNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.colorStallionUnReg,
        },
        originStallionUnReg: getServicesRequestByIdData.coveringCertificates.originStallionUnReg,
        originStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.originNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.originStallionUnReg,
        },
        horseCountryStallionUnReg: getServicesRequestByIdData.coveringCertificates.horseCountryStallionUnReg,
        horseCountryStallionUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.horseCountryNameStallionUnReg,
          value: getServicesRequestByIdData.coveringCertificates.horseCountryStallionUnReg,
        },
        birthDateStallionUnReg: getServicesRequestByIdData.coveringCertificates.birthDateStallionUnReg != null ? dayjs(getServicesRequestByIdData.coveringCertificates.birthDateStallionUnReg).format("YYYY-MM-DD") : null,
        horseMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.id ?? "",
        horseMareRegDrop: {
          label:
            getServicesRequestByIdData.coveringCertificates.horseMareVM?.regNo +
            " - " +
            getServicesRequestByIdData.coveringCertificates.horseMareVM?.name,
          value: getServicesRequestByIdData.coveringCertificates.horseMareVM?.id,
        },
        originMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.origin,
        breederMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.breeder,
        genderMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.gender,
        colorMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.color,
        birthDateMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.birthDate,
        sireMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.sire,
        damMareReg: getServicesRequestByIdData.coveringCertificates.horseMareVM?.dam,
        horseNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.horseNameEnMareUnReg,
        horseNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.horseNameArMareUnReg,
        damEnMareUnReg: getServicesRequestByIdData.coveringCertificates.damEnMareUnReg,
        damArMareUnReg: getServicesRequestByIdData.coveringCertificates.damArMareUnReg,
        sireEnMareUnReg: getServicesRequestByIdData.coveringCertificates.sireEnMareUnReg,
        sireArMareUnReg: getServicesRequestByIdData.coveringCertificates.sireArMareUnReg,
        ownerNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameEnMareUnReg,
        ownerNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.ownerNameArMareUnReg,
        breederNameEnMareUnReg: getServicesRequestByIdData.coveringCertificates.breederNameEnMareUnReg,
        breederNameArMareUnReg: getServicesRequestByIdData.coveringCertificates.breederNameArMareUnReg,
        horseAddressMareUnReg: getServicesRequestByIdData.coveringCertificates.horseAddressMareUnReg,
        currentRegNoMareUnReg: getServicesRequestByIdData.coveringCertificates.currentRegNoMareUnReg,
        uelnMareUnReg: getServicesRequestByIdData.coveringCertificates.uelnMareUnReg,
        colorMareUnReg: getServicesRequestByIdData.coveringCertificates.colorMareUnReg,
        colorMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.colorNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.colorMareUnReg,
        },
        originMareUnReg: getServicesRequestByIdData.coveringCertificates.originMareUnReg,
        originMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.originNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.originMareUnReg,
        },
        horseCountryMareUnReg: getServicesRequestByIdData.coveringCertificates.horseCountryMareUnReg,
        horseCountryMareUnRegDrop: {
          label: getServicesRequestByIdData.coveringCertificates.horseCountryNameMareUnReg,
          value: getServicesRequestByIdData.coveringCertificates.horseCountryMareUnReg,
        },
        birthDateMareUnReg: getServicesRequestByIdData.coveringCertificates.birthDateMareUnReg != null ? dayjs(getServicesRequestByIdData.coveringCertificates.birthDateMareUnReg).format("YYYY-MM-DD") : null,
        semenDoseOwnershipReferenceNumber: getServicesRequestByIdData.coveringCertificates.referenceNumberSemenCollectionCertificate,
        violationPayment: getServicesRequestByIdData.coveringCertificates.violationPayment,
      });

      loadd.current = false;

      //another owner
      if (
        getServicesRequestByIdData.coveringCertificates.ownerId !==
        getServicesRequestByIdData.coveringCertificates.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.coveringCertificates.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //checkAnotherOwner.current = false;
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.coveringCertificates.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.coveringCertificates.ownerId
            )[0][`text${currentLanguage == "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.coveringCertificates.ownerId
          )[0].value,
        });
      }

      //checkAnotherOwner.current = false;

      //options
      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
      });
    }
  }, [
    getServicesRequestByIdData,
    //subOwnerProfilesByIdData,
  ]);


  useEffect(() => {
    //save
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "" || _status.current === "Approve") {
        toast.success(
          translate(
            "web.yourRequestHasSuccessfully",
            "Your Request Has Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "" && _status.current === "Draft") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id == null ? createCoveringCertificateServiceRequestData.id : id}&paymentType=11&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div key={id} className="container-fluid container-fluid-padding">

      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading || loadd.current
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />

      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl ">

          {/* convering certificate title */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage == "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>

          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a.registerNo +
                              " - " +
                              a[`text${currentLanguage == "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName().registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage == "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          {/* stallion details */}
          <EahsForm title={translate("web.stallionDetails", "Stallion Details")}>
            {/* stallion radioServiceRequest buttons */}
            <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"stallionType"}
                  type={"radioServiceRequest"}
                  value={"Registered"}
                  label={`${translate("web.registered", "Registered")}-`}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === "Registered"
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"stallionType"}
                  type={"radioServiceRequest"}
                  value={"Unregistered"}
                  label={`${translate("web.unregistered", "Unregistered")}-`}
                  onChange={(e) => handleFormChange(e, "stallionType")}
                  checked={
                    formModel.isStallionRegistered === "Unregistered"
                  }
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
            </div>

            {/* stallion content registered */}
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-5 rtl"
              style={{
                display: formModel.isStallionRegistered == "Registered" ? "" : "none",
              }}
            >
              {
                //stallion details
              }
              <div className="col-md-12 col-sm-12 rtl p0">
                <NetaqInput
                  type={"checkbox"}
                  label={translate(
                    "web.notStallionOwner",
                    "i'm not the stallion's owner"
                  )}
                  name="notStallionOwner"
                  checked={formModel.notStallionOwner}
                  onChange={(e) => handleFormChange(e, "notStallionOwner")}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3"
                style={{
                  display: !formModel.notStallionOwner ? "" : "none",
                }}>
                <label>
                  {translate("web.horse", "Horse")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horse", "Horse"),
                      value: null,
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.filter(q => q.gender === "Stallion").map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.horseStallionRegDrop}
                  onChange={(e) => handleFormChange(e, "horseStallionReg")}
                  placeholder={translate("web.horse", "Horse")}
                  name={"horseStallionReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horseStallionReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseStallionReg }} className="errorHorseStallionReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3"
                style={{
                  display: formModel.notStallionOwner ? "" : "none",
                }}>
                <label>
                  {translate("web.horse", "Horse")}
                  <i className="colorred"> *</i>
                </label>
                <AsyncSelect
                  isClearable
                  loadOptions={promiseOptions}
                  defaultOptions={false} // Set to true to load initial options
                  onInputChange={(newValue) => setInputValue(newValue)}
                  inputValue={inputValue}
                  value={formModel.horseNotStallionDrop}
                  loadingMessage={() => translate("web.loading", "Loading")}
                  onChange={(e) => handleFormChange(e, "horseNotStallion")}
                  placeholder={
                    translate("web.typeToSearch", "Type To Search") + "..."
                  }
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"horseNotStallion"}
                  isDisabled={
                    (action === "approve" || action == "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseStallionReg }} className="errorHorseStallionReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.origin", "Origin")}
                  name={"origin"}
                  type={"text"}
                  disabled={true}
                  value={formModel.originStallionReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breederStallionReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.gender", "Gender")}
                  name={"gender"}
                  type={"text"}
                  disabled={true}
                  value={translate(
                    `web.${formModel.genderStallionReg?.toLowerCase()}`,
                    formModel.genderStallionReg
                  )}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"color"}
                  type={"text"}
                  disabled={true}
                  value={formModel.colorStallionReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.birthDate", "Birth Date")}
                  name={"birthDate"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.birthDateStallionReg != null &&
                      formModel.birthDateStallionReg !== ""
                      ? formModel.birthDateStallionReg &&
                      formatDate(formModel.birthDateStallionReg)
                      : ""
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"sire"}
                  type={"text"}
                  disabled={true}
                  value={formModel.sireStallionReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"dam"}
                  type={"text"}
                  disabled={true}
                  value={formModel.damStallionReg}
                />
              </div>
              <div
                className="col-md-4 col-sm-12 mt-3"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.ownerName", "Owner Name")}
                  name={"ownerName"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerNameStallionReg}
                />
              </div>
              <div
                className="col-md-4 col-sm-12 mt-3"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.email", "Email")}
                  name={"ownerEmail"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerEmailStallionReg}
                />
              </div>
              <div
                className="col-md-4 col-sm-12 mt-3"
                style={{
                  display: formModel.notStallionOwner == true ? "" : "none",
                }}
              >
                <NetaqInput
                  label={translate("web.smsPhoneNo", "Mobile No")}
                  name={"ownerMobile"}
                  type={"text"}
                  disabled={true}
                  value={formModel.ownerMobileStallionReg}
                />
              </div>
            </div>

            {/* stallion content unregistered*/}
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-3 rtl"
              style={{
                display: formModel.isStallionRegistered === "Unregistered" ? "" : "none",
              }}
            >
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.name", "Name") + " (EN)"}
                  name={"horseNameEnStallionUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "horseNameEnStallionUnReg")}
                  value={formModel.horseNameEnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseNameEnStallionUnReg }} className="errorHorseNameEnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.name", "Name") + " (AR)"}
                  name={"horseNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseNameArStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseNameArStallionUnReg }} className="errorHorseNameArStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire") + " (EN)"}
                  name={"sireEnStallionUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "sireEnStallionUnReg")}
                  value={formModel.sireEnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorSireEnStallionUnReg }} className="errorSireEnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire") + " (AR)"}
                  name={"sireArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.sireArStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorSireArStallionUnReg }} className="errorSireArStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam") + " (EN)"}
                  name={"damEnStallionUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "damEnStallionUnReg")}
                  value={formModel.damEnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorDamEnStallionUnReg }} className="errorDamEnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam") + ' (AR)'}
                  name={"damArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.damArStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorDamArStallionUnReg }} className="errorDamArStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ownerName", "Owner Name") + " (EN)"}
                  name={"ownerNameEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.ownerNameEnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorOwnerNameEnStallionUnReg }} className="errorOwnerNameEnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ownerName", "Owner Name") + " (AR)"}
                  name={"ownerNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.ownerNameArStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorOwnerNameArStallionUnReg }} className="errorOwnerNameArStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederName", "breederName") + " (EN)"}
                  name={"breederNameEnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameEnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorBreederNameEnStallionUnReg }} className="errorBreederNameEnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederName", "breederName") + " (AR)"}
                  name={"breederNameArStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameArStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorBreederNameArStallionUnReg }} className="errorBreederNameArStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.horseAddress", "Horse Address")}
                  name={"horseAddressStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseAddressStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseAddressStallionUnReg }} className="errorHorseAddressStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.regno", "Reg.No")}
                  name={"currentRegNoStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.currentRegNoStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorCurrentRegNoStallionUnReg }} className="errorCurrentRegNoStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ueln", "UELN")}
                  name={"uelnStallionUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.uelnStallionUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorUelnStallionUnReg }} className="errorUelnStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.color", "Color")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.color", "Color"),
                      value: null,
                    },
                    ...(!!horseColorsData &&
                      horseColorsData.map((a) => ({
                        label: a.nameEn,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.colorStallionUnRegDrop}
                  onChange={(e) => handleFormChange(e, "colorStallionUnReg")}
                  placeholder={translate("web.color", "Color")}
                  name={"colorStallionUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"colorStallionUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorColorStallionUnReg }} className="errorColorStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.origin", "Origin")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.chooseOrigin", "Choose Origin"),
                      value: null,
                    },
                    ...(!!countriesData &&
                      countriesData.map((a) => ({
                        label: a.text,
                        value: a.value,
                      }))),
                  ]}
                  value={formModel.originStallionUnRegDrop}
                  onChange={(e) => handleFormChange(e, "originStallionUnReg")}
                  placeholder={translate("web.chooseOrigin", "Choose Origin")}
                  name={"originStallionUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"originStallionUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorOriginStallionUnReg }} className="errorOriginStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.horseCountry", "Horse Country")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horseCountry", "Horse Country"),
                      value: null,
                    },
                    ...(!!countriesData &&
                      countriesData.map((a) => ({
                        label: a.text,
                        value: a.value,
                      }))),
                  ]}
                  value={formModel.horseCountryStallionUnRegDrop}
                  onChange={(e) => handleFormChange(e, "horseCountryStallionUnReg")}
                  placeholder={translate("web.horseCountry", "Horse Country")}
                  name={"horseCountryStallionUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"horseCountryStallionUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseCountryStallionUnReg }} className="errorHorseCountryStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3 service">
                <NetaqInput
                  htmlTags={
                    formModel.birthDateStallionUnReg ?
                      <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                        {
                          dayjs(formModel.birthDateStallionUnReg).format("DD/MM/YYYY")
                        }
                      </p> :
                      <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                        DD/MM/YYYY
                      </p>
                  }
                  name={"birthDateStallionUnReg"}
                  type={"normalDateService"}
                  //onKeydown={(e) => e.preventDefault()}
                  label={translate("web.birthDate", "Birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDateStallionUnReg")}
                  value={formModel.birthDateStallionUnReg}
                  required={true}
                  maxDate={dayjs().format("YYYY-MM-DD")}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />

                {/* <NetaqInput
                  name={"birthDateStallionUnReg"}
                  type={"date"}
                  label={translate("web.birthDate", "Birth Date")}
                  onChange={(e) =>
                    handleFormChange(e, "birthDateStallionUnReg")
                  }
                  maxDate={dayjs()}
                  value={formModel.birthDateStallionUnReg}
                  required={true}
                  disabled={
                    (action === "approve" || action === "view") ?
                      true
                      : false
                  }
                /> */}
                <span style={{ color: "red", display: errorBirthDateStallionUnReg }} className="errorBirthDateStallionUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>

            </div>
          </EahsForm>

          {/* mare details*/}
          <EahsForm title={translate("web.mareDetails", "Mare Details")}>
            {/* mare radioServiceRequest buttons */}
            <div className="AuctionFilterradioServiceRequestContainer mt-3 row">
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"mareType"}
                  type={"radioServiceRequest"}
                  value={"Registered"}
                  label={translate("web.registered", "Registered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === "Registered"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
              <div style={{ maxWidth: 150 }}>
                <NetaqInput
                  name={"mareType"}
                  type={"radioServiceRequest"}
                  value={"Unregistered"}
                  label={translate("web.unregistered", "Unregistered")}
                  onChange={(e) => handleFormChange(e, "mareType")}
                  checked={formModel.isMareRegistered === "Unregistered"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
            </div>
            {/* mare content registered */}
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-5 rtl"
              style={{
                display: formModel.isMareRegistered === "Registered" ? "" : "none",
              }}
            >
              {
                //mare details
              }
              <div className="col-md-4 col-sm-12">
                <label>
                  {translate("web.horse", "Horse")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horse", "Horse"),
                      value: "",
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.horseMareRegDrop}
                  onChange={(e) => handleFormChange(e, "horseMareReg")}
                  placeholder={translate("web.horse", "Horse")}
                  name={"horseMareReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horseMareReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseMareReg }} className="errorHorseMareReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 ">
                <NetaqInput
                  label={translate("web.origin", "Origin")}
                  name={"origin"}
                  type={"text"}
                  disabled={true}
                  value={formModel.originMareReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 ">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breederMareReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.gender", "Gender")}
                  name={"gender"}
                  type={"text"}
                  disabled={true}
                  value={translate(
                    `web.${formModel.genderMareReg?.toLowerCase()}`,
                    formModel.genderMareReg
                  )}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"color"}
                  type={"text"}
                  disabled={true}
                  value={formModel.colorMareReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.birthDate", "Birth Date")}
                  name={"birthDate"}
                  type={"text"}
                  disabled={true}
                  value={
                    formModel.birthDateMareReg != null &&
                      formModel.birthDateMareReg !== ""
                      ? formModel.birthDateMareReg &&
                      formatDate(formModel.birthDateMareReg)
                      : ""
                  }
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"sire"}
                  type={"text"}
                  disabled={true}
                  value={formModel.sireMareReg}
                />
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"dam"}
                  type={"text"}
                  disabled={true}
                  value={formModel.damMareReg}
                />
              </div>
            </div>
            {/* mare content unregistered */}
            <div
              className="row mx-0 col-md-12 col-sm-12 mt-3 rtl"
              style={{
                display: formModel.isMareRegistered === "Unregistered" ? "" : "none",
              }}
            >
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.name", "Name") + " (EN)"}
                  name={"horseNameEnMareUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "horseNameEnMareUnReg")}
                  value={formModel.horseNameEnMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseNameEnMareUnReg }} className="errorHorseNameEnMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.name", "Name") + " (AR)"}
                  name={"horseNameArMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseNameArMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseNameArMareUnReg }} className="errorHorseNameArMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire") + " (EN)"}
                  name={"sireEnMareUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "sireEnMareUnReg")}
                  value={formModel.sireEnMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorSireEnMareUnReg }} className="errorSireEnMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire") + " (AR)"}
                  name={"sireArMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.sireArMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorSireArMareUnReg }} className="errorSireArMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam") + " (EN)"}
                  name={"damEnMareUnReg"}
                  type={"text"}
                  onChange={(e) => handleFormChange(e, "damEnMareUnReg")}
                  value={formModel.damEnMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorDamEnMareUnReg }} className="errorDamEnMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam") + " (AR)"}
                  name={"damArMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.damArMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorDamArMareUnReg }} className="errorDamArMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederName", "Breeder Name") + " (EN)"}
                  name={"breederNameEnMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameEnMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorBreederNameEnMareUnReg }} className="errorBreederNameEnMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.breederName", "Breeder Name") + " (AR)"}
                  name={"breederNameArMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.breederNameArMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorBreederNameArMareUnReg }} className="errorBreederNameArMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.horseAddress", "Horse Address")}
                  name={"horseAddressMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.horseAddressMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseAddressMareUnReg }} className="errorHorseAddressMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.regno", "Reg.No")}
                  name={"currentRegNoMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.currentRegNoMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorCurrentRegNoMareUnReg }} className="errorCurrentRegNoMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.ueln", "UELN")}
                  name={"uelnMareUnReg"}
                  type={"text"}
                  onChange={handleFormChange}
                  value={formModel.uelnMareUnReg}
                  required={true}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorUelnMareUnReg }} className="errorUelnMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.color", "Color")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.color", "Color"),
                      value: null,
                    },
                    ...(!!horseColorsData &&
                      horseColorsData.map((a) => ({
                        label: a.nameEn,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.colorMareUnRegDrop}
                  onChange={(e) => handleFormChange(e, "colorMareUnReg")}
                  placeholder={translate("web.color", "Color")}
                  name={"colorMareUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"colorMareUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorColorMareUnReg }} className="errorColorMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.origin", "Origin")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.chooseOrigin", "Choose Origin"),
                      value: null,
                    },
                    ...(!!countriesData &&
                      countriesData.map((a) => ({
                        label: a.text,
                        value: a.value,
                      }))),
                  ]}
                  value={formModel.originMareUnRegDrop}
                  onChange={(e) => handleFormChange(e, "originMareUnReg")}
                  placeholder={translate("web.chooseOrigin", "Choose Origin")}
                  name={"originMareUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"originMareUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorOriginMareUnReg }} className="errorOriginMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3">
                <label>
                  {translate("web.horseCountry", "Horse Country")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horseCountry", "Horse Country"),
                      value: null,
                    },
                    ...(!!countriesData &&
                      countriesData.map((a) => ({
                        label: a.text,
                        value: a.value,
                      }))),
                  ]}
                  value={formModel.horseCountryMareUnRegDrop}
                  onChange={(e) => handleFormChange(e, "horseCountryMareUnReg")}
                  placeholder={translate("web.horseCountry", "Horse Country")}
                  name={"horseCountryMareUnReg"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  key={"horseCountryMareUnReg"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorseCountryMareUnReg }} className="errorHorseCountryMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
              <div className="col-md-4 col-sm-12 mt-3 service">
                <NetaqInput
                  htmlTags={
                    formModel.birthDateMareUnReg ?
                      <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                        {
                          dayjs(formModel.birthDateMareUnReg).format("DD/MM/YYYY")
                        }
                      </p> :
                      <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                        DD/MM/YYYY
                      </p>
                  }
                  name={"birthDateMareUnReg"}
                  type={"normalDateService"}
                  label={translate("web.birthDate", "birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDateMareUnReg")}
                  value={formModel.birthDateMareUnReg}
                  // onKeydown={(e) => e.preventDefault()}
                  required={true}
                  maxDate={dayjs().format("YYYY-MM-DD")}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />

                {/* <NetaqInput
                  name={"birthDateMareUnReg"}
                  type={"date"}
                  label={translate("web.birthDate", "birth Date")}
                  onChange={(e) => handleFormChange(e, "birthDateMareUnReg")}
                  maxDate={dayjs()}
                  value={formModel.birthDateMareUnReg}
                  required={true}
                  disabled={
                    (action === "approve" || action === "view") ?
                      true
                      : false
                  }
                /> */}
                <span style={{ color: "red", display: errorBirthDateMareUnReg }} className="errorBirthDateMareUnReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
            </div>
          </EahsForm>

          {/* covering details */}
          <EahsForm title={translate("web.coveringDetails", "Covering Details")}>
            <div className="col-md-6 col-sm-12 rtl">
              <h2 className="fontWeight colorred">
                {translate("web.typeOfCovering", "Type Of Covering")}
              </h2>
              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  type={"radioServiceRequest"}
                  value={"NaturalBreeding"}
                  label={translate(
                    "web.naturalBreeding",
                    "Natural (In-Hand) Breeding"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCovering === "NaturalBreeding"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              <div className="col-md-12 col-sm-12 rtl p0 mt-3">
                <NetaqInput
                  name={"typeOfCovering"}
                  type={"radioServiceRequest"}
                  value={"ArtificialInseminationLocal"}
                  label={translate(
                    "web.artificialInseminationLocal",
                    "Artificial Insemination (Locally Collected Semen)"
                  )}
                  onChange={handleFormChange}
                  checked={formModel.typeOfCovering === "ArtificialInseminationLocal"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>

              <div className="col-md-12 col-sm-12 rtl p0 mt-3 row">
                <div className="col-5">
                  <NetaqInput
                    name={"typeOfCovering"}
                    type={"radioServiceRequest"}
                    value={"ArtificialInseminationImported"}
                    label={translate(
                      "web.artificialInseminationImported",
                      "Artificial Insemination (Imported Semen)"
                    )}
                    onChange={handleFormChange}
                    checked={formModel.typeOfCovering === "ArtificialInseminationImported"}
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                </div>

                {
                  // imported Semen Certificate                       
                  formModel.typeOfCovering === "ArtificialInseminationImported" &&
                  <div className="col-7">
                    <SimpleFileUpload
                      contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                      isMulti={false}
                      initialFiles={
                        !!formModel.filePathImportedSemenCertificate &&
                          !!formModel.documentBase64ImportedSemenCertificate &&
                          importedSemenCertificateAttachmentFile.length == 0
                          ? [
                            {
                              file: base64ToBlob(
                                formModel.documentBase64ImportedSemenCertificate,
                                formModel.fileTypeImportedSemenCertificate ===
                                  ".pdf"
                                  ? "application/pdf"
                                  : "image/png"
                              ),
                              name: formModel.fileNameImportedSemenCertificate,
                            },
                          ]
                          : importedSemenCertificateAttachmentFile
                      }
                      isServer={
                        !!formModel.filePathImportedSemenCertificate &&
                          !!formModel.documentBase64ImportedSemenCertificate &&
                          importedSemenCertificateAttachmentFile.length == 0
                          ? true
                          : false
                      }
                      onChange={handleUploadImportedSemenCertificateChange}
                      accept={"image/*,application/pdf"}
                      disabled={
                        ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                          true
                          : false
                      }
                    />
                    <span style={{ color: "red", display: errorImportedSemenCertificate }} className="errorImportedSemenCertificate">
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  </div>
                }
              </div>
            </div>

            <div className="col-md-4 col-sm-12 rtl service">
              <NetaqInput
                htmlTags={
                  formModel.coveringDate ?
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      {
                        dayjs(formModel.coveringDate).format("DD/MM/YYYY")
                      }
                    </p> :
                    <p className="me-auto px-3 effectiveDateParagraph" style={{ textAlign: 'left' }}>
                      DD/MM/YYYY
                    </p>
                }
                name={"coveringDate"}
                type={"normalDateService"}
                //onKeydown={(e) => e.preventDefault()}
                label={translate("web.coveringDate", "Covering Date")}
                onChange={(e) => handleFormChange(e, "coveringDate")}
                value={formModel.coveringDate}
                required={true}
                minDate={dayjs(oneYearAgo).format("YYYY-MM-DD")}
                maxDate={dayjs().format("YYYY-MM-DD")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorCoveringDate }} className="errorCoveringDate">
                {translate("web.requiredField", "Required Field")}
              </span>

              {
                formModel.typeOfCovering === "ArtificialInseminationLocal" &&
                <div className="mt-3">
                  <NetaqInput
                    label={translate("web.semenDoseOwnershipReferenceNumber", "Semen Dose Ownership Reference Number")}
                    name={"semenDoseOwnershipReferenceNumber"}
                    type={"text"}
                    onChange={handleFormChange}
                    value={formModel.semenDoseOwnershipReferenceNumber}
                    required={true}
                    //placeholder={"SDO-#-DD"}
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                  <span style={{ color: "red", display: errorSemenDoseOwnershipReferenceNumber }} className="errorSemenDoseOwnershipReferenceNumber">
                    {translate("web.requiredField", "Required Field")}
                  </span>
                </div>
              }
            </div>
          </EahsForm>

          {/* upload attachments */}
          <EahsForm title={translate("web.uploadDocument", "Upload Document")}>
            {/* breeding happend abroad */}
            <div className="col-md-2 col-sm-6  col-12 rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.breedingHappendAbroad",
                  "The Breeding Happend Abroad"
                )}
                name="breedingHappendAbroad"
                checked={formModel.breedingHappendAbroad}
                onChange={(e) => handleFormChange(e, "breedingHappendAbroad")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>

            <div className="col-md-10 col-sm-6 row rtl mt-1 me-auto">
              {/* document file button breeding happend abroad */}
              <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                <SimpleFileUpload
                  contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathBreedingHappendAbroad &&
                      !!formModel.documentBase64BreedingHappendAbroad &&
                      breedingHappendAbroadAttachmentFile.length == 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64BreedingHappendAbroad,
                            formModel.fileTypeBreedingHappendAbroad === ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameBreedingHappendAbroad,
                        },
                      ]
                      : breedingHappendAbroadAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathBreedingHappendAbroad &&
                      !!formModel.documentBase64BreedingHappendAbroad &&
                      breedingHappendAbroadAttachmentFile.length == 0
                      ? true
                      : false
                  }
                  onChange={handleUploadBreedingHappendAbroadChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : (formModel.breedingHappendAbroad ? false : true)
                  }
                />
                <span style={{ color: "red", display: errorBreedingHappendAbroad }} className="errorBreedingHappendAbroad">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
            </div>

            {/* embryo is imported checkbox */}
            <div className="col-md-2 col-sm-6  col-12  rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoIsImported",
                  "The embryo is imported in utero"
                )}
                name="embryoIsImported"
                checked={formModel.embryoIsImported}
                onChange={(e) => handleFormChange(e, "embryoIsImported")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>

            {/* embryo is imported */}
            <div className="col-md-10 col-sm-6 row rtl mt-1  me-auto">
              <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                {/* document file button embryo is imported */}
                <SimpleFileUpload
                  contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathEmbryoIsImported &&
                      !!formModel.documentBase64EmbryoIsImported &&
                      embryoIsImportedAttachmentFile.length == 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64EmbryoIsImported,
                            formModel.fileTypeEmbryoIsImported === ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameEmbryoIsImported,
                        },
                      ]
                      : embryoIsImportedAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathEmbryoIsImported &&
                      !!formModel.documentBase64EmbryoIsImported &&
                      embryoIsImportedAttachmentFile.length == 0
                      ? true
                      : false
                  }
                  onChange={handleUploadEmbryoIsImportedChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : (formModel.embryoIsImported ? false : true)
                  }
                />
                <span style={{ color: "red", display: errorEmbryoIsImported }} className="errorEmbryoIsImported">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>
            </div>

            {/* stallion owner signed permit checkbox */}
            <div className="col-md-2 col-sm-6  col-12  rtl p0 mt-1">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.stallionOwnerSignedPermit",
                  "I Have Stallion Owner Signed Permit"
                )}
                name="stallionOwnerSignedPermit"
                checked={formModel.stallionOwnerSignedPermit}
                onChange={(e) => handleFormChange(e, "stallionOwnerSignedPermit")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorStallionOwnerSignedPermitCheck }} className="errorStallionOwnerSignedPermitCheck">
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            {/* stallion owner signed permit */}
            {
              //formModel.stallionOwnerSignedPermitPart && (
              <div className="col-md-10 col-sm-6 row rtl mt-1  me-auto">
                <div style={{ maxWidth: 500 }} className="col-md-12 col-sm-12">
                  {
                    //document file button stallion owner signed permit
                  }
                  <div className="col-md-12 col-sm-12">
                    <SimpleFileUpload
                      contentInputUpload={translate("web.uploadCertificatePdfPng", "Upload Certificate (pdf,png)")}
                      isMulti={false}
                      initialFiles={
                        !!formModel.filePathStallionOwnerSignedPermit &&
                          !!formModel.documentBase64StallionOwnerSignedPermit &&
                          stallionOwnerSignedPermitAttachmentFile.length == 0
                          ? [
                            {
                              file: base64ToBlob(
                                formModel.documentBase64StallionOwnerSignedPermit,
                                formModel.fileTypeStallionOwnerSignedPermit ===
                                  ".pdf"
                                  ? "application/pdf"
                                  : "image/png"
                              ),
                              name: formModel.fileNameStallionOwnerSignedPermit,
                            },
                          ]
                          : stallionOwnerSignedPermitAttachmentFile
                      }
                      isServer={
                        !!formModel.filePathStallionOwnerSignedPermit &&
                          !!formModel.documentBase64StallionOwnerSignedPermit &&
                          stallionOwnerSignedPermitAttachmentFile.length == 0
                          ? true
                          : false
                      }
                      onChange={handleUploadStallionOwnerSignedPermitChange}
                      accept={"image/*,application/pdf"}
                      disabled={
                        ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                          true
                          : (formModel.stallionOwnerSignedPermit ? false : true)
                      }
                    />
                    <span style={{ color: "red", display: errorStallionOwnerSignedPermit }} className="errorStallionOwnerSignedPermit">
                      {translate("web.requiredField", "Required Field")}
                    </span>
                  </div>
                </div>
              </div>
              //)
            }
          </EahsForm>

          {/* Comments */}
          {(!!formModel.comment ||
            !!formModel.stallionComment ||
            action === "approve") && (
              <EahsForm title={translate("web.comments", "Comments")}>
                {/* eahs Comment */}
                {!!formModel.comment && (
                  <div className="col-md-6 col-sm-12">
                    <NetaqInput
                      label={translate("web.eahsComments", "EAHS Comments")}
                      name={"adminComment"}
                      type={"textarea"}
                      maxchars={1150}
                      value={formModel.comment}
                      disabled={true}
                    />
                  </div>
                )}

                {/* Stallion Comment */}
                {((action === "approve" && ((formModel.eventName === "SentBack" && !!formModel.stallionComment) || (formModel.eventName !== "SentBack"))) ||
                  (action === "edit" &&
                    formModel.eventName === "StallionRejected") ||
                  (action === "view" &&
                    !!formModel.stallionComment)) && (
                    <div className="col-md-6 col-sm-12 ">
                      <NetaqInput
                        label={translate("web.stallionComment", "Stallion Owner Comment")}
                        name={"stallionComment"}
                        type={"textarea"}
                        placeholder={translate(
                          "web.stallionComment",
                          "Stallion Owner Comment"
                        )}
                        maxchars={1150}
                        onChange={handleFormChange}
                        value={formModel.stallionComment}
                        disabled={
                          action === "approve" && formModel.eventName !== "SentBack" &&
                            formModel.requestsType === "Portal"
                            ?
                            false
                            : true
                        }
                      />
                    </div>
                  )}
              </EahsForm>
            )}

          {/* terms */}
          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0" style={{
              display: (formModel.isStallionRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionReg), 2))
                || (formModel.isStallionRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateStallionUnReg), 2))
                || (formModel.isMareRegistered === "Registered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareReg), 3))
                || (formModel.isMareRegistered === "Unregistered" && isLessThanUndefinedYearOld(new Date(formModel.birthDateMareUnReg), 3)) ? "" : "none",
            }}>
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.coveringCertificateNote1",
                  "Covering Certificate Note 1"
                )}
                name="option2"
                checked={checkedItems.option2}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            {/* <div className="col-md-12 col-sm-12 rtl p0" style={{
            display: formModel.horseMareRegLessThanOneWeek == true || checkLastChildrenOfHorseMareRegLessThanOneWeekData == true ? "" : "none",
          }}>
            <NetaqInput
              type={"checkbox"}
              label={translate(
                "web.coveringCertificateNote2",
                "Covering Certificate Note 2"
              )}
              name="option2"
              checked={checkedItems.option3}
              onChange={handleCheckboxChange}
              disabled={formModel.eventName === "" ? false : true}
            />
          </div> */}
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons */}
          <div className="col-md-12 col-sm-12 mt-15 text-end row">
            {
              (
                //formModel.eventName === "SentBack" || 
                (action === "edit" && formModel.eventName !== "Draft")) &&
              //cancel
              <ActionButton
                className={"btn-danger requestBtn"}
                type={"requests"}
                label={translate("web.cancel", "Cancel")}
                text={
                  <>
                    <i className="fa-solid fa-circle-xmark fa-lg marginrightleft-10"></i>
                    {translate("web.cancel", "Cancel")}
                  </>
                }
                margin={"auto 10px"}
                width={"250px"}
                onClick={() => {
                  setOpenPopupCancel((o) => !o);
                }}
              />
            }
            {
              (action == null
                || action === "edit"
                || (action === "approve" && formModel.eventName === "SentBack")) ? (
                //draft/submit
                <>
                  <ActionButton
                    type={"requests"}
                    className="btn buttonformshowentrycard height46 linkbtn col-md-1 ms-auto"
                    label={translate("web.draft", "Draft")}
                    text={
                      <>
                        <i className="fa fa-save fa-lg marginrightleft-10"></i>
                        {translate("web.draft", "Draft")}
                      </>
                    }
                    margin={"auto 10px"}
                    width={"250px"}
                    onClick={() => handelFormSubmit("Draft", "")}
                    disabled={
                      formModel.eventName === "" ||
                        formModel.eventName === "Draft"
                        ? false
                        : true
                    }
                  />
                  {
                    (
                      //((!!formModel.violationPayment && formModel.eventName === "SentBack") || (formModel.violationPayment === "" || formModel.violationPayment == null)) &&
                      (formModel.eventName === "SentBack"
                        &&
                        (
                          (formModel.coveringDate != null && CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration) && !!formModel.violationPayment)
                          ||
                          (formModel.coveringDate != null && !CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration))
                        )
                      ) ||
                      ((formModel.eventName === "" || formModel.eventName === "Draft") &&
                        (
                          (getServiceDefinitionByIdData.serviceFees > 0)
                          ||
                          (formModel.coveringDate == null || (formModel.coveringDate != null && CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration)))
                        ))
                    ) &&
                    <ActionButton
                      type={"requests"}
                      className="btn-success text-white requestBtn col-md-1"
                      label={translate("web.submit", "Submit")}
                      text={
                        <>
                          <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                          {translate("web.submit", "Submit")}
                        </>
                      }
                      margin={"auto 25px"}
                      width={"200"}
                      onClick={() =>
                        handelFormSubmit("Submit", "")
                      }
                    />
                  }
                </>
              ) : action === "approve" && formModel.eventName !== "SentBack" && formModel.eventName !== "PendingPayment" ? (
                //approve/reject btn
                <>
                  <div className="row">
                    <div className="col-md-2 col-sm-12 d-flex flex-column justify-content-center ms-auto">
                      <ActionButton
                        type={"submit"}
                        label={translate("web.reject", "Reject")}
                        text={
                          <>
                            <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                            {translate("web.reject", "Reject")}
                          </>
                        }
                        additionalText={`
                                ${translate(
                          "web.rejectStallionComment",
                          "*To Reject The Request Please Enter The Stallion Owner Comment*"
                        )}
                              `}
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => {
                          setOpenPopup((o) => !o);
                        }}
                        disabled={
                          formModel.requestsType === "Portal" &&
                            !!formModel.stallionComment
                            ? false
                            : true
                        }
                      />
                    </div>
                    <div className="col-md-2 col-sm-12" style={{ minWidth: 246, marginLeft: "-46px" }}>
                      <ActionButton
                        className={"btn-success requestBtn"}
                        type={"requests"}
                        label={translate("web.approve", "Approve")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.approve", "Approve")}
                          </>
                        }
                        margin={"auto 10px"}
                        width={"250px"}
                        onClick={() =>
                          handelApproveRejectRequest("Approve")
                        }
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )
            }

            {
              (action == null
                || action === "edit"
                || (action === "approve" && (formModel.eventName === "SentBack" || formModel.eventName === "PendingPayment")))
              &&
              (
                getServiceDefinitionByIdData.serviceFees > 0
                ||
                (formModel.coveringDate != null && !CheckDateBeforUndefinedDays(formModel.coveringDate, getServiceDefinitionByIdData.penaltyDuration))
              )
              && (
                <>
                  {userProfile?.participateWithoutPayment === true && (((formModel.paymentStatus === "" || formModel.paymentStatus === "Pendding") && getServiceDefinitionByIdData.serviceFees > 0) || formModel.violationPayment === "" || formModel.violationPayment === null) && (
                    //pay later
                    <div className="col-md-3">
                      <ActionButton
                        className="btn-info text-white w-100"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                                       ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                                       `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                      // disabled={
                      //   formModel.eventName === "SentBack"
                      //     ? true
                      //     : false
                      // }
                      />
                    </div>
                  )}
                  {(((formModel.paymentStatus === "" || formModel.paymentStatus === "Pendding") && getServiceDefinitionByIdData.serviceFees > 0) || formModel.violationPayment === "" || formModel.violationPayment === null) &&
                    //pay
                    <div className="col-md-3">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 w-100"}
                        type={"requests"}
                        label={translate("web.submitPay", "Submit And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                      // disabled={
                      //   formModel.eventName === "SentBack"
                      //     ? true
                      //     : false
                      // }
                      />
                    </div>
                  }
                </>
              )
            }
          </div>

          {/* pop up for reject */}
          <Popup
            modal
            lockScroll
            open={openPopup}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <>
                <div className="">
                  {translate(
                    "web.confirmMsgRejectrequest",
                    "Are you sure to reject request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl ">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModal}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      onClick={() => {
                        setOpenPopup((o) => !o);
                        handelApproveRejectRequest("Reject");
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10"></i>
                      {translate("web.reject", "Reject")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Popup>

          {/* pop up for cancel */}
          <Popup
            modal
            lockScroll
            open={openPopupCancel}
            closeOnDocumentClick={false}
            closeOnEscape
            style={{ background: "red" }}
            contentStyle={{ width: "90%", maxWidth: 400 }}
          >
            {(close) => (
              <>
                <div className="">
                  {translate(
                    "web.confirmMsgCancelRequest",
                    "Are you sure to cancel request?"
                  )}
                </div>
                <div className="divHorseSelect row rtl">
                  <div
                    className="bidSubmit buttonformshowentrycard linkbtn col-lg-4 col-sm-6 text-center"
                    onClick={closeModalCancel}
                  >
                    {translate("web.close", "Close")}
                  </div>
                  <div className=" col-lg-6 col-sm-6">
                    <button
                      type="button"
                      className="btn btn-danger btn-lg"
                      style={{ height: 53 }}
                      onClick={() => {
                        setOpenPopupCancel((o) => !o);
                        handelCancelRequest();
                      }}
                    >
                      <i className="fa-solid fa-ban fa-lg marginrightleft-10" style={{ marginLeft: 0 }}></i>
                      {translate("web.cancel", "Cancel")}
                    </button>
                  </div>
                </div>
              </>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
}
