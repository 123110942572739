import React from 'react'
import ArenaTable from './ArenaTable';
import { TableObject } from './TableObject';

export default function Arena22Table({ totalTables, toggleTableSelection, BaseURL, translate }) {
    return <div className="bookingTableContainer" style={{ width: 1350 }}>
        <div className="bookingTableCenter">
            <div className="BookingTableArena">
                <img
                    style={{ width: '100%', height: 'unset', aspectRatio: 'unset', background: '#A67B52' }}
                    src={`${BaseURL}/assets/img/Arena22Table.png`}
                    alt="Horse Arena" />
            </div>
            <span className='text-danger text-center bold' style={{ width: 110, display: 'block' }}>{translate('web.displayScreen', 'Display Screen')}<br /> 5 × 9 m </span>
        </div>
        <div className="bookingTablesRow withVIPSection" style={{ paddingLeft: 0 }}>
            {totalTables
                .filter((item, index) => item.tableNo > 0 && item.tableNo < 7).reverse()
                .map((item, index) => (
                    index < 7 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        style={{ marginRight: index == 2 || index == 5 ? '40px' : '' }}
                    />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo > 6 && item.tableNo < 12).reverse()
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        style={{ marginLeft: index == 0 ? '25px' : '' }}

                    />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow" style={{ paddingLeft: 0 }}>
            {totalTables
                .filter((item, index) => item.tableNo > 11 && item.tableNo < 23).reverse()
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        style={{ marginRight: index == 3 && '25px' || index == 5 && '55px' }}
                    />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables
                .filter((item, index) => item.tableNo > 17 && item.tableNo < 23).reverse()
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        style={{ marginLeft: index == 0 && '5px' || index == 4 && '25px' }}
                    />
                ))}
        </div>
        <div className="bookingTablesRow" style={{ paddingLeft: 0 }}>
            <div style={{ marginLeft: 200, textAlign: 'center', maxWidth: 25, marginTop: -40 }}>
                <div style={{ width: 25, height: 10, background: '#409A97' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#409A97' }}></i>
                <div style={{ fontSize: 13, marginLeft: '-13px' }}>{translate('web.audienceEntrance', 'Audience Entrance')}</div>
            </div>
            <div style={{ marginLeft: 330, textAlign: 'center', maxWidth: 25 }}>
                <div style={{ width: 25, height: 10, background: '#dc3545' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#dc3545' }}></i>
                <div style={{ fontSize: 13, marginLeft: '-13px', width: 50 }}>{translate('web.VIPEntrance', 'VIP Entrance')}</div>
            </div>
            <div style={{ marginLeft: 205, textAlign: 'center', maxWidth: 25, marginTop: -40 }}>
                <div style={{ width: 25, height: 10, background: '#409A97' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#409A97' }}></i>
                <div style={{ fontSize: 13, marginLeft: '-13px' }}>{translate('web.audienceEntrance', 'Audience Entrance')}</div>
            </div>
        </div>
    </div>;
}
