import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchOwnerHorsesDropDownthunk,
} from "../Redux/OwnerProfile/OwnerProfileAction";
import {
  FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
  FetchIsHaseRequestEmbryoOocyteTransferPermitthunk,
  FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { formatDate } from "../helper/formatDate";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import { isLessThanUndefinedYearOld } from "../helper/isLessThanUndefinedYearOld";
import EahsForm from "../components/EahsForm";

export default function RequestEmbryoOocyteTransferPermit() {
  //env
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const { ownerHorsesDropDownData } = useSelector(
    (state) => state.ownerProfileSlice
  );

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    isHaseRequestEmbryoOocyteTransferPermitData,
    createEmbryoOocyteTransferPermitServiceRequestData
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    horse: "",
    horseDrop: {
      label: translate("web.horse", "Horse"),
      value: null,
    },

    origin: "",
    gender: "",
    color: "",
    birthDate: "",
    sire: "",
    dam: "",
    breeder: "",

    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",

    isHaseRequestEmbryoOocyteTransferPermit: false,
    embryoOocyteTransferPermitId: null,
  });

  const [errorHorse, setErrorHorse] = useState("none");

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
    option2: false,
    option3: false,
    option4: false,
    //option5: false,
    option6: false,
    option7: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  //function
  const handleFormChange = (e, input) => {
    if (input === "horse") {
      let horse = ownerHorsesDropDownData.filter((item) => item.id == e.value);

      if (e.value !== "") {
        let params = {
          horseId: e.value,
          serviceDefinitionId: serviceId
        }
        dispatch(FetchIsHaseRequestEmbryoOocyteTransferPermitthunk(params));
      }

      setFormModel({
        ...formModel,
        horseDrop: e,
        horse: e.value,
        gender: horse[0]?.gender ?? "",
        color: horse[0]?.color ?? "",
        birthDate: horse[0]?.birthDate ?? "",
        sire: horse[0]?.sire ?? "",
        dam: horse[0]?.dam ?? "",
        origin: horse[0]?.origin ?? "",
        breeder: horse[0]?.breeder ?? "",
      });
    } else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.horse === ""
    ) {
      setErrorHorse("");
      valid = false;
    }
    else {
      setErrorHorse("none");
    }

    //terms
    if (
      !checkedItems.option1 ||
      !checkedItems.option2 ||
      !checkedItems.option3 ||
      !checkedItems.option6 ||
      !checkedItems.option7

      //||(!checkedItems.option4 && isLessThanUndefinedYearOld(new Date(formModel.birthDate), 3))
      //||(!checkedItems.option5 && formModel.isHaseRequestEmbryoOocyteTransferPermit)
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    if (isLessThanUndefinedYearOld(new Date(formModel.birthDate), 3)) {
      toast.error(
        translate("web.embryoOocyteTransferPermitNote3", "embryo Oocyte Transfer Permit Note3")
      );
      valid = false;
    }

    if (Object.keys(isHaseRequestEmbryoOocyteTransferPermitData).length > 0) {
      toast.error(
        currentLanguage === "en" ? `You cannot submit this request for this mare because there is a valid embryo/oocyte transfer permit Ref.No: ${isHaseRequestEmbryoOocyteTransferPermitData.referenceNumber} from date: ${isHaseRequestEmbryoOocyteTransferPermitData.fromDate} to date: ${isHaseRequestEmbryoOocyteTransferPermitData.toDate}.` :
          `لايمكنك تقديم طلب لهذا الفرس بسبب وجود تصريح نقل أجنة/بويضات ساري المفعول برقم مرجعي: ${isHaseRequestEmbryoOocyteTransferPermitData.referenceNumber} من تاريخ: ${isHaseRequestEmbryoOocyteTransferPermitData.fromDate} الى تاريخ: ${isHaseRequestEmbryoOocyteTransferPermitData.toDate}`
        //translate("web.embryoOocyteTransferPermitNote4", "Embryo Oocyte Transfer Permit Note 4")
      );

      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=8&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;

        let params = {
          id: id,
          serviceDefinitionId: serviceId,
          //date: new Date().toLocaleDateString(),
          status: status,
          eventName: formModel.eventName,
          horse: formModel.horse,
          ownerId: getUserID(),
          createdBy: localStorage.getItem("EAHSUserID"),
        };

        if (action == null) {
          dispatch(FetchCreateEmbryoOocyteTransferPermitServiceRequestthunk(params));
        } else if (action === "edit" || formModel.eventName === "SentBack") {
          dispatch(FetchUpdateEmbryoOocyteTransferPermitServiceRequestthunk(params));
        }
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    let params = {
      ownerId: element.value,
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    setProfile(element);

    setFormModel({
      ...formModel,
      horse: "",
      horseDrop: {
        label: translate("web.horse", "Horse"),
        value: null,
      },
      gender: "",
      color: "",
      birthDate: "",
      sire: "",
      dam: "",
      origin: "",
      breeder: "",
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "EmbryoTransfer" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && userProfile?.haveProfileSignture != true) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (!!userProfile && userProfile?.accountType == "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    let params = {
      ownerId: getUserID(),
      skip: 0,
      take: 1000,
      langauge: currentLanguage,
      horseTypeFilter: "1",
      searchValue: "",
      sortBy: "-1",
    };
    dispatch(FetchOwnerHorsesDropDownthunk(params));

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        embryoOocyteTransferPermitId: getServicesRequestByIdData.embryoOocyteTransferPermit.embryoOocyteTransferPermitId,

        horse: getServicesRequestByIdData.embryoOocyteTransferPermit.horse,
        horseDrop: {
          label:
            getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.registrationNumber +
            " - " +
            getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.nameEn,
          value: getServicesRequestByIdData.embryoOocyteTransferPermit.horse,
        },
        origin: getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.exportCountry,
        gender: getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.gender,
        color:
          currentLanguage == "en"
            ? getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.horseColor
              .nameEn
            : getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.horseColor
              .nameAr,
        birthDate:
          getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.birthDate,
        sire: getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.sireNameEn,
        dam: getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.damNameEn,
        breeder: getServicesRequestByIdData.embryoOocyteTransferPermit.horseVM.breeder,
      });

      //another owner
      if (
        getServicesRequestByIdData.embryoOocyteTransferPermit.ownerId !==
        getServicesRequestByIdData.embryoOocyteTransferPermit.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        let params = {
          ownerId: getServicesRequestByIdData.embryoOocyteTransferPermit.ownerId,
          skip: 0,
          take: 1000,
          langauge: currentLanguage,
          horseTypeFilter: "1",
          searchValue: "",
          sortBy: "-1",
        };
        dispatch(FetchOwnerHorsesDropDownthunk(params));

        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.embryoOocyteTransferPermit.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.embryoOocyteTransferPermit.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.embryoOocyteTransferPermit.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
        option2: true,
        option3: true,
        option4: true,
        //option5: true,
        option6: true,
        option7: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    debugger
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${createEmbryoOocyteTransferPermitServiceRequestData.id}&paymentType=8&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">
      {/* loading div */}
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {/* toast */}
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {/* content page */}
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">
          {/* Embryo Oocyte Transfer Permit title */}
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {/* Selected Account */}
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          {/* mare details */}
          <EahsForm title={translate("web.mareDetails", "Mare Details")}>
            <div className="row mx-0 col-md-12 col-sm-12 mt-5 rtl">
              <div className="col-md-4 col-sm-12">
                <label>
                  {translate("web.horse", "Horse")}
                  <i className="colorred"> *</i>
                </label>
                <Select
                  options={[
                    {
                      label: translate("web.horse", "Horse"),
                      value: "",
                    },
                    ...(!!ownerHorsesDropDownData &&
                      ownerHorsesDropDownData.filter(q => q.gender === "Mare").map((a) => ({
                        label: a.registerNumber + " - " + a.name,
                        value: a.id,
                      }))),
                  ]}
                  value={formModel.horseDrop}
                  onChange={(e) => handleFormChange(e, "horse")}
                  placeholder={translate("web.horse", "Horse")}
                  name={"horse"}
                  isSearchable
                  noOptionsMessage={() =>
                    translate("web.noOptions", "No Options")
                  }
                  autoFocus={true}
                  key={"horse"}
                  isDisabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
                <span style={{ color: "red", display: errorHorse }} className="errorHorseMareReg">
                  {translate("web.requiredField", "Required Field")}
                </span>
              </div>

              <div className="col-md-4 col-sm-12 ">
                <NetaqInput
                  label={translate("web.origin", "Origin")}
                  name={"origin"}
                  type={"text"}
                  disabled={true}
                  value={formModel.origin}
                />
              </div>

              <div className="col-md-4 col-sm-12 ">
                <NetaqInput
                  label={translate("web.breeder", "Breeder")}
                  name={"breeder"}
                  type={"text"}
                  disabled={true}
                  value={formModel.breeder}
                />
              </div>

              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.gender", "Gender")}
                  name={"gender"}
                  type={"text"}
                  disabled={true}
                  value={translate(
                    `web.${formModel.gender?.toLowerCase()}`,
                    formModel.gender
                  )}
                />
              </div>

              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.color", "Color")}
                  name={"color"}
                  type={"text"}
                  disabled={true}
                  value={formModel.color}
                />
              </div>

              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.birthDate", "Birth Date")}
                  name={"birthDate"}
                  type={"text"}
                  disabled={true}
                  value={
                    !!formModel.birthDate
                      ? formModel.birthDate &&
                      formatDate(formModel.birthDate)
                      : ""
                  }
                />
              </div>

              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.sire", "Sire")}
                  name={"sire"}
                  type={"text"}
                  disabled={true}
                  value={formModel.sire}
                />
              </div>

              <div className="col-md-4 col-sm-12 mt-3">
                <NetaqInput
                  label={translate("web.dam", "Dam")}
                  name={"dam"}
                  type={"text"}
                  disabled={true}
                  value={formModel.dam}
                />
              </div>
            </div>
          </EahsForm>
          {/* Comments */}
          {(!!formModel.comment ||
            action === "approve") && (
              <EahsForm title={translate("web.comments", "Comments")}>
                {/* eahs Comment */}
                {!!formModel.comment && (
                  <div className="col-md-6 col-sm-12">
                    <NetaqInput
                      label={translate("web.eahsComments", "EAHS Comments")}
                      name={"adminComment"}
                      type={"textarea"}
                      maxchars={1150}
                      value={formModel.comment}
                      disabled={true}
                    />
                  </div>
                )}
              </EahsForm>
            )}

          {/* terms */}
          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoOocyteTransferPermitNote1",
                  "Embryo Oocyte Transfer Permit Note 1"
                )}
                name="option2"
                checked={checkedItems.option2}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoOocyteTransferPermitNote2",
                  "Embryo Oocyte Transfer Permit Note 2"
                )}
                name="option3"
                checked={checkedItems.option3}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            {
              // isLessThanUndefinedYearOld(new Date(formModel.birthDate), 3) &&

              // <div className="col-md-12 col-sm-12 rtl p0">
              //   <NetaqInput
              //     type={"checkbox"}
              //     label={translate(
              //       "web.embryoOocyteTransferPermitNote3",
              //       "Embryo Oocyte Transfer Permit Note 3"
              //     )}
              //     name="option4"
              //     checked={checkedItems.option4}
              //     onChange={handleCheckboxChange}
              //     disabled={formModel.eventName === "" ? false : true}
              //   />
              // </div>
            }

            {
              // formModel.isHaseRequestEmbryoOocyteTransferPermit &&
              // <div className="col-md-12 col-sm-12 rtl p0">
              //   <NetaqInput
              //     type={"checkbox"}
              //     label={translate(
              //       "web.embryoOocyteTransferPermitNote4",
              //       "Embryo Oocyte Transfer Permit Note 4"
              //     )}
              //     name="option5"
              //     checked={checkedItems.option5}
              //     onChange={handleCheckboxChange}
              //     disabled={formModel.eventName === "" ? false : true}
              //   />
              // </div>
            }
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoOocyteTransferPermitNote5",
                  "Embryo Oocyte Transfer Permit Note 5"
                )}
                name="option6"
                checked={checkedItems.option6}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.embryoOocyteTransferPermitNote6",
                  "Embryo Oocyte Transfer Permit Note 6"
                )}
                name="option7"
                checked={checkedItems.option7}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons */}
          <div className="col-md-12 col-sm-12 mt-15 text-end">
            {/* draft/pay/submit btn */}
            {
              ((action == null || action === "edit") ||
                (action === "approve" && (formModel.eventName === "SentBack" || (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")))) && (
                <div className="row">

                  {/* draft */}
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-md-1 ms-auto">
                      <ActionButton
                        type={"requests"}
                        className="btn buttonformshowentrycard height46 linkbtn w-100"
                        label={translate("web.draft", "Draft")}
                        text={
                          <>
                            <i className="fa fa-save fa-lg marginrightleft-10"></i>
                            {translate("web.draft", "Draft")}
                          </>
                        }
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }

                  {/* pay later */}
                  {userProfile?.participateWithoutPayment == true && formModel.eventName !== "SentBack" && (
                    <div className="col-md-3 ms-auto">
                      <ActionButton
                        className="btn-info text-white w-100"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {/* pay */}
                  {formModel.eventName !== "SentBack" &&
                    <div className="col-md-2">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  }

                  {/* submit */}
                  {action === "approve" && formModel.eventName === "SentBack" &&
                    <div className="col-md-1">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                          //handelFormSubmit("PendingReview", "")
                        }
                        disabled={
                          action === "approve" && formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
