import React from 'react'
import ArenaTable from './ArenaTable';
import { TableObject } from './TableObject';

export default function Arena64Table({ totalTables, toggleTableSelection, BaseURL, translate }) {
    return <div className="bookingTableContainer">
        <div className="bookingTablesRow">
            {totalTables
                .filter((item, index) => item.tableNo > 53 && item.tableNo < 65)
                .map((item, index) => (
                    index < 11 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            {/* Refactor to be part of map */}
            <ArenaTable
                tableNumber={'system'}
                tablePrice={'0'}
                state={"system"} />
            <ArenaTable
                tableNumber={'system'}
                tablePrice={'0'}
                state={"system"} />
            <ArenaTable
                tableNumber={'system'}
                tablePrice={'0'}
                state={"system"} />
            <ArenaTable
                tableNumber={'system'}
                tablePrice={'0'}
                state={"system"} />
            <ArenaTable
                tableNumber={'system'}
                tablePrice={'0'}
                state={"system"} />
        </div>
        <div className="bookingTableCenter">
            <div className="bookingTablesColumn">
                {totalTables
                    .filter((item, index) => item.tableNo > 43 && item.tableNo < 54)
                    .map((item, index) => (
                        index < 10 &&
                        <ArenaTable
                            key={item.tableNo}
                            tableNumber={item.tableNo}
                            tablePrice={item.price}
                            state={TableObject.fromRaw(item).state()}
                            onClick={() => toggleTableSelection(item.id, item.price)} />
                    ))}
            </div>
            <div className="bookingTablesColumn">
                {totalTables

                    .filter((item, index) => item.tableNo > 32 && item.tableNo < 44)
                    .map((item, index) => (
                        index < 11 &&
                        <ArenaTable
                            key={item.tableNo}
                            tableNumber={item.tableNo}
                            tablePrice={item.price}
                            state={TableObject.fromRaw(item).state()}
                            onClick={() => toggleTableSelection(item.id, item.price)} />
                    ))}
            </div>
            <div className="BookingTableArena">
                <img
                    src={`${BaseURL}/assets/img/HorseArena.png`}
                    alt="Horse Arena" />
            </div>
        </div>
        <div className="bookingTablesRow withVIPSection">
            {totalTables

                .filter((item, index) => item.tableNo > 13 && item.tableNo < 21)
                .map((item, index) => (
                    index < 7 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo < 6)
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow">
            {totalTables

                .filter((item, index) => item.tableNo > 20 && item.tableNo < 27)
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo > 5 && item.tableNo < 10)
                .map((item, index) => (
                    index < 4 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow">
            {totalTables

                .filter((item, index) => item.tableNo > 26 && item.tableNo < 33)
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables
                .filter((item, index) => item.tableNo > 9 && item.tableNo < 14)
                .map((item, index) => (
                    index < 4 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
    </div>;
}
