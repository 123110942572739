import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axiosUmbraco";
import Loader from "../components/Loader";
import OwlCarousel from "react-owl-carousel";
import { Link, useLocation } from "react-router-dom";
import { RemovePlusFromString } from "../helper/RemovePlusFromString";
import ComingSoon from "../components/ComingSoon";
import { useTranslate } from "../components/TranslationText";
import { showWordsByNumber } from "../helper/showFirstSixWords";
import Popup from "reactjs-popup";
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { ProgressBar } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

const BaseBACKENDURL = process.env.REACT_APP_BACKEND_API;
const BaseURL = process.env.REACT_APP_BASE_URL;

export default function WahoConference() {
  const { translate } = useTranslate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const MediaBaseURL = process.env.REACT_APP_UMBRACO_MEDIA_BASE_URL;

  const WahoConferenceID = process.env.REACT_APP_WahoConference_ID;

  const id = WahoConferenceID;

  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const [wahoConference, setWahoConference] = useState([]);
  const [loaded, setloaded] = useState(false);
  const [programDivActive, setProgramDivActive] = useState(0);
  const [programCardList, setProgramCardList] = useState([]);
  const [tourList, setTourList] = useState([]);
  const [postTourList, setPostTourList] = useState([]);


  function getCurrentLanguageUmbraco() {
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang === "en") return "en-US";
    else return "ar-AE";
  }

  useEffect(() => {
    const lang = searchParams.has("lang") ? searchParams.get("lang") : false;
    if (!!lang) {
      const previousLang = localStorage.getItem("eahsLang") ?? 'en';
      if (previousLang != lang) {
        localStorage.setItem("eahsLang", lang);
        window.location.reload();
      }
    }
    async function getdata() {
      const umbraco = await axios.get(
        `/umbraco/delivery/api/v1/content/item?id=${id}`,
        {
          headers: {
            // "accept-language": getCurrentLanguageUmbraco(),
            "accept-language": 'en-US',
          },
        }
      );
      const formattedData = umbraco.data?.map((item) => ({
        headerImage: item?.properties?.headerImage[0]?.url ?? "",
        headerText: item?.properties?.headerText ?? "",
        estmeemedGustsTitle: item?.properties?.estmeemedGustsTitle ?? "",
        estmeemedGustsDescription:
          item?.properties?.estmeemedGustsDescription?.markup ?? "",
        aboutTitle: item?.properties?.aboutTitle ?? "",
        aboutDescription: item?.properties?.aboutDescription?.markup ?? "",
        visaDescription: item?.properties?.visaDescription?.markup ?? "",
        visaPdf: item?.properties?.visaPdf ?? "",
        estmeemedFile: item?.properties?.estmeemedFile ?? "",
        postToursFile: item?.properties?.postToursFile ?? "",
        programFile: item?.properties?.programFile ?? "",
        addressTitle: item?.properties?.addressTitle ?? "",
        address: item?.properties?.address ?? "",
        startDateLable: item?.properties?.startDateLable ?? "",
        startDateDate: item?.properties?.startDateDate ?? "",
        endDateLabel: item?.properties?.endDateLabel ?? "",
        endDateDate: item?.properties?.endDateDate ?? "",
        footerTitle: item?.properties?.footerTitle ?? "",
        footerEmailTitle: item?.properties?.footerEmailTitle ?? "",
        footerEmailDescription: item?.properties?.footerEmailDescription ?? "",
        footerPhoneTitle: item?.properties?.footerPhoneTitle ?? "",
        footerPhoneDescription: item?.properties?.footerPhoneDescription ?? "",
        footerContactName: item?.properties?.footerContactName ?? "",
        footerRegisterEmail: item?.properties?.footerRegisterEmail ?? "",
        footerMessage: item?.properties?.footerMessage ?? "",
        sponsorLable: item?.properties?.sponsorLable ?? "",
        sponsorList: item?.properties?.sponsorList ?? [],
        programLable: item?.properties?.programLable ?? "",
        programList: item?.properties?.programList ?? [],

        programCardList: item?.properties?.programCardList ?? [],
        tourLable: item?.properties?.tourLable ?? "",
        tourList: item?.properties?.tourList ?? [],
        postTourLable: item?.properties?.postTourLable ?? "",
        postTourList: item?.properties?.postTourList ?? [],
        registrationLable: item?.properties?.registrationLable ?? "",
        registrationList: item?.properties?.registrationList ?? [],
        galleryLabel: item?.properties?.galleryLabel ?? "",
        galleryList: item?.properties?.galleryList ?? [],
        footerImage: item?.properties?.footerImage[0]?.url ?? "",
      }));

      setWahoConference(formattedData);

      let program_Card_List = formattedData[0].programCardList.filter(
        (item) =>
          item.properties.programCardDay ===
          formattedData[0].programList[0].properties.dayKey
      );
      setProgramCardList(program_Card_List);


      setTourList(formattedData[0].tourList);
      setPostTourList(formattedData[0].postTourList);

      // console.log(umbraco.data);
      // console.log(formattedData);

      if (umbraco.data.length > 0) {
        setloaded(true);
      }
    }
    getdata();
  }, [currentLanguage]);

  function getcarouselOptions(responsive1000, totalcards, isDays = false) {
    let iscenter = false;
    if (totalcards < responsive1000) {
      iscenter = true;
      if (totalcards > 1) {
        iscenter = false;
        responsive1000 = totalcards;
      }
    }
    return {
      loop: false,
      margin: 5,
      nav: true,
      center: iscenter,
      autoplay: false,
      // autoplayTimeout: 3000,
      // autoplayHoverPause: true,
      // startPosition:
      //   getCurrentLanguageUmbraco() == "ar-AE" ? totalcards - 1 : 0,
      // center: true,
      dots: true,
      dotsEach: true,
      responsive: {
        0: {
          items: isDays ? 2 : 1,
        },
        600: {
          items: isDays ? 5 : 2,
        },
        700: {
          items: isDays ? 5 : responsive1000,
        },
        900: {
          items: isDays ? responsive1000 - 3 : responsive1000,
        },
        1000: {
          items: isDays ? responsive1000 - 2 : responsive1000,
        },
        1068: {
          items: isDays ? responsive1000 - 1 : responsive1000,
        },
        1378: {
          items: responsive1000,
        }
      },
    };
  }

  const carouselRef = useRef(null);

  const handleInitialized = () => {
    // Seek to the initial tab only after initialization
    if (carouselRef.current) {
      carouselRef.current.to(programDivActive);
    }
  };


  function formatDay(_date) {
    let day = new Date(_date).getDate();

    return day;
  }

  function formatMonth(_date) {
    let month = new Date(_date).toLocaleString(
      currentLanguage === "en" ? "en-us" : "ar-ae",
      { month: "short" }
    );

    return month;
  }

  function formatYear(_date) {
    let year = new Date(_date).getFullYear();

    return year;
  }

  const handleProgramCardList = async (day, index) => {
    await setProgramDivActive(index);
    await setProgramCardList(
      wahoConference[0].programCardList.filter(
        (item) => item.properties.programCardDay === day
      )
    );
  };

  const [startPositionForOwl, SetStartPosition] = useState(0)


  return (
    <>
      {loaded ? (
        // <React.Fragment>
        //   {wahoConference.map((item, itemIndex) => (
        //     <div className="container-fluid px-5" key={itemIndex}>
        //       <div className="row">
        //         {
        //           //image
        //         }
        //         <div className="col-sm-12" style={{ position: 'relative' }}>
        //           <img
        //             alt={"waho conference img"}
        //             className="w-100"
        //             //src={`${MediaBaseURL}/assets/img/horsemarket.jpg`}
        //             src={`${MediaBaseURL}${item.headerImage}`}
        //           />
        //           {
        //             //text
        //           }
        //           <div className="headerimagetextwahoconference">
        //             {/* <p>{item.headerText}</p> */}
        //           </div>
        //         </div>

        //         {
        //           //Dear Esteemed Guests
        //         }
        //         <div className="col-md-12 col-sm-12 text-center mt-3">
        //           <h4 className="bold">{item.estmeemedGustsTitle}</h4>
        //           <p className="text-start" >{showWordsByNumber(item.estmeemedGustsDescription, 67)}
        //           </p>
        //           <Link className="btn btn-outline-danger my-3 fw-bold px-4 py-2" to={`${MediaBaseURL}${wahoConference[0]?.estmeemedFile}`} target="_blank">
        //             {translate('web.viewInvitationLetter', 'View Invitation Letter')}
        //           </Link>
        //         </div>

        //         {
        //           //About
        //         }
        //         {/* <div className="col-md-12 col-sm-12 text-center ">
        //           <h4 className="bold">{item.aboutTitle}</h4>
        //           <p className="text-start" >{showWordsByNumber(item.aboutDescription, 67)} {showWordsByNumber(item.aboutDescription, 67)?.endsWith('...') ? <Popup
        //             modal
        //             trigger={<span className="text-decoration-underline text-info" style={{ cursor: 'pointer' }}>{translate('web.seeMore', 'See More')}</span>}
        //             overlayStyle={{ zIndex: 5000 }}
        //             contentStyle={{ maxWidth: 1500, padding: '15px 15px' }}
        //           >
        //             {(close) => <>
        //               <div className="position-absolute closeDivAboluste" onClick={close} > <i className="fa fa-times text-secondary" ></i> </div>
        //               <h4 className="bold mb-3 text-center">{item.aboutTitle}</h4>
        //               <div className="popupContentStyle judgesList pe-5">
        //                 <div className="text-start borderGold p-2 " dangerouslySetInnerHTML={{ __html: item?.aboutDescription }} />
        //               </div>
        //             </>}
        //           </Popup> : ''
        //           }
        //           </p>
        //         </div> */}



        //         {
        //           //startdate and enddate
        //         }

        //         {/* <div className="wahoConferanceRow">
        //           <div className="WahoDatesContainer">
        //             <div className="wahoDateCard rtl">
        //               <div className="wahoTitleContainer bold">
        //                 <i className="fa fa-calendar goldenicon-mr-5"></i> {item.startDateLable}
        //               </div>
        //               <div className="startDateEndDateWahoConferanceContainer">
        //                 <p className="fontsize14">
        //                   {formatDate(item.startDateDate)}
        //                 </p>
        //                 <p className="fontsize14">
        //                   {formatTime(item.startDateDate)}
        //                 </p>
        //               </div>
        //             </div>
        //             <div className="wahoDateSpacer" />
        //             <div className="wahoDateCard rtl">
        //               <div className="wahoTitleContainer bold">
        //                 <i className="fa fa-calendar goldenicon-mr-5"></i> {item.endDateLabel}
        //               </div>
        //               <div className="startDateEndDateWahoConferanceContainer">
        //                 <p className="fontsize14">
        //                   {formatDate(item.endDateDate)}
        //                 </p>
        //                 <p className="fontsize14">
        //                   {formatTime(item.endDateDate)}
        //                 </p>
        //               </div>
        //             </div>

        //           </div>
        //         </div> */}
        //         {
        //           //Sposnors
        //         }
        //         {/* <div className="col-md-12 col-sm-12 text-center ">
        //           <h4 className="bold mb-3">{item.sponsorLable}</h4>
        //           <div className="col-md-12 showsGallery">
        //             {!!item.sponsorList && item.sponsorList.length > 0 && (
        //               <OwlCarousel
        //                 className="owl-theme"
        //                 {...getcarouselOptions(5, item.sponsorList.length)}

        //                 marginWidth={1}
        //               >
        //                 {currentLanguage == 'en' ?
        //                   item.sponsorList.map(
        //                     (itemSponsor, itemSponsorIndex) => (
        //                       <div
        //                         className="cardsponsorwahoconference"
        //                         key={itemSponsorIndex}
        //                       >
        //                         <img
        //                           className="historyImage"
        //                           alt="asd"
        //                           src={`${MediaBaseURL}${itemSponsor.properties.sponsorImage[0].url}`}
        //                           style={{
        //                             width: "100%",
        //                             height: "240px",
        //                           }}
        //                         />
        //                         <h5 className="bold mt-2">
        //                           {itemSponsor.properties.sponsorName}
        //                         </h5>
        //                       </div>
        //                     )
        //                   )
        //                   :
        //                   item.sponsorList.slice().reverse().map(
        //                     (itemSponsor, itemSponsorIndex) => (
        //                       <div
        //                         className="cardsponsorwahoconference"
        //                         key={itemSponsorIndex}
        //                       >
        //                         <img
        //                           className="historyImage"
        //                           alt="asd"
        //                           src={`${MediaBaseURL}${itemSponsor.properties.sponsorImage[0].url}`}
        //                           style={{
        //                             width: "100%",
        //                             height: "240px",
        //                           }}
        //                         />
        //                         <h4 className="bold">
        //                           {itemSponsor.properties.sponsorName}
        //                         </h4>
        //                       </div>
        //                     )
        //                   )
        //                 }
        //               </OwlCarousel>
        //             )}
        //           </div>
        //         </div> */}

        //         {
        //           //Program
        //         }
        //         {item.programList.length > 0 &&
        //           <>
        //             <h4 className="bold col-sm-12 text-center mt-4">
        //               {item.programLable}
        //             </h4>
        //             <div className="wahoProgramesContainerRow">
        //               {/* <div className="wahoProgramesContainer"> */}
        //               <OwlCarousel
        //                 ref={carouselRef}
        //                 className="owl-theme customowlcontainer"
        //                 {...getcarouselOptions(8, item.programList.length, true)}
        //                 draggable={false}
        //                 controls={true}
        //                 marginWidth={1}
        //                 autoFocus={true}
        //                 onInitialized={handleInitialized}
        //                 key={'mykeyConstant'}
        //                 startPosition={programDivActive}

        //               >
        //                 {!!item.programList &&
        //                   item.programList.length > 0 &&
        //                   item.programList.map((itemProgram, index) => (
        //                     <div
        //                       key={'mykey' + index}
        //                       className="flexwidthwahoconferenceprogram backgroundcolorprogramwahoconference "
        //                       style={{
        //                         background:
        //                           programDivActive === index
        //                             ? "#E44D52"
        //                             : "#c89c66",
        //                       }}

        //                       onClick={() => {
        //                         handleProgramCardList(
        //                           itemProgram.properties.dayKey, index
        //                         );

        //                         // handleTourList(itemProgram.properties.dayKey);
        //                       }}
        //                     >
        //                       <div className="programCardContainer">
        //                         <div className="programCardTitle" >{itemProgram.properties.programDay} </div>
        //                         <div className="programCardDateContainer" >
        //                           <div>{formatDay(
        //                             itemProgram.properties.programDate
        //                           )}</div>
        //                           <div className="programCardDateSpacer"></div>
        //                           <div>
        //                             <div>{formatMonth(
        //                               itemProgram.properties.programDate
        //                             )}</div>
        //                             <div>{formatYear(
        //                               itemProgram.properties.programDate
        //                             )}</div>
        //                           </div>
        //                         </div>
        //                       </div>
        //                     </div>
        //                   ))}
        //               </OwlCarousel>

        //               {/* </div> */}
        //             </div>
        //           </>}
        //         {
        //           //Program Cards
        //         }
        //         <div className="col-md-12 col-sm-12 mt-4 position-relative">
        //           <OwlCarousel
        //             key={programCardList.length + "proooog" + Math.random()}
        //             className="owl-theme"
        //             {...getcarouselOptions(3, programCardList.length)}
        //             marginWidth={1}
        //           >
        //             {programCardList.map(
        //               (itemProgramCard, itemProgramCardIndex) => (
        //                 <div
        //                   key={`${itemProgramCardIndex}-${itemProgramCard?.properties?.programCardTitle}-${itemProgramCard?.properties?.programCardTime}-${itemProgramCard?.properties?.programCardDescription} ${Math.random()}`}
        //                   className="aboutusItem rtl"
        //                   style={{ minHeight: 185 }}
        //                 >
        //                   <h4 className="bold">
        //                     {itemProgramCard.properties.programCardTitle}
        //                   </h4>
        //                   <p className="bold">
        //                     {itemProgramCard.properties.programCardTime}
        //                   </p>
        //                   <p>
        //                     {itemProgramCard.properties.programCardDescription}
        //                   </p>
        //                 </div>
        //               )
        //             )}
        //           </OwlCarousel>
        //           <style>
        //             {`.owl-theme .owl-nav{
        //             position: absolute;
        //             top: 0;
        //             right: -30px;
        //             left: -30px;
        //             display: flex;
        //             bottom: 40px;
        //             justify-content: space-between;
        //             z-index: -1;
        //                 font-size: 50px;
        //             }
        //                 .owl-next , .owl-prev{
        //                 color:rgb(228, 77, 82) !important;
        //                 }
        //             .owl-next:hover , .owl-prev:hover{
        //              background: transparent !important;
        //             }
        //             `}
        //           </style>
        //         </div>
        //         <div className="col-md-12 col-sm-12 text-center">
        //           <Link className="btn btn-outline-danger my-3 fw-bold px-4 py-2 text-capitalize" to={`${MediaBaseURL}${wahoConference[0]?.programFile}`} target="_blank">
        //             {translate('web.viewFullProgram', 'View Full Program')}
        //           </Link>
        //         </div>

        //         {postTourList.length > 0 &&
        //           <div className="col-md-12 col-sm-12 mt-4">
        //             <h4 className="bold text-center">{item.postTourLable}</h4>
        //             <OwlCarousel
        //               className="owl-theme customowlcontainer"
        //               {...getcarouselOptions(3, postTourList.length)}
        //               marginWidth={1}
        //             >
        //               {postTourList.map((itemTour, itemTourIndex) => (

        //                 <div className="rtl borderGold" style={{ overflow: 'hidden' }} key={itemTourIndex}>
        //                   <img
        //                     alt={itemTourIndex}
        //                     src={`${MediaBaseURL}${itemTour.properties.tourImage[0].url}`}
        //                     style={{
        //                       width: "100%",
        //                       height: "240px",
        //                     }}
        //                   />

        //                   <div className="wahoSliderItem">
        //                     {/* <p className="mt-11wahoconference">
        //                       {" "}
        //                       {formatDay(itemTour.properties.tourDate)}{" "}
        //                       {formatMonth(itemTour.properties.tourDate)}{" "}
        //                       {formatYear(itemTour.properties.tourDate)}
        //                     </p> */}
        //                     <h4 className="bold">
        //                       {itemTour.properties.tourTitle}
        //                     </h4>
        //                     <p className="bold">{itemTour.properties.tourTime}</p>
        //                     {/* <p >
        //                       {showWordsByNumber(itemTour.properties.tourDescription?.markup, 30)}
        //                     </p> */}
        //                     {/* {!!itemTour.properties.tourPdf &&
        //                       <Popup
        //                         modal
        //                         lockScroll={true}
        //                         trigger={
        //                           <p className="colorred text-end" style={{ cursor: 'pointer' }}>
        //                             {translate("web.viewDetails", "View Details")}
        //                             <i className="fa-solid fa-chevron-right .view-details-icon rotateArabic marginleftprogramCardwahoconference"></i>
        //                           </p>
        //                         }
        //                         overlayStyle={{ zIndex: 5000 }}
        //                         contentStyle={{ maxWidth: 1500, padding: '15px 15px' }}
        //                       >
        //                         {(close) => <>
        //                           <div className="position-absolute closeDivAboluste" onClick={close} > <i className="fa fa-times text-secondary" ></i> </div>
        //                           <h4 className="bold mb-3 text-center">{itemTour.properties.tourTitle}</h4>
        //                           <div className="popupContentStyle judgesList pe-5">
        //                             <Worker workerUrl={`${BaseURL}/assets/js/pdf.worker.min.js`}>
        //                               <Viewer
        //                                 renderLoader={(percentages) => (
        //                                   <div style={{ width: '100%' }}>
        //                                     <ProgressBar progress={Math.round(percentages)} />
        //                                   </div>
        //                                 )}
        //                                 fileUrl={`${MediaBaseURL}${itemTour?.properties?.tourPdf[0].url}`} />
        //                             </Worker>
        //                           </div>
        //                         </>}
        //                       </Popup>
        //                     } */}

        //                   </div>
        //                 </div>
        //               ))}
        //             </OwlCarousel>
        //           </div>
        //         }

        //         {
        //           //Tour
        //         }
        //         {tourList.length > 0 &&
        //           <div className="col-md-12 col-sm-12 mt-4">
        //             <h4 className="bold text-center">{item.tourLable}</h4>
        //             <OwlCarousel
        //               className="owl-theme customowlcontainer"
        //               {...getcarouselOptions(3, tourList.length)}
        //               marginWidth={1}
        //             >
        //               {tourList.map((itemTour, itemTourIndex) => (

        //                 <div className="rtl borderGold" style={{ overflow: 'hidden' }} key={itemTourIndex}>
        //                   <img
        //                     alt={itemTourIndex}
        //                     src={`${MediaBaseURL}${itemTour.properties.tourImage[0].url}`}
        //                     style={{
        //                       width: "100%",
        //                       height: "240px",
        //                     }}
        //                   />

        //                   <div className="wahoSliderItem">
        //                     {/* <p className="mt-11wahoconference">
        //                       {" "}
        //                       {formatDay(itemTour.properties.tourDate)}{" "}
        //                       {formatMonth(itemTour.properties.tourDate)}{" "}
        //                       {formatYear(itemTour.properties.tourDate)}
        //                     </p> */}
        //                     <h4 className="bold">
        //                       {itemTour.properties.tourTitle}
        //                     </h4>
        //                     <p className="bold">{itemTour.properties.tourTime}</p>
        //                     {/* <p>
        //                       {showWordsByNumber(itemTour.properties.tourDescription?.markup, 35)}
        //                     </p> */}
        //                     {/* {!!itemTour.properties.tourPdf &&
        //                       <Popup
        //                         modal
        //                         lockScroll={true}
        //                         trigger={
        //                           <p className="colorred text-end" style={{ cursor: 'pointer' }}>
        //                             {translate("web.viewDetails", "View Details")}
        //                             <i className="fa-solid fa-chevron-right .view-details-icon rotateArabic marginleftprogramCardwahoconference"></i>
        //                           </p>
        //                         }
        //                         overlayStyle={{ zIndex: 5000 }}
        //                         contentStyle={{ maxWidth: 1500, padding: '15px 15px' }}
        //                       >
        //                         {(close) => <>
        //                           <div className="position-absolute closeDivAboluste" onClick={close} > <i className="fa fa-times text-secondary" ></i> </div>
        //                           <h4 className="bold mb-3 text-center">{itemTour.properties.tourTitle}</h4>
        //                           <div className="popupContentStyle judgesList pe-5">
        //                             <Worker workerUrl={`${BaseURL}/assets/js/pdf.worker.min.js`}>
        //                               <Viewer
        //                                 renderLoader={(percentages) => (
        //                                   <div style={{ width: '100%' }}>
        //                                     <ProgressBar progress={Math.round(percentages)} />
        //                                   </div>
        //                                 )}
        //                                 fileUrl={`${MediaBaseURL}${itemTour?.properties?.tourPdf[0].url}`} />
        //                             </Worker>
        //                           </div>

        //                         </>}
        //                       </Popup>
        //                     } */}

        //                   </div>
        //                 </div>
        //               ))}
        //             </OwlCarousel>
        //           </div>
        //         }
        //         <div className="col-md-12 col-sm-12 text-center">
        //           <Link className="btn btn-outline-danger my-3 fw-bold px-4 py-2 text-capitalize" to={`${MediaBaseURL}${wahoConference[0]?.postToursFile}`} target="_blank">
        //             {translate('web.viewToursBrochure', 'View Tours Brochure')}
        //           </Link>
        //         </div>

        //         {
        //           //Registration
        //         }
        //         {/* <h4 className="col-md-12 col-sm-12 bold text-center mt-2">
        //           {item.registrationLable}
        //         </h4>
        //         <div className="col-sm-12 text-center row justify-content-center mt-2 mx-0">
        //           {!!item.registrationList &&
        //             item.registrationList.length > 0 &&
        //             item.registrationList.map(
        //               (itemRegistration, itemRegistrationIndex) => (
        //                 <div
        //                   className="col-md-3 col-sm-12 mx-1 my-1 d-flex flex-column py-3"
        //                   style={{ border: '1px solid #C89C66', borderRadius: 5 }}
        //                   key={itemRegistrationIndex}
        //                 >
        //                   <img
        //                     className="historyImage mx-auto"
        //                     alt="asd"
        //                     src={`${MediaBaseURL}${itemRegistration.properties.registrationIcon[0].url}`}
        //                     style={{
        //                       width: 100,
        //                     }}
        //                   />
        //                   <h4 className="bold mt-2">
        //                     {itemRegistration.properties.registrationTitle}
        //                   </h4>
        //                   <p>
        //                     {
        //                       itemRegistration.properties
        //                         .registrationDescription
        //                     }
        //                   </p>
        //                   {itemRegistration.properties.registrationFile != null && itemRegistration.properties.registrationFile.length > 0 &&
        //                     <Link to={`${MediaBaseURL}${itemRegistration.properties.registrationFile[0].url}`} target="_blank" className="btn btn-danger mt-auto " style={{ cursor: "pointer" }}>
        //                       {
        //                         itemRegistration.properties
        //                           .registrationButtonLabel
        //                       }
        //                     </Link>
        //                   }
        //                 </div>
        //               )
        //             )}
        //         </div> */}


        //         {!!item.visaPdf && <div className="col-md-12 col-sm-12 text-center mt-3">
        //           <Link className="btn btn-outline-danger my-3 fw-bold px-4 py-2 text-capitalize " to={`${MediaBaseURL}${item.visaPdf}`} target="_blank">
        //             {translate('web.visaFlyer', 'View VISA INFORMATION FOR YOUR ASSISTANCE').toLowerCase()}
        //           </Link>
        //         </div>}

        //         {
        //           //Gallery
        //         }
        //         {/* <div className="col-md-12 col-sm-12 showsGallery margintopprogramCardwahoconference">
        //           <h2 className="bold text-center responsiveGalleryTitle">{item.galleryLabel}</h2>
        //           {!!item.galleryList && item.galleryList.length > 0 && (
        //             <OwlCarousel
        //               className="owl-theme"
        //               {...getcarouselOptions(4, item.galleryList.length)}
        //               marginWidth={1}
        //             >
        //               {item.galleryList.map((itemGallery, itemGalleryIndex) => (
        //                 <div className="" key={itemGalleryIndex}>
        //                   {itemGallery.properties.galleryFile[0].mediaType ===
        //                     "Image" ? (
        //                     <img
        //                       alt="asd"
        //                       src={`${MediaBaseURL}${itemGallery.properties.galleryFile[0].url}`}
        //                       style={{
        //                         width: "100%",
        //                         height: "240px",
        //                       }}
        //                     />
        //                   ) : (
        //                     <iframe
        //                       width="100%"
        //                       height="240px"
        //                       src={`${MediaBaseURL}${itemGallery.properties.galleryFile[0].url}`}
        //                       allowFullScreen
        //                       title="ex"
        //                     />
        //                   )}
        //                 </div>
        //               ))}
        //             </OwlCarousel>
        //           )}
        //         </div> */}
        //         {
        //           //Footer
        //         }
        //         <div
        //           className="col-md-12 col-sm-12 text-center text-uppercase rtl margintopprogramCardwahoconference backgroundcolorfooterwahoconference"
        //           style={{
        //             backgroundImage: `url('${MediaBaseURL}${item.footerImage}')`,
        //           }}
        //         >
        //           <div className="col-md-12 col-sm-12">
        //             <h4 className="bold lh-lg text-uppercase">{item.footerTitle}</h4>
        //           </div>
        //           <div className="col-md-12 col-sm-12" style={{ fontSize: 20 }}>
        //             <i className="fa-solid fa-user text-white mx-2"></i>
        //             <span className="bold">
        //               {translate('web.contactName', 'Contact Name')}:
        //             </span>
        //             <div className="text-white ms-2 d-inline-block text-uppercase" >
        //               <span>{item.footerContactName}</span>
        //             </div>
        //           </div>
        //           {/* <div className="col-md-12 col-sm-12 mt-2">
        //             <i className="fa fa-location-dot text-white mx-2"></i>
        //             <span className="bold">
        //               {item.addressTitle}:
        //             </span>
        //             <div className="text-white ms-2 d-inline-block" >
        //               <span>{item.address}</span>
        //             </div>
        //           </div> */}
        //           <div className="col-md-12 col-sm-12 mt-2" style={{ fontSize: 20 }}>
        //             <i className="fa-regular fa-envelope text-white mx-2"></i>
        //             <span className="bold">
        //               {item.footerEmailTitle}:
        //             </span>
        //             <Link to={`mailto:${item.footerEmailDescription}`} className="text-white ms-2" >
        //               <span>{item.footerEmailDescription}</span>
        //             </Link>
        //           </div>


        //           <div className="col-md-12 col-sm-12 mt-2" style={{ fontSize: 20 }}>
        //             <i className="fa-solid fa-phone text-white mx-2"></i>
        //             <span className="bold">
        //               {item.footerPhoneTitle}:
        //             </span>
        //             <Link to={`tel:${item.footerPhoneDescription}`} className="text-white ms-2">
        //               <span>{RemovePlusFromString(item.footerPhoneDescription)}</span>
        //             </Link>
        //           </div>
        //           <div className="col-md-12 col-sm-12 mt-3" >
        //             <h4 className="bold" >{item.footerMessage}  </h4>
        //             <h5 className="bold" >
        //               <Link to={`mailto:${item.footerRegisterEmail}`} className="text-info ms-2" >
        //                 <span>{item.footerRegisterEmail}</span>
        //               </Link>
        //             </h5>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   ))}
        // </React.Fragment>
        <ComingSoon />

      ) : (
        <Loader />
      )}
    </>
  );
}
