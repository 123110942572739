import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import SubmitBtn from "../components/SubmitBtn";
import { useLocation } from "react-router";
import {
  FetchShowEntryClassesthunk,
  FetchShowEntryFilesthunk,
  FetchShowEntrybyIdthunk,
  FetchShowSchedulethunk,
} from "../Redux/ShowEntry/ShowEntryAction";
import { FetchShowbyIdthunk, FetchShowClassRankListthunk, FetchShowGallerythunk, FetchShowJudgesthunk } from "../Redux/Shows/ShowsAction";
import { CompareDate } from "../helper/CompareDate";
import { Link } from "react-router-dom";
import { handelActiveTabOwnerProfile } from "../Redux/ShowEntry/ShowEntrySlice";
import { differenceDaysBetweenTwoDates } from "../helper/differenceDaysBetweenTwoDates";
import { formatDate } from "../helper/formatDate";
import { generateIframeFromUrl } from "../helper/generateIframeFromUrl";
import Popup from "reactjs-popup";
import ActionButton from "../components/ActionButton";
import { StringDateArEn } from "../helper/StringDateArEn";
import CategoryCard from "../components/CategoryCard";
import JudgeList from "../components/JudgeList";
import axiosInstance from "../axios/axios";
import PrizeCard from "../components/PrizeCard";
import { getDayString } from "../helper/getDayString";

const ExeclBaseURL = process.env.REACT_APP_UMBRACO;


export default function ShowEntryDetails() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const { translate } = useTranslate();
  const [userProfile, setUserProfile] = useState("null");
  const loadDataFromLocal = () => {
    const jsonString = localStorage.getItem("eahsUserProfile");
    if (jsonString) {
      const data = JSON.parse(jsonString);
      setUserProfile(data);
    }
  };
  const {
    showEntryLoading,
    showEntrybyIdData,
    showEntryFilesData,
    showEntryClassesData,
    showScheduleData,
  } = useSelector((state) => state.showEntrySlice);
  // console.log("showEntrybyIdData", showEntrybyIdData);


  const dispatch = useDispatch();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const currentId = searchParams.has("showId")
    ? searchParams.get("showId")
    : null;


  const [showPrizeListForChampions, setShowPrizeListForChampions] = useState([]);
  const [showPrizeListForClasses, setShowPrizeListForClasses] = useState([]);
  async function getShowPrizeList(params) {
    try {
      const response = await axiosInstance.get(`/Shows/GetShowPrizeByShowId?id=${currentId}`);
      if (response.data.responseCode == "200") {
        setShowPrizeListForChampions(response.data.result.showPrizeListForChampions)
        setShowPrizeListForClasses(response.data.result.showPrizeListForClasses)
      } else {
        console.error('error in getting Show Prize List')
      }

    } catch (error) {
      console.error('error in getting Show Prize List API')
    }

  }


  useEffect(() => {
    async function getShowEntrybyId() {
      let paramsShowEntrybyId = {
        id: currentId,
        langauge: currentLanguage,
      };
      dispatch(FetchShowEntrybyIdthunk(paramsShowEntrybyId));
      dispatch(FetchShowGallerythunk(paramsShowEntrybyId));
      dispatch(FetchShowEntryFilesthunk(paramsShowEntrybyId));
      dispatch(FetchShowEntryClassesthunk(paramsShowEntrybyId));
      dispatch(FetchShowSchedulethunk(paramsShowEntrybyId));
      dispatch(handelActiveTabOwnerProfile("showEntry"));
      dispatch(FetchShowJudgesthunk(paramsShowEntrybyId));
      dispatch(FetchShowClassRankListthunk(paramsShowEntrybyId));

    }

    getShowEntrybyId();
    loadDataFromLocal();
    getShowPrizeList();

  }, [dispatch, currentId, currentLanguage]);


  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${showEntryLoading ? "classdisplay" : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>

      <div className="row rtl">
        {
          // card
        }
        <div className="col-lg-8 col-md-8 col-sm-12   show-entry-first-div ">
          <div className="horseCardContainer row rtl" style={{ display: "flex" }}>
            <div className="col-lg-2 col-md-2 col-sm-12 horse-market-card-div horseMarketImageContainer  showsImageContainer ">
              <img src={showEntrybyIdData.logo} alt="logo" />
            </div>
            <div className="col-lg-10 col-md-10 col-sm-12 ">
              <span className="horseMarketCardLabeltitle">
                {showEntrybyIdData.showName}
              </span>

              <div className="row rtl mt-11wahoconference">
                <div className="col-md-12 ">
                  {!!showEntrybyIdData?.cityName && (
                    !!showEntrybyIdData?.mapLocationSharedUrl ?
                      <Link to={showEntrybyIdData?.mapLocationSharedUrl} target="_blank" className="horseCardlabelContent text-info text-decoration-underlined">
                        <i
                          className="fa-solid fa-location-dot text-info"
                          style={{ margin: "0 5px" }}
                        ></i>
                        <span>
                          {showEntrybyIdData?.cityName}
                        </span>
                      </Link> :
                      <div className="showDetaisLabel ">
                        <i
                          className="fa-solid fa-location-dot C89C66"
                          style={{ margin: "0 5px" }}
                        ></i>
                        <span>
                          {showEntrybyIdData?.cityName}
                        </span>
                      </div>
                  )}
                  <span className="horseCardlabelContent ">
                    <i className="fa fa-trophy " style={{ marginRight: 8 }}></i>
                    {showEntrybyIdData.showRank}
                  </span>

                  {/* <div className="col-md-12 titletrashshowentrycard flex_wrap justify-content-start"> */}
                  <div className="col-md-12 titletrashshowentrycard row m0 ">
                    {
                      //Show Date
                    }
                    <div className="p0 col-md-5 col-sm-12 mx-md-1">
                      <span className="horseCardlabelContent  fontWeight">
                        {translate("web.showDate", "Show Date")}
                      </span>
                      <div className=" start-date-showlist mt-11wahoconference">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate("web.startDate", "Start Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.startDate &&
                              formatDate(showEntrybyIdData.startDate)}
                          </div>
                        </div>
                      </div>

                      <div className="end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.endDate &&
                              formatDate(showEntrybyIdData.endDate)}
                          </div>
                        </div>
                      </div>
                    </div>
                    {
                      //Registration Date
                    }
                    <div className="p0 col-md-5 col-sm-12 mx-md-1">
                      <span className="horseCardlabelContent  fontWeight">
                        {translate("web.registrationDate", "Registration Date")}
                      </span>
                      <div className=" start-date-showlist mt-11wahoconference">
                        <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar "></i>
                            {translate("web.startDate", "Start Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerStartDate &&
                              formatDate(showEntrybyIdData.registerStartDate)}
                          </div>
                        </div>
                      </div>

                      <div className=" end-date-showlist">
                        <div className="col-md-12 start-end-date-display-ownerprofile">
                          <div>
                            <i className="fa fa-calendar"></i>
                            {translate("web.endDate", "End Date")}:
                          </div>
                          <div>
                            {showEntrybyIdData.registerEndDate &&
                              formatDate(showEntrybyIdData.registerEndDate)}
                          </div>
                        </div>
                      </div>
                      {
                        // <span className="horseCardlabelContent   colorred">
                        //   {translate(
                        //     "web.daysLeftRegistration",
                        //     "2 days Left for the registration"
                        //   )}
                        // </span>
                      }
                    </div>

                    {
                      //Register International Date
                    }
                    {(!!showEntrybyIdData.registerStartDateInter && !!showEntrybyIdData.registerEndDateInter && ((showEntrybyIdData.registerEndDateInter != showEntrybyIdData.registerEndDate) || (showEntrybyIdData.registerStartDateInter != showEntrybyIdData.registerStartDate))) &&
                      <div className="p0 col-md-5 col-sm-12 mx-md-1">
                        <span className="horseCardlabelContent  fontWeight">
                          {translate(
                            "web.registerInternationalDate",
                            "Register International Horse Date"
                          )}
                        </span>
                        <div className=" start-date-showlist mt-11wahoconference">
                          <div className="col-md-12 background-color-orange start-end-date-display-ownerprofile">
                            <div>
                              <i className="fa fa-calendar "></i>
                              {translate("web.startDate", "Start Date")}:
                            </div>
                            <div>
                              {showEntrybyIdData.registerStartDateInter &&
                                formatDate(
                                  showEntrybyIdData.registerStartDateInter
                                )}
                            </div>
                          </div>
                        </div>

                        <div className=" end-date-showlist">
                          <div className="col-md-12 start-end-date-display-ownerprofile">
                            <div>
                              <i className="fa fa-calendar"></i>
                              {translate("web.endDate", "End Date")}:
                            </div>
                            <div>
                              {showEntrybyIdData.registerEndDateInter &&
                                formatDate(
                                  showEntrybyIdData.registerEndDateInter
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                  <span className="horseCardlabelContent   colorred">
                    {showEntrybyIdData.startDate &&
                      differenceDaysBetweenTwoDates(
                        new Date(),
                        new Date(showEntrybyIdData.startDate)
                      )}{" "}
                    {translate(
                      "web.daysLeftShow",
                      "days Left for the show"
                    )}
                  </span>
                </div>
              </div>
              <div className="textalignend">
                {(userProfile.accountType !== 'Organizer' && showEntrybyIdData.isShowRegisterStarted) && <Link
                  className={`btn button-saveasdraft ${
                    // CompareDate(
                    //   showEntrybyIdData.startDate,
                    //   showEntrybyIdData.endDate
                    // )
                    CompareDate(
                      showEntrybyIdData.registerStartDate,
                      showEntrybyIdData.endDate
                    )
                      ? "newbtn"
                      : "btn-danger"
                    } wd-20`}
                  disabled={
                    CompareDate(
                      showEntrybyIdData.registerStartDate,
                      showEntrybyIdData.endDate
                    )
                      ? false
                      : true
                  }
                  style={CompareDate(
                    showEntrybyIdData.registerStartDate,
                    showEntrybyIdData.endDate
                  )
                    ? { opacity: 1, cursor: 'pointer' }
                    : { opacity: 0.5, cursor: "not-allowed" }}
                  onClick={(event) => {
                    if (
                      !CompareDate(
                        showEntrybyIdData.registerStartDate,
                        showEntrybyIdData.endDate
                      )
                    ) {
                      event.preventDefault();
                    }
                  }}
                  to={
                    showEntrybyIdData.showParticipantRequestStatus === "New" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Draft" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Pending" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Returned"
                      ? `/showentrycard?entryFees=${showEntrybyIdData.entryFees
                      }&maxHorses=${parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                      parseInt(showEntrybyIdData.completedRequestsCount)
                      }&showNameAr=${showEntrybyIdData.showNameAr
                      }&showNameEn=${showEntrybyIdData.showNameEn
                      }&showId=${currentId}&registerStartDate=${showEntrybyIdData.registerStartDate
                      }&registerEndDate=${showEntrybyIdData.registerEndDate
                      }&registerStartDateInter=${showEntrybyIdData.registerStartDateInter
                      }&registerEndDateInter=${showEntrybyIdData.registerEndDateInter
                      }`
                      : `/showentryconflict?maxHorses=${parseInt(showEntrybyIdData.maxHorseNumberPerOwner)
                      // parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                      // parseInt(showEntrybyIdData.completedRequestsCount)
                      }&showNameAr=${showEntrybyIdData.showNameAr
                      }&showNameEn=${showEntrybyIdData.showNameEn
                      }&showId=${currentId}`
                  }
                >
                  {showEntrybyIdData.showParticipantRequestStatus === "New"
                    ? translate("web.participateNow", "Participate Now")
                    : showEntrybyIdData.showParticipantRequestStatus ===
                      "Draft" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Pending" ||
                      showEntrybyIdData.showParticipantRequestStatus ===
                      "Returned"
                      ? translate("web.continue", "Continue")
                      : translate("web.addConflict", "Add Conflict")}
                </Link>}

                {userProfile.accountType == 'Organizer' &&
                  <Link
                    target="_blank"
                    to={`${ExeclBaseURL}/BackOfficeShow/ExportShowParticipant?id=${currentId}`}
                    className={`btn button-saveasdraft newbtn wd-20`}
                  >
                    {translate('web.getParticipateHorses', 'Get Participate Horses')}
                  </Link>
                }
                {
                  // <SubmitBtn
                  //   width={"20%"}
                  //   disabled={
                  //     CompareDate(
                  //       showEntrybyIdData.startDate,
                  //       showEntrybyIdData.endDate
                  //     )
                  //       ? false
                  //       : true
                  //   }
                  //   text={
                  //     showEntrybyIdData.showParticipantRequestStatus === "New"
                  //       ? translate("web.registerNow", "Register Now")
                  //       : showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Draft" ||
                  //         showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Pending" ||
                  //         showEntrybyIdData.showParticipantRequestStatus ===
                  //           "Returned"
                  //       ? translate("web.continue", "Continue")
                  //       : translate("web.addConflict", "Add Conflict")
                  //   }
                  //   type={"link"}
                  //   to={
                  //     showEntrybyIdData.showParticipantRequestStatus === "New" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Draft" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Pending" ||
                  //     showEntrybyIdData.showParticipantRequestStatus ===
                  //       "Returned"
                  //       ? `/showentrycard?entryFees=${
                  //           showEntrybyIdData.entryFees
                  //         }&maxHorses=${
                  //           parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                  //           parseInt(showEntrybyIdData.completedRequestsCount)
                  //         }&showName=${
                  //           showEntrybyIdData.showName
                  //         }&showId=${currentId}&registerStartDate=${
                  //           showEntrybyIdData.registerStartDate
                  //         }&registerEndDate=${
                  //           showEntrybyIdData.registerEndDate
                  //         }&registerStartDateInter=${
                  //           showEntrybyIdData.registerStartDateInter
                  //         }&registerEndDateInter=${
                  //           showEntrybyIdData.registerEndDateInter
                  //         }`
                  //       : `/showentryconflict?maxHorses=${
                  //           parseInt(showEntrybyIdData.maxHorseNumberPerOwner) -
                  //           parseInt(showEntrybyIdData.completedRequestsCount)
                  //         }&showName=${
                  //           showEntrybyIdData.showName
                  //         }&showId=${currentId}`
                  //   }
                  // />
                }

                {showEntrybyIdData.showParticipantRequestStatus ===
                  "Completed" &&
                  showEntrybyIdData.maxHorseNumberPerOwner >
                  showEntrybyIdData.completedRequestsCount && (
                    <Link
                      className={`btn button-saveasdraft ${CompareDate(
                        showEntrybyIdData.registerStartDate,
                        showEntrybyIdData.endDate
                      )
                        ? "newbtn"
                        : "btn-danger"
                        } wd-20 marginrightleft-10`}
                      disabled={
                        CompareDate(
                          showEntrybyIdData.registerStartDate,
                          showEntrybyIdData.endDate
                        )
                          ? false
                          : true
                      }
                      onClick={(event) => {
                        if (
                          !CompareDate(
                            showEntrybyIdData.registerStartDate,
                            showEntrybyIdData.endDate
                          )
                        ) {
                          event.preventDefault();
                        }
                      }}
                      to={`/showentrycard?entryFees=${showEntrybyIdData.entryFees
                        }&maxHorses=${parseInt(
                          showEntrybyIdData.maxHorseNumberPerOwner
                        )}&showNameAr=${showEntrybyIdData.showNameAr
                        }&showNameEn=${showEntrybyIdData.showNameEn
                        }&showId=${currentId}&registerStartDate=${showEntrybyIdData.registerStartDate
                        }&registerEndDate=${showEntrybyIdData.registerEndDate
                        }&registerStartDateInter=${showEntrybyIdData.registerStartDateInter
                        }&registerEndDateInter=${showEntrybyIdData.registerEndDateInter
                        }`}
                    >
                      {translate(
                        "web.addAdditionalHorse",
                        "Add Additional Horse"
                      )}
                    </Link>
                  )}
              </div>
            </div>
          </div>
        </div>
        <JudgeList showID={currentId} />

        {
          // location
        }
        {((showEntrybyIdData.address != "" &&
          showEntrybyIdData.address != "undefiend" &&
          showEntrybyIdData.address) ||
          (showEntrybyIdData.mapLocation != "" &&
            showEntrybyIdData.mapLocation != "undefiend" &&
            showEntrybyIdData.mapLocation != null)) && (
            <div className="col-md-12 col-sm-12  ">
              {showEntrybyIdData.address != "" &&
                showEntrybyIdData.address != "undefiend" &&
                showEntrybyIdData.address && (
                  <div className="col-md-12 col-sm-12 ">
                    <span className="horseMarketCardLabeltitle">
                      {translate("web.address", "Address")}
                    </span>
                    <p>
                      <span className="horseMarketCardLabeltitle ">
                        {showEntrybyIdData.address}
                        {
                          //"Building 100, Any Street, Abu Dhabi, ABZ 001"
                        }
                      </span>
                    </p>
                  </div>
                )}
              {showEntrybyIdData.mapLocation != "" &&
                showEntrybyIdData.mapLocation != "undefiend" &&
                showEntrybyIdData.mapLocation != null && (
                  <div
                    className="col-md-12 col-sm-12 "
                    dangerouslySetInnerHTML={{
                      __html: generateIframeFromUrl(
                        showEntrybyIdData.mapLocation
                      ),
                    }}
                  ></div>
                )}
            </div>
          )}

        {
          // Terms & Conditions
        }
        <div className="col-md-12 col-sm-12  rtl ">
          <div className="col-md-12  col-sm-12">
            <h4 className="bold my-2">
              {translate(
                "web.additionalData",
                "Additional Data"
              )}
            </h4>
          </div>

          {!!showEntryFilesData &&
            showEntryFilesData.length > 0 &&
            <ul className="list-group ">
              {showEntryFilesData.map((fileItem, iFileItem) => (
                <li className="list-group-item" key={iFileItem}>
                  <span className="d-flex align-items-center ">
                    <div className="d-flex align-items-center justify-content-between" >
                      <div className="mx-2" style={{ width: 5, height: 5, background: '#000', border: '1px solid #000', borderRadius: 2.5 }} ></div>
                      {
                        fileItem.fileName
                      }
                    </div>
                    {!!fileItem.url ?
                      <Link to={fileItem.url} target="_blank" key={iFileItem} className="btn btn-danger mx-2" style={{ minWidth: 120 }}>
                        {translate("web.downloadFile", "DownLoad Terms And Conditions File")}
                      </Link>
                      :
                      <Popup
                        modal
                        trigger={
                          <div key={iFileItem} className="btn btn-danger mx-2" style={{ minWidth: 120 }}>
                            {translate("web.viewDetails", "View Details")}
                          </div>
                        }
                        closeOnDocumentClick={true}
                        closeOnEscape
                        className="popupUnsetmaxWidth"
                      >
                        {(close) => (
                          <React.Fragment>
                            <div
                              className="text-start"
                              style={{ maxHeight: '60vh', overflowY: 'scroll' }}
                              dangerouslySetInnerHTML={{ __html: fileItem.termsAndConditions }}
                            />
                            <br />
                            <div
                              className="rtl"
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-around',
                              }}
                            >
                              <ActionButton type={'btn'} label={translate("web.close", "close")} onClick={close} text={translate("web.close", "close")} />
                            </div>
                          </React.Fragment>
                        )}
                      </Popup>
                    }
                  </span>
                </li>
              ))}
            </ul>
          }
          {
            // <div className="col-md-12 col-sm-12 mb-10">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate("web.registrationTC", "Registration T&C")}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
            // <div className="col-md-12 col-sm-12 mt-15 mb-5">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate("web.horseEntryTC", "Horse entry t&C")}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
            // <div className="col-md-12 col-sm-12 mt-15 mb-5">
            //   <div className="col-md-2 col-sm-12">
            //     <span className="horseCardlabelContent ">
            //       {translate(
            //         "web.internationalRegistrationTC",
            //         "International Registration T&C"
            //       )}
            //     </span>
            //   </div>
            //   <div className="col-md-2 col-sm-12">
            //     {!!showEntrybyIdData.termsAndConditions && (
            //       <SubmitBtn
            //         type={"linkReset"}
            //         text={translate("web.viewDetails", "View Details")}
            //         to={showEntrybyIdData.termsAndConditions}
            //         target="_blank"
            //       />
            //     )}
            //   </div>
            // </div>
          }
        </div>
        {
          // Categories
        }
        <div className="col-md-12 col-sm-12  m0 row rtl ">
          <div className="col-md-12 col-sm-12 p0 ">
            <h4 className="bold my-2">
              {translate("web.categories", "Categories")}
            </h4>
          </div>
          {!!showEntryClassesData && showEntryClassesData.length > 0 ? (
            showEntryClassesData.map((item, i) => (
              <CategoryCard i={i} item={item} translate={translate} />
            ))
          ) : (
            <h2 className="text-danger text-center dblock">
              {translate("web.nodataFound", "No Data Found !")}
            </h2>
          )}
        </div>

        {/* {Prize List} */}
        {(!!showPrizeListForChampions.length > 0 || !!showPrizeListForClasses.length > 0) && <div className="col-md-12 row m0 rtl">
          <h4 className="bold my-2">
            {translate("web.prizeMoney", "Prize Money")}
          </h4>
          {!!showPrizeListForClasses.length > 0 &&
            <>
              <h5 className="bold my-2">
                {translate("web.classesPrize", "Classes Prize")}
              </h5>
              {!!showPrizeListForClasses && showPrizeListForClasses.map((prize) => (<PrizeCard data={prize} />))}

            </>
          }
          {!!showPrizeListForChampions.length > 0 &&
            <>
              <h5 className="bold my-2">
                {translate("web.championsPrize", "Champions Prize")}
              </h5>
              {!!showPrizeListForChampions && showPrizeListForChampions.map((prize) => (<PrizeCard data={prize} specialRanks={true} />))}
            </>}

        </div>}
        {
          //Show Schedule
        }
        <div className="col-md-12 row m0 rtl">
          <h4 className="bold my-2">
            {translate("web.showSchedule", "Show Schedule")}
          </h4>
          {!!showScheduleData && showScheduleData.length > 0 ? (
            showScheduleData.map((item, i) => (
              <div className="col-md-6 mb-25" key={i}>
                <div className="col-md-12 bordercategorycard titleShowSchedule">
                  <p className="showScheduleParag">{getDayString(item.dayDisplayName, currentLanguage)}</p>
                  <p className="showScheduleParag">{StringDateArEn(item.dayName, currentLanguage)} </p>
                  <p className="showScheduleParag">{item.time}</p>
                </div>

                {item.showClassesList.map(
                  (showClassesListItem, iShowClassesList) => (
                    <div
                      className="col-md-12 bordercategorycard row m0"
                      key={iShowClassesList}
                    >
                      <div className="col-md-4">
                        <h4>
                          {!!showClassesListItem.classShortName &&
                            showClassesListItem.classShortName}
                        </h4>
                      </div>
                      <div className="col-md-8">
                        <h4>{showClassesListItem.className}</h4>
                      </div>
                    </div>
                  )
                )}
              </div>
            ))
          ) : (
            <h2 className="text-danger text-center dblock">
              {translate("web.comingSoon", "Comming Soon...")}
            </h2>
          )}
        </div>


      </div>
    </div>
  );
}
