import React, { useState, useEffect } from "react";
import { FaFingerprint } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Colors } from "../constants/theme";
import { useSelector } from "react-redux";
import { useTranslate } from "./TranslationText";

export default function ActionButton({
  type,
  marginBottom,
  text,
  margin,
  width,
  label,
  nextLink,
  additionalText = "",
  className = "",
  to,
  isPressed,
  disabled = false,
  target = "_blank",
  padding,
  ...props
}) {
  const { onClick } = props;
  const [isHovered, setIsHovered] = useState(false);
  const redirect_uri = process.env.REACT_APP_UAE_PASS_REDIRECT_LINK;
  const client_id = process.env.REACT_APP_UAE_PASS_CLIENT;
  const authLink = process.env.REACT_APP_UAE_PASS_AUTH_LINK;
  // console.log(redirect_uri);
  // console.log("authlink is", loginlink);
  const BaseURL = process.env.REACT_APP_BASE_URL;
  const { translate } = useTranslate();

  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const loginlink = `${authLink}?response_type=code&client_id=${client_id}&scope=urn:uae:digitalid:profile:general&state=UzkLPOzcsHs4SL9cNZ7bFATd&redirect_uri=${redirect_uri}&acr_values=urn:safelayer:tws:policies:authentication:level:low&ui_locales=${currentLanguage}`;
  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleButtonClick = () => {
    // Logic to handle button click and redirect to an external URL
    window.location.href = nextLink ? nextLink : loginlink;
  };

  const mergedMargin = {
    marginLeft: margin ?? 5,
    marginBottom: marginBottom ?? 0,
    margin,
  };

  const buttonBaseStyle = {
    padding: "10px 20px",
    outline: 0,
    border: "solid",
    borderWidth: 1,
    borderColor: Colors.PRIMARY_RED,
    transition: "all 0.3s",
    ...(width && { width }), // Apply width if width parameter is passed
    ...mergedMargin,
  };

  const primaryStyle = {
    ...buttonBaseStyle,
    background: isHovered || isPressed ? Colors.WHITE : Colors.PRIMARY_RED,
    color: isHovered || isPressed ? Colors.PRIMARY_RED : Colors.WHITE,
  };
  const uaePassStyle = {
    ...buttonBaseStyle,
    background: isHovered ? "#f0f0f0" : Colors.WHITE,
    borderColor: "black",
    color: isHovered ? "black" : "black",
    fontWeight: "bold",
  };

  const secondaryStyle = {
    ...buttonBaseStyle,
    background: isHovered ? Colors.PRIMARY_RED : Colors.WHITE,
    color: isHovered ? Colors.WHITE : Colors.PRIMARY_RED,
  };

  const BUTTON_TYPES = {
    submit: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    confirmandpay: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    enrollNow: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    startbiding: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    loginuaepass: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    reset: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    "view-details": {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    loadmore: {
      style: primaryStyle,
      type: "submit",
      onMouseOver: handleMouseOver,
      onMouseOut: handleMouseOut,
      onClick: handleButtonClick,
      to: "",
      children: null,
      Container: Link,
    },
    "enroll-now": {
      style: primaryStyle,
      type: "submit",
      onClick: handleButtonClick,
      to: "",
      Container: Link,
    },
  };

  const renderButton = (props) => {
    const { Container, children, ...buttonProps } = props;
    return (
      <Container
        onMouseOut={handleMouseOut}
        onMouseOver={handleMouseOver}
        {...buttonProps}
      >
        {children}
      </Container>
    );
  };

  return (
    <React.Fragment>
      {/* {renderButton(BUTTON_TYPES[type])} */}
      {type === "submit" ? (
        <>
          <button
            type="submit"
            style={primaryStyle}
            className={`btn ${className}`}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={onClick}
            disabled={disabled}
          >
            {text}
          </button>
          {!!additionalText &&
            <div style={{ maxWidth: 260, textAlign: "center" }} dangerouslySetInnerHTML={{ __html: additionalText }} />
          }
        </>
      ) : null}
      {type === "requests" ? (
        <button
          type="submit"
          // style={primaryStyle}
          className={`btn ${className}`}
          // onMouseOver={handleMouseOver}
          // onMouseOut={handleMouseOut}
          onClick={onClick}
          disabled={disabled}
        >
          {text}
        </button>
      ) : null}
      {type === "confirmandpay" ? (
        <button
          type="submit"
          className="btn"
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {text}
        </button>
      ) : null}
      {type === "btn" ? (
        <div
          className={disabled ? "btn disabledBTN" : "btn"}
          style={secondaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={disabled ? null : onClick}
          dangerouslySetInnerHTML={{ __html: text }}
        ></div>
      ) : null}
      {type === "enrollNow" ? (
        <button
          type="submit"
          className={`btn ${className}`}
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {text}
        </button>
      ) : null}
      {type === "startbiding" ? (
        <Link
          className="btn"
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          to={`/bidding?auctionHorseId=${to}`}
        >
          {text}
        </Link>
      ) : null}
      {type === "startbidinghybrid" ? (
        <Link
          className="btn"
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          to={to}
        >
          {text}
        </Link>
      ) : null}
      {type === "loginuaepass" ? (
        isHovered && currentLanguage === "en" ? (
          <img
            src={`${BaseURL}/assets/img/uaepasshoved.png`}
            // type="button"
            // className="btn"
            alt="UAE PASS"
            // style={uaePassStyle}
            style={{ cursor: "pointer" }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleButtonClick}
          />
        ) : (
          currentLanguage === "en" && (
            <img
              src={`${BaseURL}/assets/img/uaepasssignin.png`}
              // type="button"
              // className="btn"
              alt="UAE PASS"
              // style={uaePassStyle}
              style={{ cursor: "pointer" }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleButtonClick}
            />
          )
        )
      ) : null}
      {type === "loginuaepass" ? (
        isHovered && currentLanguage === "ar" ? (
          <img
            src={`${BaseURL}/assets/img/uaepassarfocus.png`}
            // type="button"
            // className="btn"
            alt="UAE PASS"
            // style={uaePassStyle}
            style={{ cursor: "pointer" }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleButtonClick}
          />
        ) : (
          currentLanguage === "ar" && (
            <img
              src={`${BaseURL}/assets/img/uaepassar.png`}
              // type="button"
              // className="btn"
              alt="UAE PASS"
              // style={uaePassStyle}
              style={{ cursor: "pointer" }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleButtonClick}
            />
          )
        )
      ) : null}
      {type === "signupuaepass" ? (
        isHovered && currentLanguage === "en" ? (
          <img
            src={`${BaseURL}/assets/img/signupuaepasshover.png`}
            // type="button"
            // className="btn"
            alt="UAE PASS"
            // style={uaePassStyle}
            style={{ cursor: "pointer" }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleButtonClick}
          />
        ) : (
          currentLanguage === "en" && (
            <img
              src={`${BaseURL}/assets/img/signupuaepass.png`}
              // type="button"
              // className="btn"
              alt="UAE PASS"
              // style={uaePassStyle}
              style={{ cursor: "pointer" }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleButtonClick}
            />
          )
        )
      ) : null}
      {type === "signupuaepass" ? (
        isHovered && currentLanguage === "ar" ? (
          <img
            src={`${BaseURL}/assets/img/signupuaepassarhoverd.png`}
            // type="button"
            // className="btn"
            alt="UAE PASS"
            // style={uaePassStyle}
            style={{ cursor: "pointer" }}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onClick={handleButtonClick}
          />
        ) : (
          currentLanguage === "ar" && (
            <img
              src={`${BaseURL}/assets/img/signupuaepassar.png`}
              // type="button"
              // className="btn"
              alt="UAE PASS"
              // style={uaePassStyle}
              style={{ cursor: "pointer" }}
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}
              onClick={handleButtonClick}
            />
          )
        )
      ) : null}
      {type === "reset" ? (
        <button
          type="reset"
          className="btn"
          style={secondaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {translate("web.reset", "Reset")}
        </button>
      ) : null}
      {type === "resetandfetch" ? (
        <button
          type="reset"
          className="btn"
          style={secondaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
        >
          {translate("web.reset", "Reset")}
        </button>
      ) : null}
      {type === "view-details" ? (
        <Link
          to={`/horse-auction?horseid=${to}`}
          className="btn"
          style={secondaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {text}
        </Link>
      ) : null}
      {type === "loadmore" ? (
        <button
          type="button"
          className="btn"
          style={secondaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
          disabled={disabled}
        >
          {!!label ? label : "Show more"}
        </button>
      ) : null}
      {type === "enroll-now" ? (
        <Link
          to={`/singleauction?id=${to}`}
          className={`btn ${className}`}
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {text}
        </Link>
      ) : null}
      {type === "openLink" ? (
        <Link
          to={to}
          className="btn"
          target={target}
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          dangerouslySetInnerHTML={{ __html: text }}
        ></Link>
      ) : null}
      {type === "internalLink" ? (
        <Link
          to={to}
          className="btn"
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          dangerouslySetInnerHTML={{ __html: text }}

        >
        </Link>
      ) : null}
      {type === "normalbtn" ? (
        <button
          type="button"
          className={`btn ${className} ${isPressed ? "bold" : ""}`}
          style={primaryStyle}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={onClick}
          disabled={disabled}
          dangerouslySetInnerHTML={{ __html: text }}
        >
        </button>
      ) : null}
      {type === "bootstrapbtn" ? (
        <button
          type="button"
          className={`btn ${className} ${isPressed ? "bold" : ""}`}
          style={{ width: '100%', padding: '10px 20px' }}
          onClick={onClick}
          disabled={disabled}
          dangerouslySetInnerHTML={{ __html: text }}
        ></button>
      ) : null}
      {type === "bootstrapbtnservice" ? (
        <button
          type="button"
          className={`btn ${className} ${isPressed ? "bold" : ""}`}
          style={{ width: width, padding: padding }}
          onClick={onClick}
          disabled={disabled}
          dangerouslySetInnerHTML={{ __html: text }}
        ></button>
      ) : null}
    </React.Fragment>
  );
}
