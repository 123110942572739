import React from 'react'
import ArenaTable from './ArenaTable';
import { TableObject } from './TableObject';

export default function Arena77Table({ totalTables, toggleTableSelection, BaseURL, translate }) {
    return <div className="bookingTableContainer" style={{ width: 1350 }}>
        <div className="bookingTablesRow" style={{marginLeft:50}}>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            <div className="vipSection" style={{ marginLeft: 15 }}>{translate('web.judgesNotForSelleing', 'Judges Not For Selleing')}</div>

            {/* Refactor to be part of map */}

        </div>
        <div className="bookingTableCenter">
            <div className="bookingTablesColumn" style={{ marginRight: 10 }}>
                {totalTables
                    .filter((item, index) => item.tableNo > 56 && item.tableNo < 67)
                    .map((item, index) => (
                        index < 10 &&
                        <ArenaTable
                            key={item.tableNo}
                            tableNumber={item.tableNo}
                            tablePrice={item.price}
                            state={TableObject.fromRaw(item).state()}
                            onClick={() => toggleTableSelection(item.id, item.price)} />
                    ))}
            </div>
            <div className="bookingTablesColumn" style={{ marginRight: 10 }}>
                {totalTables
                    .filter((item, index) => item.tableNo > 46 && item.tableNo < 57)
                    .map((item, index) => (
                        index < 10 &&
                        <ArenaTable
                            key={item.tableNo}
                            tableNumber={item.tableNo}
                            tablePrice={item.price}
                            state={TableObject.fromRaw(item).state()}
                            onClick={() => toggleTableSelection(item.id, item.price)} />
                    ))}
            </div>
            <div className="bookingTablesColumn" style={{ marginRight: 10 }}>
                {totalTables

                    .filter((item, index) => item.tableNo > 35 && item.tableNo < 47)
                    .map((item, index) => (
                        index < 11 &&
                        <ArenaTable
                            key={item.tableNo}
                            tableNumber={item.tableNo}
                            tablePrice={item.price}
                            state={TableObject.fromRaw(item).state()}
                            onClick={() => toggleTableSelection(item.id, item.price)} />
                    ))}
            </div>
            <div className="BookingTableArena">
                <img
                    style={{ background: '#A67B52' }}
                    src={`${BaseURL}/assets/img/HorseArena.png`}
                    alt="Horse Arena" />
            </div>
        </div>
        <div className="bookingTablesRow withVIPSection" style={{ marginLeft: 50 }}>
            {totalTables

                .filter((item, index) => item.tableNo > 16 && item.tableNo < 24)
                .map((item, index) => (
                    index < 7 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo < 7)
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow" style={{ marginLeft: 50 }}>
            {totalTables

                .filter((item, index) => item.tableNo > 23 && item.tableNo < 30)
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo > 6 && item.tableNo < 12)
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow" style={{ marginLeft: 50 }}>
            {totalTables

                .filter((item, index) => item.tableNo > 29 && item.tableNo < 36)
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables
                .filter((item, index) => item.tableNo > 11 && item.tableNo < 17)
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)} />
                ))}
        </div>
    </div>;
}
