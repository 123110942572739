import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { FetchStudBookLanguagethunk } from "../Redux/StudBook/StudBookAction";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
const baseURL = process.env.REACT_APP_BASE_URL;

const Layout = ({ children }) => {
  const [userProfile, setUserProfile] = useState("");
  const { pathname } = useLocation();


  const { currentLanguage } = useSelector(
    (state) => state.studBookSlice
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(FetchStudBookLanguagethunk(currentLanguage === "en" ? "1" : "2"));
  }, [currentLanguage]);


  return (
    <div>
      {currentLanguage === 'ar' && <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css" integrity="sha384-gXt9imSW0VcJVHezoNQsP+TNrjYXoGcrqBZJpry9zJt8PCQjobwmhMGaDHTASo9N" crossorigin="anonymous" />}
      {currentLanguage === 'en' && <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous" />}
      <link rel="stylesheet" href={`${baseURL}/assets/css/style-prod-v7.css`} />
      {currentLanguage === 'ar' && <link rel="stylesheet" href={`${baseURL}/assets/css/rtl-prod-v3.css`} />}

      {/* Pass setUserProfile as a prop to Header */}
      {pathname != "/m-book-table"
        && pathname != "/m-ads-book-table"
        && pathname != "/m-opend-ads"
        && pathname != "/fullScreenBidding"
        && pathname != "/m-opend-events"
        && pathname != "/m-wahoconference" &&
        <Header setUserProfile={setUserProfile} />
      }
      <div className="mainLayoutContainer">{children}</div>
      {pathname != "/m-book-table"
        && pathname != "/m-ads-book-table"
        && pathname != "/m-opend-ads"
        && pathname != "/fullScreenBidding"
        && pathname != "/m-wahoconference"
        && pathname != "/m-opend-events" &&
        <Footer />
      }
    </div>
  );
};

export default Layout;
