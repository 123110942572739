import React, { useEffect, useRef, useState } from "react";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useDispatch, useSelector } from "react-redux";
import NetaqInput from "../components/NetaqInput";
import Select from "react-select";
import ActionButton from "../components/ActionButton";
import { useLocation, useNavigate } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import {
  FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk,
  FetchGetLoacationNameByLatLangthunk,
  FetchGetServiceDefinitionByIdthunk,
  FetchGetServicesRequestByIdthunk,
} from "../Redux/ServicesRequests/ServicesRequestsAction";
import {
  resetGetServicesRequestByIdData,
  resetToasterMessageServicesRequests,
} from "../Redux/ServicesRequests/ServicesRequestsSlice";
import { FetchSubOwnerProfilesByIdthunk } from "../Redux/ShowEntry/ShowEntryAction";
import axios from "../axios/axios";
import axiosMap from "axios";
import SimpleFileUpload from "../components/SimpleFileUpload";
import { base64ToBlob } from "../helper/base64ToBlob";
import EahsForm from "../components/EahsForm";

export default function RequestForRegistrationOfAnArabianHorseStud() {
  //env 
  const UMBRACOURL = process.env.REACT_APP_UMBRACO;
  //const UMBRACOURL = process.env.REACT_APP_Local_UMBRACO;
  const BaseURL = process.env.REACT_APP_BASE_URL;

  //selector
  const { currentLanguage } = useSelector((state) => state.studBookSlice);

  const {
    toasterMessageServicesRequests,
    servicesRequestsLoading,
    getServicesRequestByIdData,
    getServiceDefinitionByIdData,
    createOrEditStudRegistrationCertificatesServiceRequestData,
    getLoacationNameByLatLangData
  } = useSelector((state) => state.servicesRequestsSlice);

  const userProfile = JSON.parse(localStorage.getItem("eahsUserProfile"));
  const subOwnerProfilesByIdData = userProfile?.subAccounts ?? [];

  const dispatch = useDispatch();

  const { translate } = useTranslate();

  const navigate = useNavigate();

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const serviceId = searchParams.has("serviceId")
    ? searchParams.get("serviceId")
    : null;

  const id = searchParams.has("id") ? searchParams.get("id") : null;

  const action = searchParams.has("action") ? searchParams.get("action") : null;

  //state
  const [formModel, setFormModel] = useState({
    id: "",
    studRegistrationId: "",
    eventName: "",
    comment: "",
    referenceNumber: "",
    requestsType: "",
    paymentStatus: "",

    studName: "",
    studNameAr: "",
    emirateId: "",
    emirateDrop: {
      label: translate("web.emirate", "Emirate"),
      value: null,
    },
    regionId: "",
    regionDrop: {
      label: translate("web.area", "Area"),
      value: null,
    },
    studAddress: "",
    studPhone: "",
    latitude: "23.55344",
    longitude: "53.56701",
    lockey: Math.floor(Math.random() * 10000 + 1000),

    isShowCategory: false,
    isRaceCategory: false,
    isEnduranceCategory: false,
    isOtherCategory: false,
    gpsLocationLink: "",
    ownerComment: "",

    filePathLandOwnership: "",
    fileTypeLandOwnership: "",
    fileNameLandOwnership: "",
    documentBase64LandOwnership: "",
    landOwnershipAttachmentFile: null,
  });

  const [
    landOwnershipAttachmentFile,
    setLandOwnershipAttachmentFile,
  ] = useState([]);

  const _isOnlinePayment = useRef("");

  const [checkedItems, setCheckedItems] = useState({
    option1: false,
  });

  const [profile, setProfile] = useState({
    label:
      !!userProfile &&
      userProfile?.ownerRegisterNo +
      " - " +
      userProfile[`fullName${currentLanguage === "en" ? "" : "Ar"}`],
    value: localStorage.getItem("EAHSUserID"),
  });
  const subAccountId = localStorage.getItem("currentSubAccountId");

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
    }),
  };

  const [latitude, setLatitude] = useState(23.55344);
  const [longitude, setLongitude] = useState(53.56701);
  const [lockey] = useState(Math.floor(Math.random() * 10000 + 1000));

  const [errorStudName, setErrorStudName] = useState("none");
  const [errorStudNameAr, setErrorStudNameAr] = useState("none");
  const [errorEmirateId, setErrorEmirateId] = useState("none");
  const [errorRegionId, setErrorRegionId] = useState("none");
  const [errorStudAddress, setErrorStudAddress] = useState("none");
  const [errorStudPhone, setErrorStudPhone] = useState("none");
  const [errorHorseCategory, setErrorHorseCategory] = useState("none");
  const [errorGpsLocationLink, setErrorGpsLocationLink] = useState("none");
  //const [showHideMap, setShowHideMap] = useState(false);
  const [emiratelistoptions, setEmirateListOptions] = useState([]);
  const [regionsList, setRegionsList] = useState([]);

  //function
  async function getEmirateList() {
    try {
      const emiratelist = await axios.get('/lookup/getmaincities')

      const formattedData = await emiratelist.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));

      setEmirateListOptions(formattedData)
    } catch (error) {
      toast.error(translate("web.errorFetchingEmirateList", "Error fetching emirate list"));
    }
  }

  async function getRegionListbyID(id) {
    try {
      const regionList = await axios.get(`/lookup/getareasbyemirateid?EmirateId=${id}`)
      const formattedData = await regionList.data.result.map((item) => ({
        text: item.name,
        value: item.id,
      }));
      setRegionsList(formattedData)
    } catch (error) {
      toast.error(translate("web.errorFetchingAreaList", "Error fetching area list"));
    }
  }

  // const toggleMap = () => {
  //   setShowHideMap((prev) => !prev);
  // };

  const selectLocation = async () => {
    try {
      const response = await axiosMap.get(
        `https://maps.atlasapps.net/map.aspx?getLocationString=${lockey}`
      );

      if (!response.data) {
        toast.error(translate("web.pleaseMoveLocationMap", "Please move the location on the map."));
      } else {
        const [lat, lng] = response.data.split(',');

        let params = {
          latVal: lat,
          longVal: lng
        };

        dispatch(FetchGetLoacationNameByLatLangthunk(params));

        setLatitude(lat);
        setLongitude(lng);

        setFormModel({
          ...formModel,
          latitude: lat,
          longitude: lng,
          gpsLocationLink: `https://www.google.com/maps?q=${lat},${lng}`,
          studAddress: getLoacationNameByLatLangData
        });

        toast.success(translate("web.locationSuccessfullyLocated", "Location successfully located."));
      }
    } catch (error) {
      toast.error(translate("web.errorFetchingLocationData", "Error fetching location data."));
    }
  };

  const handleFormChange = (e, input) => {
    if (!!input && input === "emirateId") {
      getRegionListbyID(e.value);

      setFormModel({
        ...formModel,
        emirateId: e.value,
        emirateDrop: e,
        regionId: "",
        regionDrop: {
          label: translate("web.area", "Area"),
          value: null,
        }
      });
    } else if (!!input && input === "regionId") {
      setFormModel({
        ...formModel,
        regionId: e.value,
        regionDrop: e,
      });
    }
    else if (input === "horseCategory") {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.checked,
      });
    }
    else {
      setFormModel({
        ...formModel,
        [e.target.name]: e.target.value,
      });
    }

  };

  const handleUploadLandOwnershipChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      if (e.target.files[0].size > 10 * 1024 * 1024) {
        toast.error(translate("web.fileSizeLimit", "File size 10MB limit."));
      } else {
        setFormModel({
          ...formModel,
          landOwnershipAttachmentFile: e.target.files[0],
        });

        setLandOwnershipAttachmentFile([
          {
            file: e.target.files[0],
            name: e.target.files[0].name,
          },
        ]);
      }
    }
  };

  const validInputs = () => {
    let valid = true;

    if (
      formModel.studName === ""
    ) {
      setErrorStudName("");
      valid = false;
    }
    else {
      setErrorStudName("none");
    }

    if (
      formModel.studNameAr === ""
    ) {
      setErrorStudNameAr("");
      valid = false;
    }
    else {
      setErrorStudNameAr("none");
    }

    if (
      formModel.emirateId === ""
    ) {
      setErrorEmirateId("");
      valid = false;
    }
    else {
      setErrorEmirateId("none");
    }

    if (
      formModel.regionId === ""
    ) {
      setErrorRegionId("");
      valid = false;
    }
    else {
      setErrorRegionId("none");
    }

    if (
      formModel.studAddress === ""
    ) {
      setErrorStudAddress("");
      valid = false;
    }
    else {
      setErrorStudAddress("none");
    }

    if (
      formModel.studPhone === ""
    ) {
      setErrorStudPhone("");
      valid = false;
    }
    else {
      setErrorStudPhone("none");
    }

    if (
      formModel.isShowCategory === false && formModel.isRaceCategory === false && formModel.isEnduranceCategory === false && formModel.isOtherCategory === false
    ) {
      setErrorHorseCategory("");
      valid = false;
    }
    else {
      setErrorHorseCategory("none");
    }

    if (
      formModel.gpsLocationLink === ""
    ) {
      setErrorGpsLocationLink("");
      valid = false;
    }
    else {
      setErrorGpsLocationLink("none");
    }

    //terms
    if (
      !checkedItems.option1
    ) {
      toast.error(
        translate("web.pleaseCheckAllTerms", "Please Check All Terms")
      );
      valid = false;
    }

    return valid;
  }

  const handelFormSubmit = (status, isOnlinePayment) => {
    if (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice" && formModel.eventName !== "SentBack") {
      const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

      const encodeURI = encodeURIComponent(returnUrl);

      window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${id}&paymentType=14&userId=${localStorage.getItem("EAHSUserID")}&returnUrl=${encodeURI}&isOnline=${isOnlinePayment}&lang=${currentLanguage}`;
    }
    else {
      if (
        validInputs()
      ) {
        _isOnlinePayment.current = isOnlinePayment;

        let params = {
          id: id == null ? '' : id,
          studRegistrationId: formModel.studRegistrationId,
          ownerId: getUserID(),
          studName: formModel.studName,
          studNameAr: formModel.studNameAr,
          emirateId: formModel.emirateId,
          regionId: formModel.regionId,
          studAddress: formModel.studAddress,
          studPhone: formModel.studPhone,
          isShowCategory: formModel.isShowCategory,
          isRaceCategory: formModel.isRaceCategory,
          isEnduranceCategory: formModel.isEnduranceCategory,
          isOtherCategory: formModel.isOtherCategory,
          gpsLocationLink: formModel.gpsLocationLink,
          longitude: longitude,
          latitude: latitude,
          status: status,
          ownerComment: formModel.ownerComment,
          landOwnershipAttachmentFile: formModel.landOwnershipAttachmentFile,
        };

        dispatch(FetchCreateOrEditStudRegistrationCertificatesServiceRequestthunk(params));
      }
    }
  };

  const handelSelectedAccountChange = async (element) => {
    setProfile(element);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    setCheckedItems({
      ...checkedItems,
      [name]: checked,
    });
  };

  function CheckIfAgentHasPermission() {
    const AgentHasPermission = userProfile.subAccounts
      .filter((x) => x.value == subAccountId)[0]
      .ownersPermissionsList.some(
        (x) =>
          x.permissionName === "StudRegistration" || x.permissionName === "All"
      );
    return AgentHasPermission;
  }

  const getUserID = () => {
    const userID =
      !!userProfile && userProfile?.accountType !== "Agent" ? profile.value : subAccountId;
    return userID;
  };

  const getSelectedAccountName = () => {
    const selectAccount = userProfile.subAccounts.filter(
      (x) => x.value == subAccountId
    );

    if (selectAccount.length > 0) {
      return selectAccount[0];
    }
    else {
      return false;
    }
  };

  const dismissAll = () => toast.dismiss();

  //Effect
  useEffect(() => {
    if (id != null) {
      let params = {
        language: currentLanguage,
        id: id,
      };

      dispatch(FetchGetServicesRequestByIdthunk(params));
    }

    return () => {
      dispatch(resetGetServicesRequestByIdData());
    };
  }, []);

  useEffect(() => {
    if (!!userProfile && (userProfile?.haveProfileSignture != true || userProfile?.isStud)) {
      toast.error(
        translate(
          "web.completeOwnerProfileData",
          "Please complete the personal data of the profile."
        )
      );

      navigate("/profile");
    }

    if (!!userProfile && userProfile?.accountType === "Agent") {
      if (userProfile.id == subAccountId) {
        navigate("/403-Not-Authrized");
      }

      if (!CheckIfAgentHasPermission()) {
        navigate("/403-Not-Authrized");
      }
    }

    getEmirateList();

    let subOwnerProfilesParams = {
      id: getUserID(),
      langauge: currentLanguage,
    };
    dispatch(FetchSubOwnerProfilesByIdthunk(subOwnerProfilesParams));

    dispatch(FetchGetServiceDefinitionByIdthunk(serviceId));
  }, [currentLanguage]);

  useEffect(() => {
    if (
      Object.keys(getServicesRequestByIdData).length > 0 && (getServicesRequestByIdData.serviceDefinitionId == serviceId)
    ) {
      setFormModel({
        ...formModel,
        eventName: getServicesRequestByIdData.eventName,
        comment: getServicesRequestByIdData.comment,
        requestsType: getServicesRequestByIdData.requestsType,
        paymentStatus: getServicesRequestByIdData.paymentStatus,
        referenceNumber: " - " + getServicesRequestByIdData.referenceNumber,
        studRegistrationId: getServicesRequestByIdData.studRegistrationDto.id,
        studName: getServicesRequestByIdData.studRegistrationDto.studName,
        studNameAr: getServicesRequestByIdData.studRegistrationDto.studNameAr,
        emirateId: getServicesRequestByIdData.studRegistrationDto.emirateId,
        emirateDrop: {
          label: getServicesRequestByIdData.studRegistrationDto.emirateName,
          value: getServicesRequestByIdData.studRegistrationDto.emirateId,
        },
        regionId: getServicesRequestByIdData.studRegistrationDto.regionId,
        regionDrop: {
          label: getServicesRequestByIdData.studRegistrationDto.regionName,
          value: getServicesRequestByIdData.studRegistrationDto.regionId,
        },
        studAddress: getServicesRequestByIdData.studRegistrationDto.studAddress,
        studPhone: getServicesRequestByIdData.studRegistrationDto.studPhone,
        latitude: getServicesRequestByIdData.studRegistrationDto.latitude,
        longitude: getServicesRequestByIdData.studRegistrationDto.longitude,
        isShowCategory: getServicesRequestByIdData.studRegistrationDto.isShowCategory,
        isRaceCategory: getServicesRequestByIdData.studRegistrationDto.isRaceCategory,
        isEnduranceCategory: getServicesRequestByIdData.studRegistrationDto.isEnduranceCategory,
        isOtherCategory: getServicesRequestByIdData.studRegistrationDto.isOtherCategory,
        gpsLocationLink: getServicesRequestByIdData.studRegistrationDto.gpsLocationLink,
        ownerComment: getServicesRequestByIdData.studRegistrationDto.ownerComment != null ? getServicesRequestByIdData.studRegistrationDto.ownerComment : "",

        filePathLandOwnership: getServicesRequestByIdData.studRegistrationDto.landOwnershipAttachmentFile.filePath,
        fileTypeLandOwnership: getServicesRequestByIdData.studRegistrationDto.landOwnershipAttachmentFile.fileType,
        fileNameLandOwnership: getServicesRequestByIdData.studRegistrationDto.landOwnershipAttachmentFile.fileName,
        documentBase64LandOwnership: getServicesRequestByIdData.studRegistrationDto.landOwnershipAttachmentFile.fileBase64,
      });

      setLatitude(getServicesRequestByIdData.studRegistrationDto.latitude);
      setLongitude(getServicesRequestByIdData.studRegistrationDto.longitude);

      //another owner
      if (
        getServicesRequestByIdData.studRegistrationDto.ownerId !==
        getServicesRequestByIdData.studRegistrationDto.createdBy &&
        subOwnerProfilesByIdData.length > 0 &&
        getServicesRequestByIdData.requestsType !== "BackOffice"
      ) {
        //update profile
        setProfile({
          label:
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.studRegistrationDto.ownerId
            )[0].registerNo +
            " - " +
            subOwnerProfilesByIdData.filter(
              (item) =>
                item.value ==
                getServicesRequestByIdData.studRegistrationDto.ownerId
            )[0][`text${currentLanguage === "en" ? "" : "Ar"}`],
          value: subOwnerProfilesByIdData.filter(
            (item) =>
              item.value == getServicesRequestByIdData.studRegistrationDto.ownerId
          )[0].value,
        });
      }

      //options
      setCheckedItems({
        option1: true,
      });
    }
  }, [
    getServicesRequestByIdData,
  ]);

  useEffect(() => {
    if (
      toasterMessageServicesRequests != null &&
      toasterMessageServicesRequests === "save"
    ) {
      if (_isOnlinePayment.current !== "") {
        toast.success(
          translate(
            "web.yourRequestHasSubmittedSuccessfully",
            "Your Request Has Submitted Successfully"
          )
        );
      } else {
        toast.success(
          translate("web.dataSavedSuccessfully", "Data Saved Successfully")
        );
      }

      dispatch(resetToasterMessageServicesRequests());

      setTimeout(() => {
        if (_isOnlinePayment.current !== "") {
          const returnUrl = `${BaseURL}/profile?activeTab=${"e_services"}`;

          const encodeURI = encodeURIComponent(returnUrl);

          window.location.href = `${UMBRACOURL}/BackofficePayment/PaymentGetway?entityId=${createOrEditStudRegistrationCertificatesServiceRequestData.id}&paymentType=14&userId=${localStorage.getItem(
            "EAHSUserID"
          )}&returnUrl=${encodeURI}&isOnline=${_isOnlinePayment.current}&lang=${currentLanguage}`;
        } else {
          navigate(`/profile?activeTab=${"e_services"}`);
        }
      }, 1000);
    }

    //error
    else if (toasterMessageServicesRequests != null && toasterMessageServicesRequests !== "save") {
      toast.error(
        translate(toasterMessageServicesRequests, toasterMessageServicesRequests)
      );

      dispatch(resetToasterMessageServicesRequests());
    }
  }, [toasterMessageServicesRequests]);

  //consoles
  console.log(formModel)

  return (
    <div className="container-fluid container-fluid-padding">
      {
        // loading div
      }
      <div
        className={`page-overlay text-center ${servicesRequestsLoading
          ? "classdisplay"
          : "classnotdisplay"
          }`}
      >
        <div className="loaderDiv">
          <Loader />
        </div>
      </div>
      {
        //toast
      }
      <ToastContainer
        position="top-right"
        onClick={dismissAll}
        theme="colored"
        autoClose={5000}
      />
      {
        //content page
      }
      <div className="row rtl">
        <div className="col-md-12 col-sm-12 row rtl">
          {
            //owned Registered Horse Certificate title
          }
          <div className="col-md-12 col-sm-12 mb-4">
            <h1 className="fontWeight display-inline">
              {currentLanguage === "en"
                ? getServiceDefinitionByIdData.nameEn
                : getServiceDefinitionByIdData.nameAr}
            </h1>
            <h3 className="fontWeight display-inline">
              {formModel.referenceNumber}{" "}
            </h3>
          </div>
          {
            // Selected Account
          }
          {subOwnerProfilesByIdData.length > 0 &&
            (action == null || action === "edit") &&
            (
              <div className="col-md-6 col-sm-12 mb-3">
                {!!userProfile && userProfile?.accountType !== "Agent" ? (
                  <>
                    <label className="capatalize_text">
                      {translate("web.selectedAccount", "Selected Account")}
                    </label>
                    <Select
                      className="mt-10 zindex200"
                      options={[
                        {
                          label:
                            userProfile?.ownerRegisterNo +
                            " - " +
                            userProfile[
                            `fullName${currentLanguage === "en" ? "" : "Ar"}`
                            ],
                          value: localStorage.getItem("EAHSUserID"),
                        },
                        ...(!!subOwnerProfilesByIdData &&
                          !!subOwnerProfilesByIdData.length > 0 &&
                          subOwnerProfilesByIdData.map((a) => ({
                            label:
                              a?.registerNo +
                              " - " +
                              a[`text${currentLanguage === "en" ? "" : "Ar"}`],
                            value: a.value,
                          }))),
                      ]}
                      value={profile}
                      onChange={(e) => {
                        handelSelectedAccountChange(e);
                      }}
                      placeholder={localStorage.getItem("EAHSUserFullName")}
                      isSearchable
                      noOptionsMessage={() =>
                        translate("web.noOptions", "No Options")
                      }
                      styles={customStyles}
                      key={"EAHSUserFullName"}
                    />
                  </>
                ) : (
                  <label className="capatalize_text">
                    {getSelectedAccountName()?.registerNo +
                      " - " +
                      getSelectedAccountName()[
                      `text${currentLanguage === "en" ? "" : "Ar"}`
                      ]}
                  </label>
                )}
              </div>
            )}

          <EahsForm title={translate("web.studDetails", "Stud Details")}>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (EN)"}
                name={"studName"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.studName}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <p style={{ color: "red", display: errorStudName }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.name", "Name") + " (AR)"}
                name={"studNameAr"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.studNameAr}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <p style={{ color: "red", display: errorStudNameAr }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <NetaqInput
                label={translate("web.phone", "Phone")}
                name={"studPhone"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.studPhone}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <p style={{ color: "red", display: errorStudPhone }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>
          </EahsForm>

          <EahsForm title={translate("web.studLocationDetails", "Stud Location Details")}>
            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.emirate", "Emirate")}
                placeholder={translate("web.emirate", "emirate")}
                name={"emirateId"}
                listSelect={emiratelistoptions}
                value={formModel.emirateDrop}
                onChange={(e) => handleFormChange(e, "emirateId")}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorEmirateId }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-6 col-sm-12 rtl service">
              <NetaqInput
                type={"select"}
                label={translate("web.area", "Area")}
                placeholder={translate("web.area", "Area")}
                name={"regionId"}
                listSelect={regionsList}
                value={formModel.regionDrop}
                onChange={(evt) => handleFormChange(evt, "regionId")}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <span style={{ color: "red", display: errorRegionId }}>
                {translate("web.requiredField", "Required Field")}
              </span>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.address", "Address")}
                name={"studAddress"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.studAddress}
                required={true}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
              <p style={{ color: "red", display: errorStudAddress }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>

            <div className="col-md-6 col-sm-12 mt-3">
              <NetaqInput
                label={translate("web.gpsLocationLink", "GPS Location Link")}
                name={"gpsLocationLink"}
                type={"text"}
                onChange={handleFormChange}
                value={formModel.gpsLocationLink}
                required={true}
                disabled={true}
              />
              <p style={{ color: "red", display: errorGpsLocationLink }}>
                {translate("web.requiredField", "Required Field")}
              </p>
            </div>

            {
              // <div className="col-md-12 col-sm-12 mt-3">
              //       <div className="col-md-3 mt-3">
              //         <ActionButton
              //           className="btn-info text-white w-50"
              //           type={"bootstrapbtn"}
              //           text={`<i class="fa-solid fa-eye fa-lg marginrightleft-10"></i>                   
              //                  ${translate("web.showHideMap", "Show/Hide MAP")}
              //                  `}
              //           margin={"0"}
              //           width={"100%"}
              //           onClick={toggleMap}
              //         />
              //       </div>
              //     </div>
            }

            {
              //showHideMap && (
              <div className="col-md-12 col-sm-12 rtl service mt-3">
                <iframe
                  id="Imap"
                  src={`https://maps.atlasapps.net/map.aspx?set=yes&location=${latitude},${longitude}&key=${lockey}&MapsKey=AIzaSyB26sBNX-V2SgrBTaWpepPfXvttfiIb2YM`}
                  width="100%"
                  height="350"
                  frameborder="0"
                  style={{ border: 0 }}
                  allowfullscreen=""
                ></iframe>
                <div className="col-md-3 mt-3">
                  <ActionButton
                    className="btn-info text-white w-50"
                    type={"bootstrapbtn"}
                    text={`<i class="fa-solid fa-location-dot fa-lg marginrightleft-10"></i>                   
                             ${translate("web.selectLocation", "Select Location")}
                            `}
                    margin={"0"}
                    width={"100%"}
                    onClick={
                      selectLocation
                    }
                    disabled={
                      ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                        true
                        : false
                    }
                  />
                </div>
              </div>
              //)
            }
          </EahsForm>

          <EahsForm title={translate("web.horseCategoryDetails", "Horse Category Details")}>
            <div className="col-md-12 col-sm-12 rtl service">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.isShowCategory",
                  "Is Show Category"
                )}
                name="isShowCategory"
                checked={formModel.isShowCategory}
                onChange={(e) => handleFormChange(e, "horseCategory")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl service">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.isRaceCategory",
                  "Is Race Category"
                )}
                name="isRaceCategory"
                checked={formModel.isRaceCategory}
                onChange={(e) => handleFormChange(e, "horseCategory")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl service">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.isEnduranceCategory",
                  "Is Endurance Category"
                )}
                name="isEnduranceCategory"
                checked={formModel.isEnduranceCategory}
                onChange={(e) => handleFormChange(e, "horseCategory")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>
            <div className="col-md-12 col-sm-12 rtl service">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.isOtherCategory",
                  "Is Other Category"
                )}
                name="isOtherCategory"
                checked={formModel.isOtherCategory}
                onChange={(e) => handleFormChange(e, "horseCategory")}
                disabled={
                  ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                    true
                    : false
                }
              />
            </div>
            <span style={{ color: "red", display: errorHorseCategory }}>
              {translate("web.requiredField", "Required Field")}
            </span>
          </EahsForm>

          <EahsForm title={translate("web.uploadDocument", "Upload Document")}>
            <div className="col-md-12 col-sm-12 row mb-25 row align-items-center">
              <h3>
                {translate("web.serviceRequestDocument", "Supported Document")}
              </h3>
              <div className="col-7">
                <SimpleFileUpload
                  isMulti={false}
                  initialFiles={
                    !!formModel.filePathLandOwnership &&
                      !!formModel.documentBase64LandOwnership &&
                      landOwnershipAttachmentFile.length === 0
                      ? [
                        {
                          file: base64ToBlob(
                            formModel.documentBase64LandOwnership,
                            formModel.fileTypeLandOwnership ===
                              ".pdf"
                              ? "application/pdf"
                              : "image/png"
                          ),
                          name: formModel.fileNameLandOwnership,
                        },
                      ]
                      : landOwnershipAttachmentFile
                  }
                  isServer={
                    !!formModel.filePathLandOwnership &&
                      !!formModel.documentBase64LandOwnership &&
                      landOwnershipAttachmentFile.length === 0
                      ? true
                      : false
                  }
                  onChange={handleUploadLandOwnershipChange}
                  accept={"image/*,application/pdf"}
                  disabled={
                    ((action === "approve" && formModel.eventName !== "SentBack") || action === "view") ?
                      true
                      : false
                  }
                />
              </div>
            </div>
          </EahsForm>

          {/* Comments */}
          {(!!formModel.comment || !!formModel.ownerComment) && (
            <EahsForm title={translate("web.comments", "Comments")}>
              {!!formModel.comment && (
                // eahs Comment
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.eahsComments", "EAHS Comments")}
                    name={"adminComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.comment}
                    disabled={true}
                  />
                </div>
              )}

              {(!!formModel.ownerComment || formModel.eventName === "SentBack") && (
                // owner Comment 
                <div className="col-md-6 col-sm-12">
                  <NetaqInput
                    label={translate("web.ownerComment", "Owner Comment")}
                    name={"ownerComment"}
                    type={"textarea"}
                    maxchars={1150}
                    value={formModel.ownerComment}
                    onChange={handleFormChange}
                    disabled={formModel.eventName !== "SentBack" ? true : false}
                  />
                </div>
              )}
            </EahsForm>
          )}

          <EahsForm title={translate("web.terms", "Terms")}>
            <div className="col-md-12 col-sm-12 rtl p0">
              <NetaqInput
                type={"checkbox"}
                label={translate(
                  "web.ServiceGeneralNote",
                  "Service General Note"
                )}
                name="option1"
                checked={checkedItems.option1}
                onChange={handleCheckboxChange}
                disabled={formModel.eventName === "" ? false : true}
              />
            </div>
          </EahsForm>

          {/* buttons*/}
          <div className="col-md-12 col-sm-12 mt-5 text-end">
            {/* draft/pay/submit btn */}
            {
              (
                (action == null || action === "edit")
                ||
                (
                  action === "approve"
                  &&
                  (
                    formModel.eventName === "SentBack"
                    ||
                    (formModel.paymentStatus === "Pendding" && formModel.requestsType === "BackOffice")
                  )
                )
              ) && (
                <div className="row">
                  {(formModel.eventName === "" || formModel.eventName === "Draft") &&
                    <div className="col-md-1 ms-auto">
                      <ActionButton
                        type={"requests"}
                        className="btn buttonformshowentrycard height46 linkbtn w-100"
                        label={translate("web.draft", "Draft")}
                        text={
                          <>
                            <i className="fa fa-save fa-lg marginrightleft-10"></i>
                            {translate("web.draft", "Draft")}
                          </>
                        }
                        margin={"auto 10px"}
                        width={"100%"}
                        onClick={() => handelFormSubmit("Draft", "")}
                        disabled={
                          formModel.eventName === "" ||
                            formModel.eventName === "Draft"
                            ? false
                            : true
                        }
                      />
                    </div>
                  }
                  {userProfile?.participateWithoutPayment === true && formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-md-2">
                      <ActionButton
                        className="btn-info text-white"
                        type={"bootstrapbtn"}
                        text={`<i class="fa-solid fa-wallet fa-lg marginrightleft-10"></i>                   
                          ${translate("web.submitAndPayLater", "Submit And Pay Later")}
                          `}
                        margin={"0"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Draft", "no")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}
                  {formModel.eventName !== "SentBack" && getServiceDefinitionByIdData.serviceFees > 0 && (
                    <div className="col-md-2">
                      <ActionButton
                        className={"btn-success requestBtn mx-0 w-100"}
                        type={"requests"}
                        label={translate("web.approvePay", "Approve And Pay")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submitPay", "Submit And Pay")}
                          </>
                        }
                        margin={"0"}
                        onClick={() =>
                          handelFormSubmit("Draft", "yes")
                        }
                        disabled={
                          formModel.eventName === "SentBack"
                            ? true
                            : false
                        }
                      />
                    </div>
                  )}

                  {(getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack") && (
                    <div className="col-md-1 ms-auto">
                      <ActionButton
                        type={"requests"}
                        className="btn-success text-white requestBtn w-100"
                        label={translate("web.submit", "Submit")}
                        text={
                          <>
                            <i className="fa-solid fa-circle-check fa-lg marginrightleft-10"></i>
                            {translate("web.submit", "Submit")}
                          </>
                        }
                        margin={"auto 25px"}
                        width={"100%"}
                        onClick={() =>
                          handelFormSubmit("Submit", "")
                        }
                        disabled={
                          getServiceDefinitionByIdData.serviceFees === 0 || formModel.eventName === "SentBack"
                            ? false
                            : true
                        }
                      />
                    </div>
                  )}
                </div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
}
