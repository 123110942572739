import React from 'react'
import ArenaTable from './ArenaTable';
import { TableObject } from './TableObject';
export default function Alshira3Table({ totalTables, toggleTableSelection, BaseURL, translate, }) {
    const userProfile = JSON.parse(localStorage.getItem('eahsUserProfile') ?? '{}') ?? '';
    // const selectedTables = JSON.parse(localStorage.getItem('selectedBookingTables') ?? '{}') ?? '[]';
    const selectedTables = [];
    return <div className="bookingTableContainer" style={{ width: 1350 }}>
        <div className="bookingTableCenter">
            <div className="BookingTableArena">
                <img
                    style={{ width: '100%', height: 'unset', aspectRatio: 'unset', background: '#A67B52' }}
                    src={`${BaseURL}/assets/img/alshiraa.png`}
                    alt="Horse Arena" />
            </div>
        </div>
        <div className="bookingTablesRow withVIPSection mt-2" style={{ paddingLeft: 0 }}>
            {totalTables
                .filter((item, index) => item.tableNo > 0 && item.tableNo < 10).reverse()
                .map((item, index) => (
                    index < 9 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={ TableObject.fromRaw(item).state()}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        customClassName={'mx-auto'}
                        style={{ marginRight: index == 8 ? '25px' : '' }}
                    />
                ))}
            <div className="vipSection">{translate('web.vvipPlatForm', 'VVIP Platform')}</div>
            {totalTables

                .filter((item, index) => item.tableNo > 11 && item.tableNo < 20).reverse()
                .map((item, index) => (
                    index < 9 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={ TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)}
                        customClassName={`mx-auto me-3 ${index == 7 ? 'me-4' : ''}`}
                        style={{ marginLeft: index == 0 ? '25px' : '' }}

                    />
                ))}
        </div>
        <div className="bookingTablesRow withVIPSection secondVIPRow mt-2" style={{ paddingLeft: 0 }}>
            {totalTables
                .filter((item, index) => item.tableNo > 19 && item.tableNo < 25).reverse()
                .map((item, index) => (
                    index < 6 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={ TableObject.fromRaw(item).state()}
                                                onClick={() => toggleTableSelection(item.id, item.price)}
                        customClassName={`mx-auto ${index == 0 ? 'ms-5' : ''} `}
                        style={{ marginRight: index == 4 ? '100px' : '', marginLeft: index == 0 ? '25px' : '' }}
                    />
                ))}
            <div className="vipSection mx-auto" style={{ maxWidth: 150, textAlign: 'center', marginLeft: 100 }}>{10}</div>
            <div className="vipSection mx-auto" style={{ maxWidth: 150, textAlign: 'center', marginLeft: 50 }} >{11}</div>
            {totalTables
                .filter((item, index) => item.tableNo > 24 && item.tableNo < 30).reverse()
                .map((item, index) => (
                    index < 5 &&
                    <ArenaTable
                        key={item.tableNo}
                        tableNumber={item.tableNo}
                        tablePrice={item.price}
                        state={ TableObject.fromRaw(item).state()}
                                                customClassName={`mx-auto ${index == 4 ? 'mr-3' : ''}`}
                        onClick={() => toggleTableSelection(item.id, item.price)}
                        style={{ marginLeft: index == 0 ? '100px' : '' }}
                    />
                ))}
        </div>
        <div className="bookingTablesRow" style={{ paddingLeft: 0 }}>
            <div style={{ marginLeft: 468, textAlign: 'center', maxWidth: 30, marginTop: -40 }}>
                <div style={{ width: 30, height: 10, background: '#409A97' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#409A97' }}></i>
                <div style={{ fontSize: 13, textAlign: 'center', width: 75 }}>{translate('web.entrance', 'Entrance')}</div>
            </div>
            <div style={{ marginLeft: 330, textAlign: 'center', maxWidth: 25, opacity: 0, visibility: 'hidden' }}>
                <div style={{ width: 25, height: 10, background: '#dc3545' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#dc3545' }}></i>
                <div style={{ fontSize: 13, marginLeft: '-13px', width: 50 }}>{translate('web.VIPEntrance', 'VIP Entrance')}</div>
            </div>
            <div style={{ marginLeft: 0, textAlign: 'center', maxWidth: 30, marginTop: -40 }}>
                <div style={{ width: 30, height: 10, background: '#409A97' }} />
                <i class="fa-solid fa-up-long" style={{ color: '#409A97' }}></i>
                <div style={{ fontSize: 13, textAlign: 'center', width: 75 }}>{translate('web.entrance', 'Entrance')}</div>
            </div>
        </div>
    </div>;
}
