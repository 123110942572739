import React from 'react'
import { convertToCamelCase } from "../helper/convertToCamelCase";
import { formatDate } from "../helper/formatDate";
import { countWords } from "../helper/countWords";
import { Link } from 'react-router-dom';
import { useTranslate } from "./TranslationText";

const BaseURL = process.env.REACT_APP_BASE_URL;


export default function ShowCard({ item, i }) {
    const { translate } = useTranslate();

    return (

        <div className='showCardFinial'>
            <div className='showImageNewContainer'>
                <img
                    src={item.logo}
                    onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = `${BaseURL}/assets/img/horseauction.png`;
                    }}
                    alt="itemName" />
            </div>
            <div className='showNewTitleWithDetails'>

                {item.showName !== null && item.showName !== "" && (
                    <div className='showNewTitle'>
                        {item.showName}
                    </div>
                )}
                <div className='showNewDetials'>
                    <div className='showNewRank'>
                        <i className="fa fa-trophy " ></i> {item.showRank}
                    </div>
                    <div className='showNewLocation'>
                        <i className="fa-solid fa-location-dot" ></i>{translate(`web.${convertToCamelCase(item.cityName)}`, item.cityName)}
                    </div>
                    <div className='showNewRank showNewStartDate'>
                        <div>
                            <i className="fa fa-calendar" ></i>{translate("web.startDate", "Start Date")}:
                        </div>
                        <div>
                            {formatDate(item.startDate)}
                        </div>
                    </div>
                    <div className='showNewLocation showNewStartDate'>
                        <div>
                            <i className="fa fa-calendar " ></i>{translate("web.endDate", "End Date")}:
                        </div>
                        <div>
                            {formatDate(item.endDate)}
                        </div>
                    </div>
                    {(new Date(item.startDate) > new Date()) && (!!item.registerStartDate) && (item.registerEndDate) && <>
                        <div className='showNewRank showNewStartDate'>
                            <div>
                                <i className="fa fa-calendar" ></i>{translate("web.regStartDate", "Reg Start Date")}:
                            </div>
                            <div>
                                {formatDate(item.registerStartDate)}
                            </div>
                        </div>
                        <div className='showNewLocation showNewStartDate'>
                            <div>
                                <i className="fa fa-calendar " ></i>{translate("web.regEndDate", "Reg End Date")}:
                            </div>
                            <div>
                                {formatDate(item.registerEndDate)}
                            </div>
                        </div>
                    </>
                    }

                    <div className='showNewViewDetails'>
                        {item.haveTermsAndConditions &&
                            <Link
                                className="hoveronlink ViewProfileLink  rtl"
                                to={`/SingleTermsAndConditionsShowPage?id=${item.id}`}
                            >
                                {translate("web.termsAndConditions", "termsAndConditions")}
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                                ></i>
                            </Link>
                        }
                    </div>
                    <div className='showNewViewDetails'>


                        {(new Date(item.startDate) > new Date()) ?
                            <>

                            </>

                            :
                            <Link
                                className="hoveronlink ViewProfileLink  rtl"
                                to={`/showdetails?id=${item.id}`}
                            >
                                {translate("web.viewDetails", "View Details")}
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-25"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-50"
                                ></i>
                                <i
                                    className="fa-solid fa-chevron-right rotateArabic red-fz-8-op-1"
                                ></i>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
