import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "../axios/axios";
import { useTranslate } from "../components/TranslationText";
import Loader from "../components/Loader";
import { useLocation } from "react-router";
import { formatDate } from "../helper/formatDate";
import ActionButton from "../components/ActionButton";
import { Link } from "react-router-dom";





export default function SingleTermsAndConditionsShowPage() {
  const { currentLanguage } = useSelector((state) => state.studBookSlice);
  const [show, setShow] = useState(null);
  const { translate } = useTranslate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const id = searchParams.has("id") ? searchParams.get("id") : "";
  const lang = localStorage.getItem("eahsLang") ?? "en";

  function getCurrentLanguageUmbraco() {
    // Replace this with your logic to retrieve the current language
    // For example, you can retrieve it from localStorage or from a state variable
    const lang = localStorage.getItem("eahsLang") ?? "en";
    if (lang == "en") return "en-US";
    else return "ar-AE"; // Defaulting to English for demonstration
  }

  useEffect(() => {
    async function getdata() {
      const response = await axios.get(
        `/Shows/GetShowbyId?id=${id}&langauge=${lang}&language=1`,
        {
          headers: {
            "accept-language": getCurrentLanguageUmbraco(),
          },
        }
      );

      setShow(response.data.result);
    }
    getdata();
  }, [currentLanguage]);

  return (
    <>
      {!show && <Loader />}
      {!!show && (
        <div className="container">
          <>
            {/* <h2 className="bold rtl">{show.showName}</h2> */}

            <div className="row rotateArabic" style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>

              <h2 className="col-lg-12 col-md-12 col-sm-12 rotateArabic  bold rtl capatalise">
                <img src={show.logo} style={{ maxWidth: 100 }} />
                {show.showName}
              </h2>


              <div className="col-md-12 col-sm-12  rtl p0" style={{ display: 'flex', flexDirection: currentLanguage == "en" ? 'row' : 'row-reverse', justifyContent: "flex-start", flexWrap: 'wrap', alignItems: 'center', marginBottom: 15 }}>
                {/* <div className="row"> */}

                {!!show?.startDate && (
                  <div className="col-md-3  showDetaisLabel rotateArabic" style={{ margin: '5px 0' }}>
                    <i
                      className="fa fa-calendar C89C66"
                      style={{ margin: "0 5px" }}
                    ></i>
                    <span>{formatDate(show.startDate)}</span>
                  </div>
                )}
                {!!show?.endDate && (
                  <div className="col-md-3  showDetaisLabel rotateArabic" style={{ margin: '5px 0' }}>
                    <i
                      className="fa fa-calendar C89C66"
                      style={{ margin: "0 5px" }}
                    ></i>
                    <span>{formatDate(show.endDate)}</span>
                  </div>
                )}
                {!!show?.cityName && (
                  !!show?.mapLocationSharedUrl ?
                    <Link to={show?.mapLocationSharedUrl} target="_blank" className="col-md-3 justRotate showDetaisLabel text-info text-decoration-underlined">
                      <i
                        className="fa-solid fa-location-dot text-info"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span>
                        {show.cityName}
                      </span>
                    </Link> :
                    <div className="col-md-3 showDetaisLabel justRotate ">
                      <i
                        className="fa-solid fa-location-dot C89C66"
                        style={{ margin: "0 5px" }}
                      ></i>
                      <span>
                        {show.cityName}
                      </span>
                    </div>
                )}
                <div className="col-md-3 showDetaisLabel rotateArabic" style={{ margin: '5px 0' }}>
                  <i className="fa fa-trophy C89C66 " style={{ margin: "0 5px" }}></i>
                  <span>{show.showRank}</span>
                </div>

              </div>

            </div>

            {currentLanguage == "en" && (
              <>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 serviceLabelKey  rtl">
                    {translate(
                      "web.T&C",
                      "Terms and Conditions"
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: show.termsAndConditionsEn,
                    }}
                    className="col-lg-12 col-md-12 col-sm-12 serviceLabelVal currentFont rtl"
                  />
                </div>
              </>
            )}
            {currentLanguage == "ar" && (
              <>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 serviceLabelKey  rtl">
                    {translate(
                      "web.T&C",
                      "Terms and Conditions"
                    )}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: show.termsAndConditionsEn,
                    }}
                    className="col-lg-12 col-md-12 col-sm-12 serviceLabelVal currentFont rtl"
                  />


                </div>
              </>
            )}

          </>

        </div>
      )}
    </>
  );
}
